import { CategoryProps } from '../types/category.type';

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function capitalizeEachFirstLetter(text: string) {
  const words = text.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }

  console.log(words.join(' '));

  return words.join(' ');
}

export function showReadableText(str: string) {
  return str.replace(/_/g, ' ');
}
export const getCategoryName = (
  categories: CategoryProps[] | undefined,
  type: 'category' | 'subCategory',
) => {
  if (type === 'category') {
    return categories?.find((category) => category?.parentCategoryId === null)!?.categoryName;
  } else if (type === 'subCategory') {
    return categories?.find((category) => category?.parentCategoryId !== null)!?.categoryName;
  }
};
