import { Row, Typography } from 'antd';
import styled from 'styled-components';
import formatPrice from '../helpers/formatPrice';

interface IProps {
  realPrice: number; // harga asli produk
  discount: number; // discount persen
  discountPrice: number; // harga setelah diskon
  isDetail?: boolean;
  isSmall?: boolean;
}

export default function HargaCoret(props: IProps) {
  return (
    <Wrapper isDetail={props.isDetail} isSmall={props.isSmall}>
      {props.discount === 0 ? (
        <Typography.Text className="discount-price">
          IDR {(props.realPrice && formatPrice(props.discountPrice)) || '-'}
        </Typography.Text>
      ) : (
        <>
          <Row className="items-center">
            <Typography.Text className="real-price">
              IDR {(props.realPrice && formatPrice(props.realPrice)) || '-'}
            </Typography.Text>
            <div className="discount-percentage">
              {Math.floor(props.discount)}%OFF
            </div>
          </Row>
          <Typography.Text className="discount-price">
            IDR {(props.realPrice && formatPrice(props.discountPrice)) || '-'}
          </Typography.Text>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isDetail?: boolean; isSmall?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2px;

  .real-price {
    font-size: 10px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.gray_dark};
    margin-right: 8px;
    text-decoration: line-through;
  }

  .discount-percentage {
    font-size: 10px;
    color: #161616;
    background-color: #dac592;
    padding: 3px 4px;
    font-weight: 500;
  }

  .discount-price {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.black};
  }
`;
