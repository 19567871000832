import {
  Form,
  FormInstance,
  message,
  Select,
  Space,
  Button,
  Spin,
  Image,
  Modal,
} from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  initialProductImages,
  ProductImagesProps,
} from '../../../types/productImages.type';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { UploadResponseProps } from '../../../types/config.type';
import { generateFormRules } from '../../../helpers/formRules';
import FormUploadImage from '../../../components/FormUpload/FormUploadImage';
import { CloseOutlined } from '@ant-design/icons';
import { getErrorMessage } from '../../../helpers/errorHandler';
import { DEFAULT_IMG } from '../../../const/config';

interface ResponseProps extends UploadResponseProps {
  payload: Omit<ProductImagesProps, 'createdAt' | 'updatedAt'>;
}

interface IParams {
  productId: string;
}

const UploadImagesEdit: React.FC = () => {
  const navigate = useNavigate();
  const { productId } = useParams<keyof IParams>() as IParams;
  const formRef =
    React.useRef<
      FormInstance<Omit<ProductImagesProps, 'createdAt' | 'updatedAt'>>
    >(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [images, setImages] = React.useState<File[]>([]);
  const [imageUrls, setImageUrls] = React.useState<string[]>([]);
  const [selectedImage, setSelectedImage] = React.useState<File | string>('');
  const [willBeDeletedImage, setWillBeDeletedImage] = React.useState<
    File | undefined
  >();
  const [isLoadingDeleteImage, setIsLoadingDeleteImage] = React.useState(false);

  const uploadProductImages = async (
    props: Omit<
      ProductImagesProps,
      'createdAt' | 'updatedAt' | 'productId' | 'statusLoading'
    >
  ) => {
    try {
      setIsLoadingAction(true);
      const formData = new FormData();
      if (images) {
        console.log(images);
        images.forEach((image) => formData.append('images', image));
      }

      await httpRequest.post(`/product-images/upload/${productId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Success upload images.');
      navigate('/products');
      setIsLoadingAction(false);
    } catch (error) {
      setIsLoadingAction(false);
      message.error('images cannot be empty.');
    }
  };

  const handleDeleteImage = async () => {
    try {
      setIsLoadingDeleteImage(true);
      setImages(
        images.filter((item, index) => {
          setImageUrls(imageUrls.filter((item) => item !== imageUrls[index]));
          return item !== willBeDeletedImage;
        })
      );
      setIsLoadingDeleteImage(false);
      setWillBeDeletedImage(undefined);
    } catch (err) {
      message.error(getErrorMessage(err));
      setIsLoadingDeleteImage(false);
    }
  };

  const handleSubmit = async (
    values: Omit<
      ProductImagesProps,
      'createdAt' | 'updatedAt' | 'statusLoading'
    >
  ) => {
    uploadProductImages(values);
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={'Upload Product Images'}
        subtitle="Manage your product images"
        rightAction={
          <Space>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button
              loading={isLoadingAction}
              type="primary"
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </Button>
          </Space>
        }
      />
      <Spin spinning={isLoading}>
        <Form
          ref={formRef}
          name="uploadImagesForm"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={initialProductImages}
          autoComplete="off"
        >
          <h4>Product Images</h4>
          <FormUploadImage
            mode={'single-large'}
            maxSizeInMB={5}
            imageUrl={
              typeof selectedImage === 'string'
                ? selectedImage
                : imageUrls[imageUrls.length - 1]
            }
            onChange={(file: any) => {
              setImages((prevFile) => [...prevFile, file]);
            }}
            onPreviewChange={(previewUrl: any) => {
              setImageUrls((prevUrl) => [...prevUrl, previewUrl]);
            }}
          />
          <Space direction="horizontal">
            {images.map((img, index) => (
              <div
                key={index}
                style={
                  selectedImage === img
                    ? {
                        border: 5,
                        borderStyle: 'solid',
                        borderColor: '#FF4A50',
                        position: 'relative',
                      }
                    : { position: 'relative' }
                }
              >
                <Image
                  onClick={() => {
                    setSelectedImage(imageUrls[index]);
                  }}
                  key={index}
                  preview={true}
                  width={100}
                  height={100}
                  src={imageUrls[index] || DEFAULT_IMG}
                  fallback={DEFAULT_IMG}
                  style={{ objectFit: 'cover' }}
                  placeholder={
                    <Image
                      preview={false}
                      src={DEFAULT_IMG}
                      width={100}
                      height={100}
                      style={{ objectFit: 'cover' }}
                    />
                  }
                />
                <div
                  onClick={() => {
                    setWillBeDeletedImage(img);
                  }}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: 'grey',
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    paddingRight: 5,
                    paddingLeft: 5,
                    zIndex: 100,
                  }}
                >
                  <CloseOutlined width={20} height={20} color="#FFFFFF" />
                </div>
              </div>
            ))}
          </Space>
        </Form>
      </Spin>

      <Modal
        title="Confirmation"
        visible={!!willBeDeletedImage}
        onOk={handleDeleteImage}
        onCancel={() => {
          setWillBeDeletedImage(undefined);
        }}
        okText="Yes"
        confirmLoading={isLoadingDeleteImage}
        okButtonProps={{ type: 'primary' }}
      >
        <p>Are you sure want to delete this image?</p>
      </Modal>
    </React.Fragment>
  );
};

export default UploadImagesEdit;
