import {
  Form,
  Input,
  message,
  Radio,
  Select,
  Space,
  Button,
  Card,
  Spin,
} from 'antd';
import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  initialProductStock,
  ProductStockProps,
} from '../../../types/productStocks.type';
import { initialProduct, ProductProps } from '../../../types/products.type';
import { BaseResponseProps } from '../../../types/config.type';
import { getErrorMessage } from '../../../helpers/errorHandler';
import { httpRequest } from '../../../helpers/api';
import HeaderSection from '../../../components/HeaderSection';
import { generateFormRules } from '../../../helpers/formRules';
import { initialLocation, LocationProps } from '../../../types/location.type';

interface ILocation {
  productStockId: string;
}
interface ResponseProps extends BaseResponseProps<ProductStockProps> {}

const { Option } = Select;

const ProductStockEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { productStockId } = useParams<keyof ILocation>() as ILocation;
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [productStock, setProductStock] = React.useState<ProductStockProps>({
    ...initialProductStock,
    currentStock: 1,
  });
  const [products, setProducts] = React.useState<ProductProps[]>([
    initialProduct,
  ]);
  const [locations, setLocations] = React.useState<LocationProps[]>([
    initialLocation,
  ]);
  const [query, setQuery] = React.useState<string>('');

  const createStock = async (value: ProductStockProps) => {
    try {
      setIsLoadingAction(true);

      const locationCode = locations.find((item) => {
        if (item.locationId === value.locationId) {
          return item.locationCode;
        }
      });
      const productCode = products.find((item) => {
        if (item.productId === value.productId) {
          return item.productCode;
        }
      });
      const formData = {
        bulk: [
          {
            newData: {
              description: value.description,
              currentStock: value.currentStock,
              baseUnitOfMeasure: value.baseUnitOfMeasure,
            },
            condition: {
              productCode: productCode?.productCode,
              locationCode: locationCode?.locationCode,
            },
          },
        ],
      };

      await httpRequest.put('/product-stocks/by-code', formData);

      message.success('Success add stock.');
      navigate('/product-stocks');
    } catch (error) {
      message.error(getErrorMessage(error));
      setIsLoadingAction(false);
    }
  };

  const updateStock = async (value: ProductStockProps) => {
    try {
      setIsLoadingAction(true);
      const locationCode = locations.find((item) => {
        if (item.locationId === value.locationId) {
          return item.locationCode;
        }
      });
      const productCode = products.find((item) => {
        if (item.productId === value.productId) {
          return item.productCode;
        }
      });
      const formData = {
        newData: {
          description: value.description,
          currentStock: value.currentStock,
          baseUnitOfMeasure: value.baseUnitOfMeasure,
        },
        condition: {
          productCode: productCode?.productCode,
          locationCode: locationCode?.locationCode,
        },
      };

      await httpRequest.put('/product-stocks/by-code', formData);

      message.success('Success update stock');
      navigate('/product-stocks');
    } catch (error) {
      message.error(getErrorMessage(error));
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (values: ProductStockProps) => {
    if (productStockId) {
      updateStock(values);
    } else {
      createStock(values);
    }
  };

  const onProductChange = (value: string) => {
    setProductStock({
      ...productStock,
      productId: value,
    });
  };

  const onLocationChange = (value: string) => {
    setProductStock({
      ...productStock,
      locationId: value,
    });
  };

  React.useEffect(() => {
    if (productStockId) {
      const fetchStockData = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            '/product-stocks/' + productStockId
          );
          if (res && res.data) {
            setProductStock(res.data.payload);
            form.setFieldsValue(res.data.payload);
          } else {
            message.error('Something went wrong');
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchStockData();
    }
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const productRes = await httpRequest.get<any>('/products');
        if (productRes && productRes.data) {
          setProducts(productRes.data.payload.results);
        } else {
          message.error('Something went wrong');
        }

        const locationRes = await httpRequest.get<any>(`/locations`);
        if (locationRes && locationRes.data) {
          setLocations(locationRes.data.payload.results);
        } else {
          message.error('Something went wrong');
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [productStockId, location]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={(productStockId ? 'Edit' : 'Add') + ' Stock'}
        subtitle={
          productStockId ? 'Manage your stock data' : 'Create new stock'
        }
        rightAction={
          <Space>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button
              loading={isLoadingAction}
              type="primary"
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </Space>
        }
      />
      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <Form
            form={form}
            name="stockForm"
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Product Name"
              name="productId"
              key="productId"
              rules={generateFormRules('product Name', ['required'])}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select Product"
                optionFilterProp="children"
                searchValue=""
                onChange={onProductChange}
                // onSearch={onSearch}
              >
                {products.map((item) => {
                  return (
                    <Option value={item.productId}>{item.productName}</Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Location"
              name="locationId"
              key="locationId"
              rules={generateFormRules('Location', ['required'])}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select Location"
                optionFilterProp="children"
                onChange={onLocationChange}
                // onSearch={onSearch}
              >
                {locations.map((item) => {
                  return (
                    <Option value={item.locationId}>{item.locationName}</Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Current Stock"
              name="currentStock"
              key="currentStock"
              rules={generateFormRules('currentStock', ['required', 'numeric'])}
            >
              <Input
                onChange={(event) =>
                  setProductStock({
                    ...productStock,
                    currentStock: parseInt(event.target.value),
                  })
                }
                placeholder="Input Stock"
              />
            </Form.Item>

            <Form.Item
              label="Base Unit Of Measure"
              name="baseUnitOfMeasure"
              key="baseUnitOfMeasure"
              rules={generateFormRules('baseUnitOfMeasure', ['required'])}
            >
              <Input
                onChange={(event) =>
                  setProductStock({
                    ...productStock,
                    baseUnitOfMeasure: event.target.value,
                  })
                }
                placeholder="PCS"
              />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              key="description"
              rules={generateFormRules('description', ['required'])}
            >
              <Input
                onChange={(event) =>
                  setProductStock({
                    ...productStock,
                    description: event.target.value,
                  })
                }
                placeholder="description"
              />
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </React.Fragment>
  );
};

export default ProductStockEdit;
