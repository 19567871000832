import React from 'react';
import AppLayout from '../layout/AppLayout';
import { useNavigate } from 'react-router-dom';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Pagination,
  Space,
  Table,
  Switch,
  Dropdown,
  Menu,
  Modal,
  message,
  Input,
  Select,
  Image,
  Typography,
  Button,
  TableProps,
  Row,
  Col,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import styled from 'styled-components';
import useFetchList from '../../hooks/useFetchList';
import { formatYearToTime, PAGE_SIZE_OPTIONS } from '../../helpers/constant';
import {
  RedeemProductProps,
  initialRedeemProduct,
} from '../../types/redeemProducts.type';
import CustomPagination from '../../components/CustomPagination';
import { IconArrowDown } from '../../assets/icons';
import { DEFAULT_IMG } from '../../const/config';

interface ResponseProps extends BaseResponseProps<RedeemProductProps> {
  payload: Omit<RedeemProductProps, 'createdAt' | 'updatedAt'>;
}

const { Text } = Typography;

const RedeemProducts = () => {
  const navigate = useNavigate();

  const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
    React.useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] =
    React.useState<boolean>(false);

  const [tmpData, setTmpData] =
    React.useState<RedeemProductProps>(initialRedeemProduct);

  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
    fetchList,
  } = useFetchList<RedeemProductProps>({
    endpoint: 'redeem-products',
    limit: 10,
  });

  // React.useEffect(() => {
  //   (async () => {
  //     const res = await httpRequest.get('/redeem-products');
  //     console.log(res);
  //   })();
  // }, []);

  console.log(tmpData);

  const handleStatusChange = async () => {
    try {
      setIsLoadingUpdateStatus(true);
      const newData = data.map((item) => {
        if (item.redeemProductId === tmpData.redeemProductId) {
          return {
            ...item,
            isPublished: !tmpData.isPublished,
          };
        }
        return item;
      });

      await httpRequest.patch<ResponseProps>(
        '/redeem-products/' + tmpData.redeemProductId,
        {
          isPublished: !tmpData.isPublished,
        }
      );

      setData(newData);

      message.success(
        'Success change ' + tmpData.product?.productName + ' status.'
      );
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setTmpData(initialRedeemProduct);
      setIsModalVisible(false);
      setIsLoadingUpdateStatus(false);
    }
  };

  const { Option } = Select;

  const handleChangeStatus = (status: string) => {
    if (status !== 'all') {
      setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isPublished: '' }));
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoadingUpdateStatus(true);

      const res = await httpRequest.delete<{ payload: { isSuccess: boolean } }>(
        '/redeem-products/' + tmpData.redeemProductId
      );

      if (res?.data?.payload?.isSuccess) {
        fetchList();
        message.success('Success delete ' + tmpData.product?.productName);
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setTmpData(initialRedeemProduct);
      setIsModalDeleteVisible(false);
      setIsLoadingUpdateStatus(false);
    }
  };

  const handleCreateRedeemProduct = () => {
    navigate('/redeem-products/add');
  };

  const handleClickDetail = (e: RedeemProductProps) => {
    navigate(`/products/${e.productId}`);
  };

  const handleClickAction = (props: RedeemProductProps, key: string) => {
    // if (key === 'detail') {
    // 	navigate(`/products/${props.productId}`);
    // } else
    if (key === 'edit') {
      navigate(`/redeem-products/${props.redeemProductId}/edit`);
    } else if (key === 'delete') {
      setIsModalDeleteVisible(true);
      setTmpData(props);
    }
  };

  const columns = [
    {
      title: 'IMAGE',
      dataIndex: 'images',
      key: 'images',
      width: 70,
      render: (record: RedeemProductProps) => {
        return (
          <Image
            style={{
              objectFit: 'cover',
            }}
            width={40}
            height={40}
            preview={false}
            src={
              record?.product?.images && record.product.images.length > 0
                ? record.product.images[0].imageUrl || DEFAULT_IMG
                : DEFAULT_IMG
            }
            placeholder={
              <Image preview={false} src={DEFAULT_IMG} width={40} height={40} />
            }
          />
        );
      },
    },
    {
      title: 'PRODUCT ID',
      dataIndex: 'productCode',
      key: 'productCode',
      width: 150,
      render: (text: string, record: RedeemProductProps) => {
        return (
          <Text
            className="table-link"
            onClick={() => handleClickDetail(record)}
          >
            {record.product?.productCode || ''}
          </Text>
        );
      },
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'productName',
      key: 'productName',
      width: 280,
      render: (text: string, record: RedeemProductProps) => {
        return (
          <div
          // className="table-link" onClick={() => handleClickDetail(record)}
          >
            <p style={{ marginBottom: 0 }}>{record.product?.productName}</p>
            {(() => {
              if (record.product && record.product?.categories.length > 0) {
                const findSubCat = record.product?.categories.filter(
                  (category) => category.parentCategoryId
                );
                const findCat = record.product?.categories.filter(
                  (category) => !category.parentCategoryId
                );

                if (findCat.length > 0 && findSubCat.length > 0) {
                  return (
                    <p
                      style={{
                        fontSize: 12,
                        marginBottom: 0,
                        color: '#768499',
                      }}
                    >{`${findCat[0].categoryName}, ${findSubCat[0].categoryName}`}</p>
                  );
                } else if (findCat.length > 0 && findSubCat.length === 0) {
                  return (
                    <p
                      style={{
                        fontSize: 12,
                        marginBottom: 0,
                        color: '#768499',
                      }}
                    >{`${findCat[0].categoryName}`}</p>
                  );
                }
              } else {
                return <></>;
              }
            })()}
          </div>
        );
      },
    },
    {
      title: 'AMOUNT POINT',
      dataIndex: 'pointAmount',
      key: 'pointAmount',
      width: 170,
      render: (text: string, record: RedeemProductProps) => {
        return (
          <>
            {record.pointAmount ? (
              <>{record.pointAmount.toLocaleString('id-ID')}</>
            ) : (
              <p style={{ marginBottom: 0, color: '#9FACBF' }}> Not Set</p>
            )}
          </>
        );
      },
    },
    {
      title: 'STATUS',
      key: 'isPublished',
      dataIndex: 'isPublished',
      width: 125,
      render: (status: any, record: RedeemProductProps) => (
        <>
          {
            <Switch
              checked={record.isPublished}
              onChange={() => {
                setIsModalVisible(true);
                setTmpData(record);
              }}
            />
          }
        </>
      ),
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 200,
      render: (updatedAt: any) => <div>{formatYearToTime(updatedAt)}</div>,
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (createdAt: any) => <div>{formatYearToTime(createdAt)}</div>,
    },
    {
      title: '',
      align: 'right',
      key: 'action',
      render: (_: any, record: RedeemProductProps) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ fontWeight: 700, cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ] as TableProps<any>['columns'];

  const menu = (record: RedeemProductProps) => (
    <Menu onClick={(e) => handleClickAction(record, e.key)}>
      <Menu.Item key="edit">Edit Redeem Product</Menu.Item>
      {/* <Menu.Item key='detail'>Detail Redeem Product</Menu.Item> */}
      <Menu.Item key="delete">Delete Redeem Product</Menu.Item>
    </Menu>
  );

  console.log(tmpData);

  return (
    <React.Fragment>
      <HeaderSection
        // icon={<CodeSandboxOutlined />}
        title="Redeem Product"
        // subtitle="Manage your Products"
        rightAction={
          <Space>
            <Button type="primary" onClick={handleCreateRedeemProduct}>
              Add Redeem Product
            </Button>
          </Space>
        }
      />

      {/* <ContainerFilter>
        <Input
          // size="large"
          placeholder="Search by product name"
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          // size="large"
          allowClear
          style={{ width: 160 }}
          onChange={handleChangeStatus}
          placeholder="Status"
        >
          <Option value="all">All</Option>
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      </ContainerFilter> */}
      <Row gutter={{ xs: 8, sm: 15 }} className="mb-7.5">
        <Col span={18}>
          <Typography.Text className="text-3 text-gray block font-semibold mb-1">
            Search
          </Typography.Text>
          <Input
            placeholder="Search by product name"
            suffix={<SearchOutlined />}
            allowClear
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col span={6}>
          <Typography.Text className="text-3 text-gray block font-semibold mb-1">
            Status
          </Typography.Text>
          <Select
            allowClear
            onChange={handleChangeStatus}
            placeholder="Status"
            defaultValue="all"
            suffixIcon={<IconArrowDown />}
          >
            <Option value="all">All</Option>
            <Option value={'true'}>Active</Option>
            <Option value={'false'}>Inactive</Option>
          </Select>
        </Col>
      </Row>

      {/* <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      /> */}

      <Row style={{ paddingBottom: 24 }}>
        <Col span={24}>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={data && data}
            pagination={{
              pageSize: pagination.perPage,
              current: pagination.page,
              style: { display: 'none' },
            }}
          />
        </Col>
        <CustomPagination
          data={data && data}
          pagination={pagination}
          changeLimit={changeLimit}
          changePage={changePage}
        />
      </Row>
      <Modal
        title="Update status confirmation"
        visible={isModalVisible}
        onOk={handleStatusChange}
        onCancel={() => {
          setIsModalVisible(false);
          setTmpData(initialRedeemProduct);
        }}
        okText="Yes"
        confirmLoading={isLoadingUpdateStatus}
        okButtonProps={{ type: 'primary' }}
      >
        <p>
          Are you sure want to change <b>"{tmpData.product?.productName}"</b>{' '}
          status to <b>"{!tmpData.isPublished ? 'Active' : 'Inactive'}"</b>?.{' '}
          {!tmpData.isPublished}
        </p>
      </Modal>
      <Modal
        title="Delete confirmation"
        visible={isModalDeleteVisible}
        onOk={handleDelete}
        onCancel={() => {
          setIsModalDeleteVisible(false);
          setTmpData(initialRedeemProduct);
        }}
        okText="Yes"
        confirmLoading={isLoadingUpdateStatus}
        okButtonProps={{ type: 'primary' }}
      >
        <p>
          Are you sure want to delete <b>"{tmpData.product?.productName}"</b> ?
        </p>
      </Modal>
    </React.Fragment>
  );
};

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

export default RedeemProducts;
