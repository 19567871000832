import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  message,
  Row,
  DatePicker,
  Space,
  Select,
  Col,
  Modal,
  Image,
  Button,
  Tag,
  Table,
  Typography,
  Spin,
} from 'antd';
import React, { useState } from 'react';
import type { DatePickerProps } from 'antd/es/date-picker';
import { initialProductImages } from '../../types/productImages.type';
import FormUploadImage from '../../components/FormUpload/FormUploadImage';
import { useNavigate, useParams } from 'react-router-dom';
import { getErrorMessage } from '../../helpers/errorHandler';
import HeaderSection from '../../components/HeaderSection';
import { generateFormRules } from '../../helpers/formRules';
import { httpRequest } from '../../helpers/api';
import HTMLPreview from '../../components/HTMLPreview';
import {
  BaseResponseProps,
  BaseResponsePaginationProps,
} from '../../types/config.type';
import { initialProduct, ProductProps } from '../../types/products.type';
import { CategoryProps } from '../../types/category.type';
import { LocationProps } from '../../types/location.type';
import { formatYearToTime, formatDate } from '../../helpers/constant';
import {
  initialProductStock,
  ProductStockProps,
} from '../../types/productStocks.type';
import {
  initialProductPrice,
  ProductPriceProps,
} from '../../types/productPrices.type';
import moment from 'moment';
import { ContainerFilter } from '.';
import useFetchList from '../../hooks/useFetchList';
import { replaceDashWithSpace } from '../../helpers/replaceDashWithSpace';
import { capitalizeFirstLetter } from '../../helpers/text';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { PromotionProps } from '../../types/promotion.type';
import formatPrice from '../../helpers/formatPrice';
import { DEFAULT_IMG } from '../../const/config';

interface ResponseProps extends BaseResponseProps<ProductProps> {
  payload: Omit<ProductProps, 'createdAt' | 'updatedAt'>;
}

interface ResponsePropsStock
  extends BaseResponsePaginationProps<ProductStockProps> {}
interface ResponsePropsPrice
  extends BaseResponsePaginationProps<ProductPriceProps> {}

interface ILocation {
  productId: string;
}

const { Text, Title } = Typography;
const { Option } = Select;

const ProductDetail: React.FC = () => {
  const navigate = useNavigate();
  const { productId } = useParams<keyof ILocation>() as ILocation;
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const [form] = Form.useForm();

  const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [isModalBackOpen, setIsModalBackOpen] = React.useState<boolean>(false);
  const [product, setProduct] = React.useState<ProductProps>(initialProduct);
  const [productPrice, setProductPrice] = React.useState<ProductPriceProps>();
  const [price, setPrice] = React.useState<ProductPriceProps[]>([
    initialProductPrice,
  ]);
  const [imageUrls, setImageUrls] = React.useState<string[]>([]);
  const [stockTmpData, setStockTmpData] =
    React.useState<ProductStockProps>(initialProductStock);
  const [priceTmpData, setPriceTmpData] =
    React.useState<ProductPriceProps>(initialProductPrice);
  const [stock, setStock] = React.useState<ProductStockProps[] | any>([
    initialProductStock,
  ]);
  const [modalType, setModalType] = React.useState<string>('');
  const [locations, setLocations] = React.useState<LocationProps[]>([]);
  const [images, setImages] = React.useState<File[]>([]);
  const [uploadImageUrls, setUploadImageUrls] = React.useState<string[]>([]);
  const [selectedImage, setSelectedImage] = React.useState<
    File | string | null
  >('');
  const [willBeDeletedImage, setWillBeDeletedImage] = React.useState<
    File | undefined
  >();
  const [formType, setFormType] = React.useState<string>('product');
  const [isLoadingDeleteImage, setIsLoadingDeleteImage] = React.useState(false);
  const [promoProduct, setPromoProduct] = useState<PromotionProps[]>([]);

  const { data } = useFetchList<ProductStockProps>({
    endpoint: '/product-stocks',
    initialQuery: {
      productIds: productId,
    },
  });

  React.useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        setIsLoadingData(true);

        const res = await httpRequest.get<ResponseProps>(
          '/products/' + productId
        );
        if (res && res.data && res.data.payload) {
          setProduct(res.data.payload);
          console.log(res.data.payload);

          if (res.data.payload.currentProductPrice) {
            setProductPrice(res.data.payload.currentProductPrice);
          }

          if (res.data.payload.categories.length > 0) {
            const findSubCat = res.data.payload.categories.filter(
              (category) => category.parentCategoryId
            );
            const findCat = res.data.payload.categories.filter(
              (category) => !category.parentCategoryId
            );

            if (findCat.length > 0 && findSubCat.length > 0) {
              setProduct({
                ...res.data.payload,
                subcat: findSubCat[0].categoryName,
                category: findCat[0].categoryName,
              });
            } else if (findCat.length > 0 && findSubCat.length === 0) {
              setProduct({
                ...res.data.payload,
                category: findCat[0].categoryName,
              });
            }
          }
        } else {
          message.error('Something went wrong');
        }

        // if (res.data.payload.prices.length > 0) {
        // 	const filterPrice = res.data.payload.prices.filter(
        // 		(price) =>
        // 			price.startAt &&
        // 			price.endAt &&
        // 			new Date(price.startAt) < new Date() &&
        // 			new Date(price.endAt) > new Date()
        // 	);

        // 	filterPrice.length > 0 && setProductPrice(filterPrice[filterPrice.length - 1]);
        // }

        const resStock = await httpRequest.get<ResponsePropsStock>(
          '/product-stocks?productIds=' + productId
        );
        if (resStock && resStock.data) {
          setStock(resStock.data.payload.results);
        } else {
          message.error('Something went wrong');
        }

        const resPrice = await httpRequest.get<ResponsePropsPrice>(
          '/product-prices?productIds=' + productId
        );

        if (resPrice && resPrice.data) {
          setPrice(resPrice.data.payload.results);
        } else {
          message.error('Something went wrong');
        }

        const locationData = await httpRequest.get<any>(`/locations`);

        if (locationData && locationData.data) {
          setLocations(locationData.data.payload.results);
        } else {
          message.error('Something went wrong');
        }

        const imgList = await httpRequest.get<any>(
          `/product-images/${productId}`
        );
        console.log(imgList);
        if (imgList && imgList.data) {
          setImageUrls(imgList.data.payload.map((item: any) => item.imageUrl));
        } else {
          message.error('Something went wrong');
        }

        // console.log(imgList.data.payload)
        // const dataImages = (res.data.payload.images || []).map((item) => ({
        //   url: item || "",
        //   uid: productId,
        //   name: item || "",
        // }));

        // setImages(dataImages as any[]);

        // if (dataImages.length > 0) {
        //   setSelectedImage(dataImages[0].url);
        // }

        const bcDetails = [
          {
            field: 'productId',
            value: productId,
            label: 'Detail Product',
          },
        ];
        setBreadcrumbDetails(bcDetails);

        setIsLoadingData(false);
        setIsLoadingAction(false);
      } catch (error) {
        setIsLoadingData(false);
      }
    };

    fetchProductDetail();
  }, [productId, isLoadingAction]);
  const [isLoadingPromo, setIsLoadingPromo] = useState(false);
  const [isPromoDiscount, setIsPromoDiscount] = useState(false);

  React.useEffect(() => {
    (async () => {
      setIsLoadingPromo(true);
      try {
        const res = await httpRequest.get<
          BaseResponsePaginationProps<PromotionProps>
        >(
          `/promotions?isArchive=false&isPublished=true&productIds=${productId}`
        );

        if (res && res.data) {
          let resPromo = res.data.payload.results
            .filter(
              (promo) =>
                promo.items.length === 1 &&
                promo.items.some((p) => p.productId === product.productId)
            )
            .sort((a, b) => a.items[0].quantity - b.items[0].quantity);
          setPromoProduct(resPromo);

          setIsPromoDiscount(
            resPromo.some(
              (promo) =>
                promo?.items?.length === 1 && promo?.items[0]?.quantity === 1
            )
          );
        } else {
          message.error('Something went wrong');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingPromo(false);
      }
    })();
  }, [product.productId]);

  const setSearch = async (search: string) => {
    setIsLoadingData(true);

    const searchLocation = locations
      .filter((item) => item.locationName.toLowerCase().indexOf(search) >= 0)
      .map((item) => item.locationId);

    const result = data.filter((item) =>
      searchLocation.includes(item.locationId)
    );

    setStock(result);
    setIsLoadingData(false);
  };

  const createStock = async () => {
    try {
      const filter = ({
        locationId,
        currentStock,
        baseUnitOfMeasure,
        description,
      }: any) => ({
        locationId,
        currentStock,
        baseUnitOfMeasure,
        description,
      });

      const fields = filter({ ...stockTmpData });

      const errorMessage = [];

      for (const [key, value] of Object.entries(fields)) {
        if (!value) errorMessage.push(key + ' must not be empty');
      }

      if (errorMessage.length !== 0) message.error(errorMessage[0]);

      if (errorMessage.length === 0) {
        const locationCode = locations.find((item) => {
          if (item.locationId === stockTmpData.locationId) {
            return item.locationCode;
          }
        });

        const formData = {
          bulk: [
            {
              newData: {
                description: stockTmpData.description,
                currentStock: stockTmpData.currentStock,
                baseUnitOfMeasure: stockTmpData.baseUnitOfMeasure,
              },
              condition: {
                productCode: product.productCode,
                locationCode: locationCode?.locationCode,
              },
            },
          ],
        };

        await httpRequest.put('/product-stocks/by-code', formData);

        setStockTmpData(initialProductStock);
        emptyForm();

        message.success(
          `${
            stockTmpData.productStockId !== ''
              ? 'Success edit stock'
              : 'Success add stock'
          }`
        );
        setIsLoadingAction(true);
        setIsModalVisible(false);
      }
    } catch (error) {
      message.error(getErrorMessage(error));
    }
  };

  const createPrice = async () => {
    try {
      const filter = ({ unitPrice, unitOfMeasureCode, minQty }: any) => ({
        unitPrice,
        unitOfMeasureCode,
        minQty,
      });

      const fields = filter({ ...priceTmpData });

      const errorMessage = [];

      for (const [key, value] of Object.entries(fields)) {
        if (!value) errorMessage.push(key + ' must not be empty');
      }

      if (errorMessage.length !== 0) message.error(errorMessage[0]);

      if (errorMessage.length === 0) {
        const formData = {
          productCode: product.productCode,
          startAt: priceTmpData.startAt,
          endAt: priceTmpData.endAt,
          unitPrice: priceTmpData.unitPrice,
          minQty: priceTmpData.minQty,
          unitOfMeasureCode: priceTmpData.unitOfMeasureCode.toLowerCase(),
        };

        await httpRequest.put('/product-prices/by-code', formData);

        setPriceTmpData(initialProductPrice);
        emptyForm();

        message.success(
          `${
            priceTmpData.productPriceId !== ''
              ? 'Success edit price'
              : 'Success add price'
          }`
        );
        setIsLoadingAction(true);
        setIsModalVisible(false);
      }
    } catch (error) {
      message.error(getErrorMessage(error));
    }
  };
  const uploadProductImages = async () => {
    try {
      const formUpload = new FormData();
      if (images) {
        console.log(images);
        images.forEach((image) => formUpload.append('images', image));
      }

      await httpRequest.post(
        `/product-images/upload/${productId}`,
        formUpload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      message.success('Success upload images.');
      setIsModalVisible(false);
      setIsLoadingAction(true);
      setImages([]);
    } catch (error) {
      setIsLoadingAction(false);
      message.error('images cannot be empty.');
    }
  };

  const handleDeleteImage = async () => {
    try {
      setIsLoadingDeleteImage(true);
      setImages(
        images.filter((item, index) => {
          setUploadImageUrls(
            uploadImageUrls.filter((item) => item !== uploadImageUrls[index])
          );
          return item !== willBeDeletedImage;
        })
      );
      setIsLoadingDeleteImage(false);
      setWillBeDeletedImage(undefined);
    } catch (err) {
      message.error(getErrorMessage(err));
      setIsLoadingDeleteImage(false);
    }
  };

  const onStartDateChange = (value: DatePickerProps['value']) => {
    setPriceTmpData({
      ...priceTmpData,
      startAt: moment(value).toDate(),
    });
    // console.log("onOk: ", moment(value).toDate());
  };

  const onEndDateChange = (value: DatePickerProps['value']) => {
    setPriceTmpData({
      ...priceTmpData,
      endAt: moment(value).toDate(),
    });
    // console.log("onEndOk: ", moment(value).toDate());
  };

  const showModalBack = () => {
    setIsModalBackOpen(true);
  };

  const handleOkModalBack = () => {
    isModalVisible ? setIsModalVisible(false) : navigate(-1);
    setIsModalBackOpen(false);
    emptyForm();
    setImages([]);
    setUploadImageUrls([]);
    setSelectedImage('');
  };

  const emptyForm = () => {
    form.setFieldsValue({
      description: '',
      locationId: '',
      currentStock: '',
      baseUnitOfMeasure: '',
      productCode: '',
      startAt: '',
      endAt: '',
      unitPrice: '',
      minQty: '',
      unitOfMeasureCode: '',
    });
  };

  const handleCancelModalBack = () => {
    setIsModalBackOpen(false);
  };

  const onLocationChange = (value: string) => {
    setStockTmpData({
      ...stockTmpData,
      locationId: value,
    });
  };

  const stockColumns = [
    {
      title: 'LOCATION NAME',
      key: 'locationId',
      dataIndex: 'locationId',
      width: 600,
      render: (text: string, record: ProductStockProps) => {
        return (
          <Text>
            {record?.locationId
              ? locations.find((item) => item.locationId === record?.locationId)
                  ?.locationName
              : record?.locationId}
          </Text>
        );
      },
    },
    {
      title: 'STOCK',
      dataIndex: 'currentStock',
      key: 'currentStock',
      width: 200,
      render: (text: string, record: ProductStockProps) => {
        return <Text>{record?.currentStock}</Text>;
      },
    },
    {
      title: 'UNIT',
      dataIndex: 'baseUnitOfMeasure',
      key: 'baseUnitOfMeasure',
      width: 200,
      render: (text: string, record: ProductStockProps) => {
        return <Text>{record?.baseUnitOfMeasure}</Text>;
      },
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: any) => <div>{formatYearToTime(updatedAt)}</div>,
    },
    // {
    //     title: '',
    //     key: 'action',
    //     render: (_: any, record: ProductStockProps) => (
    //   <>
    //   <Button icon={<EditOutlined />} style={{marginRight: '10px'}} type="primary"
    //             onClick={() => {
    //                 setModalType('stock')
    //                 setStockTmpData(record)
    //                 form.setFieldsValue({
    //                     ...record,
    //                 })
    //                 setIsModalVisible(true)
    //             }}>
    //             </Button>
    //       </>
    //     ),
    // },
  ];

  const priceColumns = [
    {
      title: 'Min Qty',
      key: 'minQty',
      dataIndex: 'minQty',
      render: (text: string, record: ProductPriceProps) => {
        return (
          <Text>
            {record?.minQty?.toLocaleString('en-US')}{' '}
            {record?.unitOfMeasureCode}
          </Text>
        );
      },
    },
    // {
    //   title: "Unit",
    //   dataIndex: "unitOfMeasureCode",
    //   key: "unitOfMeasureCode",
    //   render: (text: string, record: ProductPriceProps) => {
    //     return <Text>{record.unitOfMeasureCode}</Text>;
    //   },
    // },
    {
      title: 'Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (text: string, record: ProductPriceProps) => {
        return <Text>{record?.unitPrice?.toLocaleString('en-US')}</Text>;
      },
    },
    {
      title: 'Price Period',
      dataIndex: 'startAt',
      key: 'startAt',
      render: (startAt: any, record: ProductPriceProps) => (
        <div>
          {(() => {
            if (record?.startAt && record?.endAt) {
              if (
                new Date(record?.startAt) < new Date() &&
                new Date(record?.endAt) > new Date()
              ) {
                return (
                  <>
                    <p>
                      {formatDate(record?.startAt)} -{' '}
                      {formatDate(record?.endAt)}
                      <Tag
                        style={{
                          border: '2px solid #31d63a',
                          color: '#31d63a',
                          marginLeft: 5,
                        }}
                      >
                        current
                      </Tag>
                    </p>
                  </>
                );
              } else {
                return (
                  <p>
                    {formatDate(record?.startAt)} - {formatDate(record?.endAt)}
                  </p>
                );
              }
            } else {
              return <></>;
            }
          })()}
        </div>
      ),
    },
    // {
    //   title: "Created at",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   render: (createdAt: any) => <div>{formatYearToTime(createdAt)}</div>,
    // },
    // {
    //   title: '',
    //   key: 'action',
    //   render: (_: any, record: ProductPriceProps) => (
    //   <>
    //   <Button icon={<EditOutlined />} style={{marginRight: '10px'}} type="primary"
    //             onClick={() => {
    //                 setModalType('price')
    //                 setPriceTmpData(record)
    //                 form.setFieldsValue({
    //                     ...record,
    //                 })
    //                 setIsModalVisible(true)
    //             }}>
    //             </Button>
    //       </>
    //     ),
    // },
  ];
  console.log(product);
  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={product?.productName
          .split(' ')
          .map((value) => capitalizeFirstLetter(value))
          .join(' ')}
        // subtitle="Manage your product data"
        rightAction={
          <Space>
            <Button
              style={{ padding: '0px 32px' }}
              type="primary"
              onClick={() => navigate('/products/' + productId + '/edit')}
            >
              Edit
            </Button>
          </Space>
        }
      />
      {isLoadingData && <Spin spinning />}

      {!isLoadingData && (
        <>
          <Row
            style={{
              borderBottom: '2px solid #768499',
              marginLeft: '-20px',
              marginRight: '-20px',
              paddingLeft: '20px',
              paddingRight: '20px',
              marginBottom: 20,
            }}
          >
            <Col
              span={8}
              style={
                {
                  // borderBottom: '2px solid #768499',
                }
              }
            >
              <button
                onClick={() => {
                  setFormType('product');
                }}
                style={{
                  padding: '15px 6px',
                  width: '100%',
                  fontWeight: 700,
                  background: 'transparent',
                  color: formType === 'product' ? '#9E2A2B' : 'black',
                  borderRadius: 0,
                  zIndex: 2,
                  borderTop: 'none',
                  borderRight: 'none',
                  borderLeft: 'none',
                  borderBottom:
                    formType === 'product' ? '3px solid #9E2A2B' : 'none',
                  marginBottom: '-1px',
                }}
              >
                Product Information
              </button>
            </Col>
            <Col span={8}>
              <button
                onClick={() => {
                  setFormType('stock');
                }}
                style={{
                  padding: '15px 6px',
                  fontWeight: 700,
                  width: '100%',
                  background: 'transparent',
                  color: formType === 'stock' ? '#9E2A2B' : 'black',
                  borderRadius: 0,
                  zIndex: 2,
                  borderTop: 'none',
                  borderRight: 'none',
                  borderLeft: 'none',
                  borderBottom:
                    formType === 'stock' ? '3px solid #9E2A2B' : 'none',
                  marginBottom: '-1px',
                }}
              >
                Stock
              </button>
            </Col>
            <Col span={8}>
              <button
                onClick={() => {
                  setFormType('price');
                }}
                style={{
                  padding: '15px 6px',
                  fontWeight: 700,
                  width: '100%',
                  background: 'transparent',
                  color: formType === 'price' ? '#9E2A2B' : 'black',
                  borderRadius: 0,
                  zIndex: 2,
                  borderTop: 'none',
                  borderRight: 'none',
                  borderLeft: 'none',
                  borderBottom:
                    formType === 'price' ? '3px solid #9E2A2B' : 'none',
                  marginBottom: '-1px',
                }}
              >
                Price
              </button>
            </Col>
          </Row>
          <div
            style={{
              display: formType === 'product' ? undefined : 'none',
              paddingBottom: 50,
            }}
          >
            <Row>
              <Col span={5}>
                <Title level={5}>Data Product Information</Title>
                <Text style={{ color: '#768499' }}>
                  These are product details, you can't change here
                </Text>
              </Col>
              <Col offset={1} span={16}>
                <Title
                  level={5}
                  style={{
                    color: '#768499',
                    marginBottom: 2,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Images
                </Title>
                {!isLoadingData ? (
                  <Row gutter={[16, 16]}>
                    {imageUrls?.length > 0 ? (
                      imageUrls.map((item) => {
                        return (
                          <Col>
                            <Image
                              preview={true}
                              width={100}
                              height={100}
                              src={item}
                              fallback={DEFAULT_IMG}
                              style={{
                                objectFit: 'cover',
                                border: 'solid 1px red',
                                boxShadow: '2px 2px #888888',
                              }}
                              placeholder={
                                <Image
                                  preview={false}
                                  src={DEFAULT_IMG}
                                  width={100}
                                  height={100}
                                  style={{ objectFit: 'cover' }}
                                />
                              }
                            />
                          </Col>
                        );
                      })
                    ) : (
                      <Col>
                        <Image
                          preview={true}
                          width={100}
                          height={100}
                          src={DEFAULT_IMG}
                          style={{
                            objectFit: 'cover',
                            border: 'solid 1px red',
                            boxShadow: '2px 2px #888888',
                          }}
                        />
                      </Col>
                    )}
                    {/* <div style={{
                    background: '#00133B',
                    fontWeight: 600,
                    cursor: 'pointer',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 100, 
                    width: 100}}
                    
                    onClick={() => {
                      setIsModalVisible(true)
                      setModalType('image')
                    }}>
                    Add Image
                    <PlusOutlined />
                  </div> */}
                  </Row>
                ) : null}

                <Title
                  level={5}
                  style={{
                    color: '#768499',
                    marginBottom: 2,
                    marginTop: 30,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Product ID
                </Title>
                <Text>{product?.productCode}</Text>

                <Title
                  level={5}
                  style={{
                    color: '#768499',
                    marginBottom: 2,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Product Name
                </Title>
                <Text>{product?.productName}</Text>

                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Price
                    </Title>

                    {isLoadingPromo && <Spin spinning />}
                    {!isLoadingPromo && (
                      <>
                        <Text
                          className="block"
                          style={{
                            textDecoration: isPromoDiscount
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          {product && product?.currentProductPrice?.unitPrice
                            ? `IDR ${product?.currentProductPrice?.unitPrice?.toLocaleString(
                                'id-ID'
                              )}`
                            : 'IDR -'}
                        </Text>

                        {promoProduct.length > 0 &&
                          promoProduct.map((promo) => (
                            <Text className="block" style={{ marginBottom: 2 }}>
                              IDR {formatPrice(promo?.finalPrice! || 0)}
                            </Text>
                          ))}
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Qty
                    </Title>

                    {isLoadingPromo && <Spin spinning />}
                    {!isLoadingPromo && (
                      <>
                        <Text
                          style={{
                            textDecoration: isPromoDiscount
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          {'>='} 1 {productPrice?.unitOfMeasureCode}
                        </Text>

                        {promoProduct.length > 0 &&
                          promoProduct.map((promo) => (
                            <Text className="block" style={{ marginBottom: 2 }}>
                              {'>='} {promo?.items[0]?.quantity! || 0}{' '}
                              {
                                promo?.items[0]?.product?.currentProductPrice
                                  ?.unitOfMeasureCode
                              }
                            </Text>
                          ))}
                      </>
                    )}
                  </Col>
                </Row>

                <Title
                  level={5}
                  style={{
                    color: '#768499',
                    marginBottom: 2,
                    marginTop: 20,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Period
                </Title>
                <Text>
                  {productPrice && productPrice.startAt && productPrice.endAt
                    ? `${formatYearToTime(
                        productPrice.startAt
                      )} - ${formatYearToTime(productPrice.endAt)}`
                    : ''}
                </Text>
              </Col>
            </Row>
            <Row style={{ marginTop: 50 }}>
              <Col span={5}>
                <Title level={5}>Data Product Details</Title>
                <Text style={{ color: '#768499' }}>
                  These are product details, you can't change here
                </Text>
              </Col>
              <Col offset={1} span={16}>
                <Row>
                  <Col span={7}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Country
                    </Title>
                    <Text>{product?.country ? product?.country : ''}</Text>
                  </Col>
                  <Col span={7}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ABV
                    </Title>
                    <Text>{product?.abv ? `${product?.abv}%` : ''}</Text>
                  </Col>
                  <Col span={7}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Size
                    </Title>
                    <Text>
                      {product?.sizeInML ? `${product?.sizeInML}ml` : ''}
                    </Text>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={7}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Category
                    </Title>
                    <Text>
                      {product?.category
                        ? product?.category.includes('-')
                          ? replaceDashWithSpace(product?.category)
                          : product?.category.charAt(0).toUpperCase() +
                            product?.category.toLowerCase().slice(1)
                        : ''}
                    </Text>
                  </Col>
                  <Col span={7}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Sub Category
                    </Title>
                    <Text>
                      {product?.subcat
                        ? product?.subcat.includes('-')
                          ? replaceDashWithSpace(product?.subcat)
                          : product?.subcat.charAt(0).toUpperCase() +
                            product?.subcat.toLowerCase().slice(1)
                        : ''}
                    </Text>
                  </Col>
                </Row>

                <Row style={{ marginTop: 20 }}>
                  <Col span={7}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Gross Weight
                    </Title>
                    <Text>
                      {product?.grossWeight ? `${product?.grossWeight} kg` : ''}
                    </Text>
                  </Col>
                  <Col span={7}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Net Weight
                    </Title>
                    <Text>
                      {product?.netWeight ? `${product?.netWeight} kg` : ''}
                    </Text>
                  </Col>
                  <Col span={7}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Volume
                    </Title>
                    <Text>
                      {product?.volume ? `${product?.volume} m` : ''}
                      {product?.volume && <sup>3</sup>}
                    </Text>
                  </Col>
                </Row>

                <Title
                  level={5}
                  style={{
                    color: '#768499',
                    marginTop: 20,
                    marginBottom: 2,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Description
                </Title>
                <Text>
                  {product?.description ? (
                    <HTMLPreview html={product?.description} />
                  ) : (
                    ''
                  )}
                </Text>

                <Title
                  level={5}
                  style={{
                    color: '#768499',
                    marginBottom: 2,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Status
                </Title>
                <Text>{product?.isPublished ? 'Active' : 'Inactive'}</Text>

                <Row style={{ marginTop: 20 }}>
                  <Col span={7}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Created At
                    </Title>
                    <Text>{formatYearToTime(product?.createdAt)}</Text>
                  </Col>
                  <Col span={7}>
                    <Title
                      level={5}
                      style={{
                        color: '#768499',
                        marginBottom: 2,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Updated At
                    </Title>
                    <Text>{formatYearToTime(product?.updatedAt)}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div
            style={{
              // minHeight: '100vh',
              display: formType === 'stock' ? undefined : 'none',
              paddingBottom: 50,
            }}
          >
            <Row>
              <Col span={24}>
                <ContainerFilter>
                  <Input
                    // size="large"
                    placeholder="Search by location Name"
                    prefix={<SearchOutlined />}
                    allowClear
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </ContainerFilter>
                <Table
                  rowKey="productStockId"
                  loading={isLoadingData}
                  columns={stockColumns}
                  dataSource={stock}
                  pagination={false}
                />
              </Col>
            </Row>
          </div>
          <div
            style={{
              // minHeight: '100vh',
              display: formType === 'price' ? undefined : 'none',
              paddingBottom: 50,
            }}
          >
            <Row>
              <Col span={24}>
                <ContainerFilter>
                  <Input
                    // size="large"
                    placeholder="Search by location Name"
                    prefix={<SearchOutlined />}
                    allowClear
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </ContainerFilter>
                <Table
                  rowKey="productPriceId"
                  loading={isLoadingData}
                  columns={priceColumns}
                  dataSource={price}
                  pagination={false}
                />
              </Col>
            </Row>
          </div>
        </>
      )}

      {/* <Space
          style={{
            width: "100%",
            background: "grey",
            justifyContent: "center",
          }}
        >
          <Image
            preview={false}
            width="100%"
            height={200}
            src={selectedImage.url || DEFAULT_IMG}
            fallback={DEFAULT_IMG}
            style={{ objectFit: "cover" }}
            placeholder={
              <Image
                preview={false}
                src=DEFAULT_IMG
                width="100%"
                height={200}
                style={{ objectFit: "cover" }}
              />
            }
          />
        </Space> */}
      {/* <Space direction="horizontal">
          {images.map((img) => (
            <div
              style={
                selectedImage.uid === img.uid
                  ? {
                      border: 5,
                      borderStyle: "solid",
                      borderColor: "#FF4A50",
                      position: "relative",
                    }
                  : { position: "relative" }
              }
            >
              <Image
                onClick={() => {
                  setSelectedImage(img);
                }}
                preview={false}
                width={100}
                height={100}
                src={img.url || img.thumbUrl || DEFAULT_IMG}
                fallback={DEFAULT_IMG}
                style={{ objectFit: "cover" }}
                placeholder={
                  <Image
                    preview={false}
                    src=DEFAULT_IMG
                    width={100}
                    height={100}
                    style={{ objectFit: "cover" }}
                  />
                }
              />
              <div
                onClick={() => {}}
                style={{
                  cursor: "pointer",
                  backgroundColor: "grey",
                  position: "absolute",
                  top: 5,
                  right: 5,
                  paddingRight: 5,
                  paddingLeft: 5,
                  zIndex: 100,
                }}
              >
                <CloseOutlined width={20} height={20} color="#FFFFFF" />
              </div>
            </div>
          ))}
        </Space> */}
      {/* <Col flex="auto" style={{textAlign: 'right'}}>
								<Button icon={<PlusOutlined />} 
                                style={{marginRight: '10px'}}
                                type="primary" 
                                onClick={() => {
                                    setModalType('stock')
                                    setIsModalVisible(true)
                                    setStockTmpData(initialProductStock)
                                }}>
									Add Stock
								</Button>
						</Col> */}
      {/* <Card bordered={false} size="small" style={{paddingTop: 20}}>
        <Row gutter={2}>
          <Col>
            <Title level={4}>Price</Title>
          </Col> */}
      {/* <Col flex="auto" style={{textAlign: 'right'}}>
								<Button icon={<PlusOutlined />} 
                                style={{marginRight: '10px'}}
                                type="primary" 
                                onClick={() => {
                                    setModalType('price')
                                    setIsModalVisible(true)
                                    setPriceTmpData(initialProductPrice)
                                }}>
									Add Price
								</Button>
						</Col> */}
      {/* </Row> */}

      {/* <Table
        rowKey="productPriceId"
        loading={isLoading}
        columns={priceColumns}
        dataSource={price}
        pagination={false}
        />
      </Card> */}
      {(() => {
        if (modalType === 'stock') {
          return (
            <Modal
              width={1000}
              style={{ top: 10 }}
              open={isModalVisible}
              onOk={() => {
                createStock();
              }}
              onCancel={() => {
                showModalBack();
              }}
              okText="Save"
              okButtonProps={{ type: 'primary' }}
            >
              <Title level={3}>
                {(stockTmpData.productStockId !== '' ? 'Edit ' : 'Add ') +
                  modalType}
              </Title>
              <Form
                form={form}
                name="stockForm"
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item
                  label="Location"
                  name="locationId"
                  key="locationId"
                  rules={generateFormRules('Location', ['required'])}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select Location"
                    optionFilterProp="children"
                    onChange={onLocationChange}
                    // onSearch={onSearch}
                  >
                    {locations.map((item) => {
                      return (
                        <Option value={item.locationId}>
                          {item.locationName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Current Stock"
                  name="currentStock"
                  key="currentStock"
                  rules={generateFormRules('currentStock', [
                    'required',
                    'numeric',
                  ])}
                >
                  <Input
                    onChange={(event) =>
                      setStockTmpData({
                        ...stockTmpData,
                        currentStock: parseInt(event.target.value),
                      })
                    }
                    placeholder="Input Stock"
                  />
                </Form.Item>

                <Form.Item
                  label="Base Unit Of Measure"
                  name="baseUnitOfMeasure"
                  key="baseUnitOfMeasure"
                  rules={generateFormRules('baseUnitOfMeasure', ['required'])}
                >
                  <Input
                    onChange={(event) =>
                      setStockTmpData({
                        ...stockTmpData,
                        baseUnitOfMeasure: event.target.value,
                      })
                    }
                    placeholder="PCS"
                  />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  key="description"
                  rules={generateFormRules('description', ['required'])}
                >
                  <Input
                    onChange={(event) =>
                      setStockTmpData({
                        ...stockTmpData,
                        description: event.target.value,
                      })
                    }
                    placeholder="description"
                  />
                </Form.Item>
              </Form>
            </Modal>
          );
        } else if (modalType === 'price') {
          return (
            <Modal
              width={1000}
              style={{ top: 10 }}
              open={isModalVisible}
              onOk={() => {
                createPrice();
              }}
              onCancel={() => {
                showModalBack();
              }}
              okText="Save"
              okButtonProps={{ type: 'primary' }}
            >
              <Title level={3}>
                {(priceTmpData.productPriceId !== '' ? 'Edit ' : 'Add ') +
                  modalType}
              </Title>
              <Form
                form={form}
                name="priceForm"
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item
                  label="Unit Price"
                  name="unitPrice"
                  key="unitPrice"
                  rules={generateFormRules('unitPrice', [
                    'required',
                    'numeric',
                  ])}
                >
                  <Input
                    onChange={(event) =>
                      setPriceTmpData({
                        ...priceTmpData,
                        unitPrice: parseInt(event.target.value),
                      })
                    }
                    placeholder="25000"
                  />
                </Form.Item>

                <Form.Item
                  label="Base Unit Of Measure"
                  name="unitOfMeasureCode"
                  key="unitOfMeasureCode"
                  rules={generateFormRules('Base Unit Of Measure', [
                    'required',
                  ])}
                >
                  <Input
                    onChange={(event) =>
                      setPriceTmpData({
                        ...priceTmpData,
                        unitOfMeasureCode: event.target.value,
                      })
                    }
                    placeholder="pcs"
                  />
                </Form.Item>

                <Form.Item
                  label="Min. Qty"
                  name="minQty"
                  key="minQty"
                  rules={generateFormRules('minQty', ['required', 'numeric'])}
                >
                  <Input
                    type="number"
                    onChange={(event) =>
                      setPriceTmpData({
                        ...priceTmpData,
                        minQty: parseInt(event.target.value),
                      })
                    }
                    placeholder="10"
                  />
                </Form.Item>
                <Row gutter={[24, 0]}>
                  <Col span={12}>
                    <h4>Start At</h4>
                    <DatePicker
                      showTime
                      onOk={onStartDateChange}
                      defaultValue={moment(priceTmpData.startAt)}
                      showToday={true}
                      style={{
                        maxWidth: '50%',
                        minWidth: '30%',
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <h4>End At</h4>
                    <DatePicker
                      showTime
                      onOk={onEndDateChange}
                      defaultValue={moment(priceTmpData.endAt)}
                      showToday={true}
                      style={{
                        maxWidth: '50%',
                        minWidth: '30%',
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            </Modal>
          );
        } else if (modalType === 'image') {
          return (
            <Modal
              width={1000}
              style={{ top: 10 }}
              open={isModalVisible}
              onOk={() => {
                uploadProductImages();
              }}
              onCancel={() => {
                showModalBack();
              }}
              okText="Save"
            >
              <Form
                name="uploadImagesForm"
                layout="vertical"
                initialValues={initialProductImages}
                autoComplete="off"
              >
                <h4>Product Images</h4>
                <FormUploadImage
                  mode={'single-large'}
                  maxSizeInMB={5}
                  imageUrl={
                    typeof selectedImage === 'string'
                      ? selectedImage
                      : uploadImageUrls[uploadImageUrls.length - 1]
                  }
                  onChange={(file: any) => {
                    setImages((prevFile) => [...prevFile, file]);
                  }}
                  onPreviewChange={(previewUrl: any) => {
                    setUploadImageUrls((prevUrl) => [...prevUrl, previewUrl]);
                  }}
                />
                <Space direction="horizontal">
                  {images.map((img, index) => (
                    <div
                      key={index}
                      style={
                        selectedImage === img
                          ? {
                              border: 5,
                              borderStyle: 'solid',
                              borderColor: '#FF4A50',
                              position: 'relative',
                            }
                          : { position: 'relative' }
                      }
                    >
                      <Image
                        onClick={() => {
                          setSelectedImage(uploadImageUrls[index]);
                        }}
                        key={index}
                        preview={true}
                        width={100}
                        height={100}
                        src={uploadImageUrls[index] || DEFAULT_IMG}
                        fallback={DEFAULT_IMG}
                        style={{ objectFit: 'cover' }}
                        placeholder={
                          <Image
                            preview={false}
                            src={DEFAULT_IMG}
                            width={100}
                            height={100}
                            style={{ objectFit: 'cover' }}
                          />
                        }
                      />
                      <div
                        onClick={() => {
                          setWillBeDeletedImage(img);
                        }}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: 'grey',
                          position: 'absolute',
                          top: 5,
                          right: 5,
                          paddingRight: 5,
                          paddingLeft: 5,
                          zIndex: 100,
                        }}
                      >
                        <CloseOutlined width={20} height={20} color="#FFFFFF" />
                      </div>
                    </div>
                  ))}
                </Space>
              </Form>
            </Modal>
          );
        }
      })()}
      <Modal
        title="Cancel Confirmation"
        open={isModalBackOpen}
        onOk={handleOkModalBack}
        onCancel={handleCancelModalBack}
      >
        <p>Are you sure ? Your data won't be save.</p>
      </Modal>

      <Modal
        title="Confirmation"
        visible={!!willBeDeletedImage}
        onOk={handleDeleteImage}
        onCancel={() => {
          setWillBeDeletedImage(undefined);
        }}
        okText="Yes"
        confirmLoading={isLoadingDeleteImage}
        okButtonProps={{ type: 'primary' }}
      >
        <p>Are you sure want to delete this image?</p>
      </Modal>
    </React.Fragment>
  );
};

export default ProductDetail;

function sortCategories(categories: CategoryProps[]) {
  categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
  return categories;
}
