import {
  Form,
  message,
  Radio,
  Space,
  Button,
  Card,
  Spin,
  Row,
  Col,
  Typography,
  InputNumber,
  Select,
} from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import {
  RedeemProductProps,
  initialRedeemProduct,
} from '../../types/redeemProducts.type';
import { ProductProps } from '../../types/products.type';
import useFetchList from '../../hooks/useFetchList';

interface ILocation {
  redeemProductId: string;
}

interface ResponseProps extends BaseResponseProps<RedeemProductProps> {
  payload: Omit<RedeemProductProps, 'createdAt' | 'updatedAt'>;
}

const { Title, Text } = Typography;
const { Option } = Select;

const RedeemProductsEdit: React.FC = () => {
  const navigate = useNavigate();
  const { redeemProductId } = useParams<keyof ILocation>() as ILocation;
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [redeemProduct, setRedeemProduct] =
    React.useState<RedeemProductProps>(initialRedeemProduct);

  const createProduct = async () => {
    try {
      setIsLoadingAction(true);

      console.log(redeemProduct);

      const res: any = await httpRequest.post(
        '/redeem-products',
        redeemProduct
      );

      if (res) {
        message.success(
          'Success create ' + res.data.payload.product?.productName
        );
        navigate('/redeem-products');
      }
    } catch (error) {
      console.log(error);
      setIsLoadingAction(false);
    }
  };

  const updateProduct = async () => {
    try {
      setIsLoadingAction(true);

      const res: any = await httpRequest.patch(
        '/redeem-products/' + redeemProductId,
        redeemProduct
      );

      if (res) {
        message.success(
          'Success update ' + res.data.payload.product?.productName
        );
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (
    values: Omit<
      RedeemProductProps,
      'createdAt' | 'updatedAt' | 'statusLoading'
    >
  ) => {
    if (redeemProductId) {
      updateProduct();
    } else {
      createProduct();
    }
  };

  const { data: productList } = useFetchList<ProductProps>({
    endpoint: 'products',
    limit: 10,
  });

  React.useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        const res = await httpRequest.get<ResponseProps>(
          '/redeem-products/' + redeemProductId
        );

        if (res && res.data) {
          setRedeemProduct(res.data.payload);

          form.setFieldsValue({
            ...res.data.payload,
          });
        } else {
          message.error('Something went wrong');
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    if (redeemProductId) {
      fetchProductDetail();
    }
  }, [redeemProductId]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={(redeemProductId ? 'Edit' : 'Add') + ' Redeem'}
        rightAction={
          <Space>
            <Button
              style={{ padding: '0px 32px' }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              style={{ padding: '0px 32px' }}
              loading={isLoadingAction}
              type="primary"
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </Space>
        }
      />

      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <Form
            form={form}
            name="redeemProductForm"
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Row>
              <Col span={5}>
                <Title level={5}>Product Redeem</Title>
                <Text style={{ color: '#768499' }}>
                  These are redeem details, you can change anything
                </Text>
              </Col>
              <Col offset={1} span={16}>
                <Row>
                  <Col span={24}>
                    <Form.Item name="productId">
                      <Title
                        level={5}
                        style={{
                          color: '#768499',
                          marginBottom: 2,
                          fontSize: 14,
                        }}
                      >
                        Product
                      </Title>
                      <Select
                        allowClear
                        value={redeemProduct?.productId}
                        onChange={(e: any) => {
                          setRedeemProduct({
                            ...redeemProduct,
                            productId: e,
                          });
                        }}
                        placeholder="Product"
                      >
                        {productList.map((item) => (
                          <Option value={item.productId}>
                            {item.productName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name="pointAmount">
                      <Title
                        level={5}
                        style={{
                          color: '#768499',
                          marginBottom: 2,
                          fontSize: 14,
                        }}
                      >
                        Point Redeem
                      </Title>
                      <InputNumber
                        width={500}
                        value={redeemProduct?.pointAmount?.toString()}
                        onChange={(e: any) => {
                          setRedeemProduct({
                            ...redeemProduct,
                            pointAmount: e,
                          });
                        }}
                        addonAfter={
                          <p style={{ marginBottom: 0, color: '#9FACBF' }}>
                            Point
                          </p>
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name="isPublished">
                      <Title
                        level={5}
                        style={{
                          color: '#768499',
                          marginBottom: 2,
                          fontSize: 14,
                        }}
                      >
                        Status
                      </Title>
                      <Radio.Group
                        onChange={(e) =>
                          setRedeemProduct({
                            ...redeemProduct,
                            isPublished: e.target.value,
                          })
                        }
                        defaultValue={true}
                        value={redeemProduct?.isPublished}
                      >
                        <CustomRadio value={true}>Active</CustomRadio>
                        <CustomRadio value={false}>Inactive</CustomRadio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </React.Fragment>
  );
};

export default RedeemProductsEdit;

const CustomRadio = styled(Radio)`
  margin-right: 5rem;
  .ant-radio-checked .ant-radio-inner {
    border-color: #1e1e1e;
    border-width: 2px;
    box-shadow: none;
  }
  .ant-radio:hover .ant-radio-inner {
    background-color: white;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #1e1e1e;
  }
`;
