import {
	Col,
	Row,
	Divider,
	Typography,
	Button,
	Modal,
	Input,
	message,
	Image,
	Form,
	Spin,
	Radio,
	DatePicker,
	TimePicker,
} from 'antd';
import ReactToPrint from 'react-to-print';
import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import DetailItem from '../../components/DetailItem';
import {
	DetailsTransaction,
	ECourierVendor,
	EPaymentMethod,
	ETransactionStatus,
	initialTransactions,
	TransactionDetailProperties,
	TransactionProps,
} from '../../types/transaction';
import SectionContent from '../../components/SectionContent';
import { colorTransactionStatus } from './index';
import moment from 'moment';
import formatPrice from '../../helpers/formatPrice';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import HargaCoret from '../../components/HargaCoret';
import ConfirmModal from '../../components/ConfirmModal';
import styled from 'styled-components';
import { Invoince } from '../../components/Invoince';
import { DEFAULT_IMG } from '../../const/config';

interface IParams {
	transactionId: string;
}

interface ResponseProps extends BaseResponseProps<TransactionProps> {
	payload: Omit<TransactionProps, 'updatedAt'>;
}

const TransactionDetail: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const { transactionId } = useParams<keyof IParams>() as IParams;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [transaction, setTransaction] = React.useState<TransactionProps>(initialTransactions);
	const [selectedTransactionStatus, setSelectedTransactionStatus] = React.useState(
		transaction.transactionStatus
	);
	const [isModalWaybillVisible, setIsModalWaybillVisible] = React.useState<boolean>(false);
	const [isModalRequetVisible, setIsModalRequetVisible] = React.useState<boolean>(false);
	const [isModalUpdateStatusVisible, setIsModalUpdateStatusVisible] =
		React.useState<boolean>(false);
	const [updateStatus, setUpdateStatus] = React.useState<Record<string, any>>({
		record: transaction,
		newStatus: ETransactionStatus.created,
	});
	const [dataRequest, setDataRequest] = useState<any>({
		type: '',
		date: null,
		time: null,
	});
	const [isShowModalUpdateStatusToShipped, setisShowModalUpdateStatusToShipped] =
		React.useState<boolean>(false);
	const [waybill, setWaybill] = React.useState<string | undefined>(
		transaction.transactionShipment?.waybill
	);
	const [isFetch, setIsFetch] = React.useState(false);
	const componentRef = useRef<any>();

	React.useEffect(() => {
		const fetchTransactionDetail = async () => {
			try {
				setIsLoading(true);
				const res = await httpRequest.get<any>('/transactions/' + transactionId);

				if (res && res.data) {
					setSelectedTransactionStatus(res.data.payload.transactionStatus);
					setWaybill(res.data.payload.transactionShipment?.waybill);
					setTransaction({
						...res.data.payload,
						details: res.data.payload.details
							? res.data.payload.details.items
								? res.data.payload.details
								: { items: res.data.payload.details }
							: transaction.details,
					});

					const bcDetails = [
						{
							field: 'transactionId',
							value: transactionId,
							label: res.data.payload.transactionCode,
						},
					];
					setBreadcrumbDetails(bcDetails);

					console.log(res.data.payload);
				} else {
					message.error('Something went wrong');
				}

				setIsLoading(false);
				setIsFetch(false);
			} catch (error) {
				console.log(error);
				setIsLoading(false);
			}
		};
		fetchTransactionDetail();
	}, [transactionId, selectedTransactionStatus, isFetch]);

	console.log(transaction);

	const { Text } = Typography;

	async function handleTransactionStatusChange() {
		try {
			setIsLoading(true);
			const res = await httpRequest.patch<ResponseProps>(
				'/transactions/' + updateStatus.record.transactionId + '/transaction-status',
				{
					transactionStatus: updateStatus.newStatus,
				}
			);
			if (res) {
				const newRes = await httpRequest.get<ResponseProps>('/transactions/' + transactionId);
				setSelectedTransactionStatus(newRes.data.payload.transactionStatus);
				setTransaction(newRes.data.payload);
				message.success('Successfully change transaction status.');
			}
			setIsLoading(false);
			setIsModalUpdateStatusVisible(false);
		} catch (error) {
			console.log('error handleTransactionStatusChange : ', error);
			message.error('Failed change transaction status. Please Contact Administrator.');
			setIsLoading(false);
			setIsModalUpdateStatusVisible(false);
		}
	}

	function handleInputWaybillChange(e: string) {
		setWaybill(e);
	}

	async function updateTransactionWaybill() {
		try {
			setIsLoading(true);
			const res = await httpRequest.post<ResponseProps>(
				'/transactions/' + transaction.transactionId + '/update-waybill-shipment/' + waybill,
				{}
			);
			if (res) {
				const newRes = await httpRequest.get<ResponseProps>('/transactions/' + transactionId);
				setSelectedTransactionStatus(newRes.data.payload.transactionStatus);
				setTransaction(newRes.data.payload);
				setWaybill(newRes.data.payload.transactionShipment?.waybill);
				message.success('Success update transaction waybill.');
			}
			setIsLoading(false);
			setIsModalWaybillVisible(false);
		} catch (error) {
			console.log('error updateTransactionWaybill : ', error);
			setIsLoading(false);
			setWaybill(transaction.transactionShipment?.waybill);
			message.error('Failed update transaction waybill. Please Contact Administrator.');
			setIsModalWaybillVisible(false);
		}
	}

	function handleWaybillClicked() {
		setIsModalWaybillVisible(true);
	}

	const updateStatusToShipped = async () => {
		try {
			await httpRequest.patch(`/transactions/${transactionId}/transaction-status`, {
				transactionStatus: ETransactionStatus.shipped,
			});
			setisShowModalUpdateStatusToShipped(false);
			setIsFetch(!isFetch);
		} catch (error) {
			console.log(error);
		}
	};

	const updateRequest = async () => {
		if (!dataRequest.type || !dataRequest.date || !dataRequest.time) {
			message.error('please fill in the input set delivery schedule');
			return;
		}

		const dataToSent = {
			transactionId: transaction.transactionId,
			delivery: {
				type: dataRequest.type,
				date: moment(dataRequest.date).format('DD-MM-YYYY'),
				time: dataRequest.time,
			},
		};

		setIsLoading(true);
		try {
			await httpRequest.post(`/jne/apiPickupOrCashless`, dataToSent);
			setIsModalRequetVisible(false);
			setIsFetch(!isFetch);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const colorStatus = colorTransactionStatus.find(
		(item) => item.status === selectedTransactionStatus
	);

	const isPoint = transaction?.details?.items.find((item) => item.type === 'point');

	const totalPayment: number =
		transaction?.details?.items?.find((item) => item.key === 'shipping-fee')?.value || 0;

	// let notDisabledStatusBtn =
	//   transaction.transactionStatus === ETransactionStatus.paid ||
	//   transaction.transactionStatus ===
	//     ETransactionStatus.scheduled_for_shipment ||
	//   transaction.transactionStatus === ETransactionStatus.shipped ||
	//   transaction.transactionStatus === ETransactionStatus.done;

	// const handleDisabledBtn = () => {
	//   if (
	//     notDisabledStatusBtn &&
	//     transaction.transactionShipment?.courierVendor === 'VINES'
	//   ) {
	//     return true;
	//   } else {
	//     return false;
	//   }

	//   if (
	//     notDisabledStatusBtn &&
	//     transaction.transactionShipment?.courierVendor !== 'VINES'
	//   ) {
	//     return false;
	//   } else {
	//     return true;
	//   }
	// };

	return (
		<>
			<HeaderSection
				icon='back'
				title={transaction.transactionCode?.toUpperCase()}
				subtitle={transaction.transactionCode?.toUpperCase()}
				key={transaction.transactionCode?.toUpperCase() + ' header section'}
				rightAction={[
					transaction.transactionStatus === ETransactionStatus.paid ||
					transaction.transactionStatus === ETransactionStatus.scheduled_for_shipment ||
					transaction.transactionStatus === ETransactionStatus.shipped ||
					transaction.transactionStatus === ETransactionStatus.done ? (
						transaction.transactionShipment?.courierVendor === 'VINES' ? (
							<ReactToPrint
								documentTitle={`INV/${transaction.transactionCode.toUpperCase()}`}
								pageStyle={`@media print {
                  @page {
                    size: 900px 600px;
                    margin: 0;
                  }
                }`}
								trigger={() => (
									<Button
										type='primary'
										style={{ padding: '0px 20px', marginRight: 12 }}>
										Print
									</Button>
								)}
								content={() => componentRef.current}
							/>
						) : (
							transaction.transactionShipment?.courierVendor !== 'VINES' && (
								<ReactToPrint
									documentTitle={`INV/${transaction.transactionCode.toUpperCase()}`}
									pageStyle={`@media print {
                    @page {
                      size: 900px 600px;
                      margin: 0;
                    }
                  }`}
									trigger={() => (
										<Button
											type='primary'
											style={{ padding: '0px 20px', marginRight: 12 }}
											disabled={transaction.transactionStatus === ETransactionStatus.paid}>
											Print
										</Button>
									)}
									content={() => componentRef.current}
								/>
							)
						)
					) : null,

					transaction.transactionStatus === ETransactionStatus.paid &&
					transaction.transactionShipment?.courierVendor === 'VINES' ? (
						<Button
							type='primary'
							onClick={() => {
								setisShowModalUpdateStatusToShipped(true);
							}}
							style={{ padding: '0px 20px', marginRight: 12 }}
							disabled={transaction?.transactionShipment?.waybill ? true : false}>
							Update Status to Shipped
						</Button>
					) : null,
					transaction.transactionStatus === ETransactionStatus.paid &&
					transaction.transactionShipment?.courierVendor !== 'VINES' ? (
						<Button
							type='primary'
							// key={'button waybill'}
							onClick={() => {
								// updateStatusToShipped();
								setIsModalRequetVisible(true);
							}}
							style={{ padding: '0px 20px' }}
							disabled={transaction?.transactionShipment?.waybill ? true : false}>
							Request for Delivery
						</Button>
					) : null,
				]}
			/>
			{isLoading ? (
				<Spin spinning />
			) : (
				<div style={{ padding: '20px 4px' }}>
					<div style={{ display: 'none' }}>
						<Invoince
							transaction={transaction}
							ref={componentRef}
						/>
					</div>
					<SectionContent
						groupTitle='Order Information'
						subTitle='This is order information that refers to the order transaction that has been placed'>
						<Row justify='space-between'>
							<Col span={6}>
								<Typography.Text className='text-3 text-gray block mb-1'>Status</Typography.Text>
								<div
									className='px-3 py-1 w-max rounded-full flex justify-center'
									style={{
										backgroundColor: colorStatus?.bgColor,
										color: colorStatus?.color,
										width: 'max-content',
									}}>
									<span className='font-semibold text-center'>
										{selectedTransactionStatus === ETransactionStatus.created
											? 'Waiting Payment'
											: selectedTransactionStatus === ETransactionStatus.scheduled_for_shipment
											? transaction.transactionShipment?.shipmentType
											: selectedTransactionStatus}
									</span>
								</div>
							</Col>
							<Col span={6}>
								<Typography.Text className='text-3 text-gray block mb-1'>Order ID</Typography.Text>
								<Typography.Text className='text-3.5 text-primary-black'>
									{transaction.transactionCode?.toUpperCase()}
								</Typography.Text>
							</Col>
							<Col span={6}>
								<Typography.Text className='text-3 text-gray block mb-1'>
									Order Date
								</Typography.Text>
								<Typography.Text className='text-3.5 text-primary-black'>
									{moment(transaction.createdAt).format('DD MMM YYYY, HH:mm')}
								</Typography.Text>
							</Col>
							<Col span={6}>
								<Typography.Text className='text-3 text-gray block mb-1'>
									Last Update Transaction
								</Typography.Text>
								<Typography.Text className='text-3.5 text-primary-black'>
									{moment(transaction.updatedAt).format('DD MMM YYYY, HH:mm')}
								</Typography.Text>
							</Col>
						</Row>
					</SectionContent>{' '}
					<Divider style={{ marginTop: '1%' }} />
					<SectionContent
						groupTitle='Products'
						subTitle='This is a product order that refers to a transaction that has been ordered by a customer'>
						<Row
							justify='space-between'
							gutter={[16, 16]}>
							{transaction?.transactionItems?.length! > 0 &&
								transaction.transactionItems?.map((item) => {
									const isPromo = item?.metaProduct?.promoId;
									const isPoint = item?.metaProduct?.redeemProductId;

									const hargaCoret =
										item?.metaProduct?.items?.length === 1 &&
										item.metaProduct?.items[0]?.product?.productId ===
											item.metaProduct?.items[0]?.productId;
									return (
										<Col span={24}>
											<Row>
												<Col span={16}>
													{' '}
													<div className='flex gap-x-2'>
														<Image
															width={64}
															height={64}
															src={
																(hargaCoret
																	? item?.metaProduct?.items[0]?.product?.images[0]?.imageUrl
																	: isPromo
																	? item?.metaProduct?.image
																	: isPoint
																	? item?.metaProduct?.product?.images[0]?.imageUrl
																	: item?.metaProduct?.images
																	? item?.metaProduct?.images[0]?.imageUrl
																	: DEFAULT_IMG) || DEFAULT_IMG
															}
															className='p-1 border-default'
														/>
														<div className='flex flex-col'>
															<Typography.Text className='text-primary-black text-3.5'>
																{hargaCoret
																	? item?.metaProduct?.items[0]?.product?.productName
																	: isPromo
																	? item?.metaProduct.description
																	: isPoint
																	? item?.metaProduct?.product?.productName
																	: item?.metaProduct?.productName}
															</Typography.Text>

															<Typography.Text className='text-primary-black text-3'>
																{hargaCoret ? (
																	<HargaCoret
																		isSmall
																		realPrice={item?.metaProduct?.items[0]?.totalAmount}
																		discountPrice={item?.pricePerUnit}
																		discount={item?.metaProduct?.items[0]?.discPercentage}
																	/>
																) : isPromo ? (
																	`IDR ${formatPrice(item.pricePerUnit)}`
																) : isPoint ? (
																	`${item?.metaProduct?.pointAmount} Point`
																) : (
																	`IDR ${formatPrice(item.pricePerUnit)}`
																)}
															</Typography.Text>
															{isPromo && !hargaCoret && (
																<>
																	<Typography.Text className='text-gray text-3'>
																		(
																		{item.metaProduct.items.map((value: any, idx: number) => (
																			<>
																				{value.product.productName}
																				{item.metaProduct.items.length - 1 !== idx && ','}
																			</>
																		))}
																		)
																	</Typography.Text>
																</>
															)}
															<Typography.Text className='text-primary-black text-3.5'></Typography.Text>
														</div>
													</div>
												</Col>
												<Col span={4}>
													<Typography.Text className='text-primary-black text-3'>
														x{item.qty} item
													</Typography.Text>
												</Col>
												<Col span={4}>
													<Typography.Text className='text-primary-black text-3.5 font-semibold'>
														{isPoint
															? `${item.qty * item.metaProduct.pointAmount} Point`
															: `IDR ${formatPrice(item.totalFinalPrice)}`}
													</Typography.Text>
												</Col>
											</Row>
										</Col>
									);
								})}
						</Row>
					</SectionContent>
					<Divider style={{ marginTop: '1%' }} />
					<SectionContent
						groupTitle='Shipping'
						subTitle='This is shipping information that refers to transactions that provide details about the delivery of products'>
						<Row>
							<Col span={7}>
								<Typography.Text className='text-3 text-gray block mb-1'>Outlet</Typography.Text>
								<Typography.Text className='text-3.5 text-primary-black'>
									{transaction?.metaLocation?.deliveryPoint?.name}
								</Typography.Text>
							</Col>
							<Col span={7}>
								<Typography.Text className='text-3 text-gray block mb-1'>Courier</Typography.Text>
								<Typography.Text className='text-3.5 text-primary-black flex items-center gap-x-2'>
									{transaction.transactionShipment?.courierVendor}{' '}
									{transaction.transactionShipment?.courirService?.service}{' '}
									{transaction.transactionShipment?.courierVendor !== ECourierVendor.VINES && (
										<>
											<div
												style={{
													width: 4,
													height: 4,
													borderRadius: '50%',
													background: '#C5CDDB',
												}}></div>{' '}
											({transaction.transactionShipment?.courirService?.cost.etd} day)
										</>
									)}
								</Typography.Text>
							</Col>
							{selectedTransactionStatus === ETransactionStatus.paid ||
							selectedTransactionStatus === ETransactionStatus.shipped ||
							selectedTransactionStatus === ETransactionStatus.done ||
							selectedTransactionStatus === ETransactionStatus.scheduled_for_shipment ? (
								<Col span={7}>
									<Typography.Text className='text-3 text-gray block mb-1'>
										No. Receipt
									</Typography.Text>
									<Typography.Text className='text-3.5 text-primary-black'>
										{transaction.transactionShipment?.waybill ?? 'Not Set'}
									</Typography.Text>
								</Col>
							) : null}
						</Row>
						<Row className='mt-4'>
							<Col>
								<Typography.Text className='text-3 text-gray block mb-1'>
									Shipping Address
								</Typography.Text>
								<Typography.Text className='text-3.5 text-primary-black block'>
									{transaction.transactionShipment?.shipToAdress.receiverName} (
									{transaction.transactionShipment?.shipToAdress.receiverPhone})
								</Typography.Text>
								<Typography.Text className='text-3 text-gray block'>
									{transaction.transactionShipment?.shipToAdress.address},{' '}
									{transaction.transactionShipment?.shipToAdress.district},{' '}
									{transaction.transactionShipment?.shipToAdress.regency},{' '}
									{transaction.transactionShipment?.shipToAdress.province},{' '}
									{transaction.transactionShipment?.shipToAdress.postalCode}
								</Typography.Text>
							</Col>
						</Row>
					</SectionContent>{' '}
					<Divider style={{ marginTop: '1%' }} />
					<SectionContent
						groupTitle='Payment Detail'
						subTitle='These are payment details that refer to the order transactions that have been made'>
						<Row>
							<Col span={7}>
								<Typography.Text className='text-3 text-gray block mb-1'>
									Payment Method
								</Typography.Text>
								<Typography.Text className='text-3.5 text-primary-black'>
									{transaction?.transactionDetail?.[0]?.paymentMethod ===
									EPaymentMethod.bank_transfer
										? `${transaction?.transactionDetail?.[0]?.paymentVendor
												?.replace('_', ' ')
												?.toUpperCase()} ${transaction?.transactionDetail?.[0]?.paymentMethod
												?.replace('_', ' ')
												.toUpperCase()}`
										: transaction?.transactionDetail?.[0]?.paymentMethod ===
										  EPaymentMethod.credit_card
										? `${transaction?.transactionDetail?.[0]?.paymentMethod
												?.replace('_', ' ')
												.toUpperCase()}`
										: transaction?.transactionDetail?.[0]?.paymentMethod ===
										  EPaymentMethod.instant_payment
										? `${transaction?.transactionDetail?.[0]?.paymentVendor
												?.replace('_', ' ')
												.toUpperCase()} ${transaction?.transactionDetail?.[0]?.paymentMethod
												?.replace('_', ' ')
												.toUpperCase()}`
										: '-'}
								</Typography.Text>
							</Col>
							<Col span={7}>
								<Typography.Text className='text-3 text-gray block mb-1'>
									Payment Date
								</Typography.Text>
								<Typography.Text className='text-3.5 text-primary-black'>
									{transaction?.transactionDetail?.[0]?.paidAt
										? moment(transaction?.transactionDetail?.[0]?.paidAt).format(
												'DD MMM YYYY, HH:mm'
										  )
										: '-'}
								</Typography.Text>
							</Col>
						</Row>
					</SectionContent>
					<Divider style={{ marginTop: '1%' }} />
					<SectionContent
						groupTitle='Summary Payment'
						subTitle='This is a summary payment that refers to the payment details of an order that has been placed.'>
						{isPoint ? (
							<>
								<Row
									style={{
										width: '100%',
										justifyContent: 'space-between',
										marginTop: 12,
									}}
									align='middle'>
									<Typography.Text
										style={{
											fontWeight: 400,
										}}>
										Shipping Fee
									</Typography.Text>
									<Typography.Text
										style={{
											fontWeight: 400,
										}}>
										{transaction?.transactionShipment?.courierVendor === 'VINES'
											? 'IDR 0 (COD)'
											: `IDR ${formatPrice(totalPayment)}`}
									</Typography.Text>
								</Row>
								<Divider style={{ margin: '14px 0px' }} />

								<Row
									style={{
										width: '100%',
										justifyContent: 'space-between',
										marginTop: 12,
									}}
									align='middle'>
									<Typography.Text
										style={{
											fontWeight: 600,
										}}>
										Total
									</Typography.Text>
									<Typography.Text
										style={{
											fontWeight: 600,
										}}>
										IDR {formatPrice(totalPayment)}
									</Typography.Text>
								</Row>
							</>
						) : (
							<Row gutter={[20, 20]}>
								<Col span={22}>
									{transaction.details?.items
										?.filter((item) => item.value !== 0)
										?.map((item: DetailsTransaction) =>
											item.type === 'separator' ? (
												<Divider style={{ margin: 10 }} />
											) : (
												<Row
													justify='space-between'
													className='mb-3'>
													<Col>
														<Text
															className='text-3.5 text-primary-black'
															style={{
																fontWeight: item.key === 'total-pay' ? 600 : 400,
															}}>
															{item.label}
															{item.key === 'shipping-fee'
																? `(${transaction.transactionShipment?.weight} Kg)`
																: null}
														</Text>
													</Col>
													<Col>
														<Text className='text-3.5 text-primary-black font-semibold'>
															{`IDR ${item?.value?.toLocaleString('id-ID')}`}{' '}
														</Text>
													</Col>
												</Row>
											)
										)}
								</Col>
							</Row>
						)}
					</SectionContent>
				</div>
			)}

			<Modal
				title='Update Transaction Status'
				open={isModalUpdateStatusVisible}
				onOk={() => {
					handleTransactionStatusChange();
				}}
				confirmLoading={isLoading}
				onCancel={() => {
					setIsModalUpdateStatusVisible(false);
				}}
				closable={false}
				centered>
				<p>
					{' '}
					Are you sure want to change{' '}
					<span style={{ fontWeight: 'bold' }}>
						{transaction.transactionCode?.toUpperCase()}
					</span>{' '}
					status from <span style={{ fontWeight: 'bold' }}>{transaction.transactionStatus}</span> to{' '}
					<span style={{ fontWeight: 'bold' }}>
						{updateStatus.newStatus === ETransactionStatus.created
							? 'Waiting Payment'
							: updateStatus.newStatus}
					</span>{' '}
					?
				</p>
			</Modal>
			<Modal
				title={<Text className='flex justify-start mb-4'>Input Receipt Number</Text>}
				open={isModalWaybillVisible}
				onOk={() => {
					updateTransactionWaybill();
				}}
				confirmLoading={isLoading}
				onCancel={() => {
					setIsModalWaybillVisible(false);
					setWaybill(transaction.transactionShipment?.waybill);
				}}
				okText='Save'>
				<Typography.Text className='text-3 text-gray block mb-1'>Receipt Number</Typography.Text>
				<Input
					placeholder='Enter receipt number'
					value={waybill}
					onChange={(e) => {
						handleInputWaybillChange(e.target.value);
					}}
					className='mb-4'
				/>
			</Modal>
			<Modal
				title={<Text className='flex justify-start mb-4'>Set Delivery Schedule</Text>}
				open={isModalRequetVisible}
				onOk={() => {
					updateRequest();
				}}
				confirmLoading={isLoading}
				onCancel={() => {
					setIsModalRequetVisible(false);
					setDataRequest({
						type: '',
						date: null,
						time: null,
					});
				}}
				okText='Save'>
				<Form
					name='form'
					layout='vertical'
					autoComplete='off'>
					<Form.Item name='type'>
						<Typography.Text
							style={{
								color: '#768499',
								marginBottom: 4,
								fontSize: 12,
								display: 'block',
							}}>
							Type
						</Typography.Text>
						<Radio.Group
							onChange={(e) =>
								setDataRequest({
									...dataRequest,
									type: e.target.value,
								})
							}
							value={dataRequest.type}>
							<CustomRadio value={'PICKUP'}>Pickup</CustomRadio>
							<CustomRadio value={'DROP'}>Drop</CustomRadio>
						</Radio.Group>
					</Form.Item>
					<Form.Item name='date'>
						<Typography.Text
							style={{
								color: '#768499',
								marginBottom: 4,
								fontSize: 12,
								display: 'block',
							}}>
							Date
						</Typography.Text>
						<DatePicker
							style={{ width: '100%' }}
							format='M/D/YYYY'
							value={dataRequest.date ? moment(dataRequest.date) : undefined}
							onChange={(time: any, timeString: string) =>
								setDataRequest({
									...dataRequest,
									date: timeString,
								})
							}
						/>
					</Form.Item>
					<Form.Item name='time'>
						<Typography.Text
							style={{
								color: '#768499',
								marginBottom: 4,
								fontSize: 12,
								display: 'block',
							}}>
							Time
						</Typography.Text>
						<TimePicker
							value={moment(dataRequest.time || '00:00', 'HH:mm')}
							format={'HH:mm'}
							showNow={false}
							style={{ width: '100%' }}
							onChange={(time: any, timeString: string) =>
								setDataRequest({
									...dataRequest,
									time: timeString,
								})
							}
						/>
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				title='Update Transaction Status'
				open={isShowModalUpdateStatusToShipped}
				onOk={() => {
					updateStatusToShipped();
				}}
				// confirmLoading={isLoading}
				onCancel={() => {
					setisShowModalUpdateStatusToShipped(false);
				}}
				closable={false}
				centered>
				<p className='mt-2'>
					{' '}
					Are you sure want to change{' '}
					<span style={{ fontWeight: 'bold' }}>
						{transaction.transactionCode?.toUpperCase()}
					</span>{' '}
					status from <span style={{ fontWeight: 'bold' }}>{transaction.transactionStatus}</span> to{' '}
					<span style={{ fontWeight: 'bold' }}>shipped</span> ?
				</p>
			</Modal>
		</>
	);
};

export default TransactionDetail;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: #1e1e1e;
		border-width: 2px;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: #1e1e1e;
	}
`;
