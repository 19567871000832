import { Row, Col, Typography, Card, Image, Space, Button, Spin } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { DEFAULT_IMG } from '../../const/config';
import { PaymentMethodProps } from '../../types/paymentMethods.type';
import DetailItem from '../../components/DetailItem';
import HTMLPreview from '../../components/HTMLPreview';

interface ILocation {
	code: string;
}

const { Title, Text } = Typography;

const PaymentMethodDetail: React.FC = () => {
	const navigate = useNavigate();

	const { code } = useParams<keyof ILocation>() as ILocation;
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethodProps>();

	React.useEffect(() => {
		const fetchPaymentMethodDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<any>('/payment-methods/' + code);

				if (res && res.data && res.data.payload) {
					setPaymentMethod(res.data.payload);
				}

				const bcDetails = [
					{
						field: 'code',
						value: code,
						label: 'Detail Payment Method',
					},
				];

				setBreadcrumbDetails(bcDetails);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchPaymentMethodDetail();
	}, [code]);

	const handleEdit = () => {
		navigate('/payment-methods/' + code + '/edit');
	};

	return (
		<React.Fragment>
			<HeaderSection
				icon='back'
				title='Detail Payment Method'
				rightAction={
					<Space>
						<Button
							type='primary'
							onClick={handleEdit}>
							Edit
						</Button>
					</Space>
				}
			/>

			{isLoading ? (
				<Spin spinning />
			) : (
				<Card bordered={false}>
					<Row>
						<Col span={5}>
							<Title level={5}>Data Payment Method</Title>
							<Text style={{ color: '#768499' }}>
								These are payment method details, you can’t change here
							</Text>
						</Col>
						<Col
							offset={1}
							span={16}>
							<DetailItem
								label='Logo'
								children={
									<Image
										width={180}
										height={120}
										src={paymentMethod?.logo ? paymentMethod?.logo : DEFAULT_IMG}
										fallback={DEFAULT_IMG}
										style={{ objectFit: 'contain' }}
									/>
								}
							/>

							<Row style={{ marginTop: 20 }}>
								<Col span={12}>
									<DetailItem
										label='Code'
										value={paymentMethod?.code}
									/>
								</Col>
								<Col span={12}>
									<DetailItem
										label='BC Code'
										value={paymentMethod?.bcCode}
									/>
								</Col>
							</Row>

							<Row>
								<Col span={12}>
									<DetailItem
										label='Description'
										value={paymentMethod?.description}
									/>
								</Col>
								<Col span={12}>
									<DetailItem
										label='Expired Time'
										value={
											paymentMethod?.expiryTime
												? paymentMethod?.expiryTime.toString() + ' seconds'
												: ''
										}
									/>
								</Col>
							</Row>

							<Row>
								<Col>
									<DetailItem
										label='Status'
										value={paymentMethod?.isPublished ? 'Active' : 'Non Active'}
									/>
								</Col>
							</Row>

							<Row>
								<Col>
									<DetailItem
										label='Instruction Type'
										children={<HTMLPreview html={paymentMethod?.instructionType || ''} />}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Card>
			)}
		</React.Fragment>
	);
};

export default PaymentMethodDetail;
