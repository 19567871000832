import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppstoreAddOutlined,
  MoreOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Pagination,
  Space,
  Table,
  Switch,
  Dropdown,
  Menu,
  Modal,
  message,
  Input,
  Select,
  Image,
  Tag,
  Typography,
  Button,
} from 'antd';
import styled from 'styled-components';
import {
  initialProductStock,
  ProductStockProps,
} from '../../../types/productStocks.type';
import { BaseResponseProps } from '../../../types/config.type';
import useFetchList from '../../../hooks/useFetchList';
import { formatDate, PAGE_SIZE_OPTIONS } from '../../../helpers/constant';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { ProductProps } from '../../../types/products.type';
import { LocationProps } from '../../../types/location.type';

interface ResponseProps extends BaseResponseProps<ProductStockProps> {
  payload: Omit<ProductStockProps, 'createdAt' | 'updatedAt'>;
}

const { Text } = Typography;

const ProductStocks = () => {
  const navigate = useNavigate();
  const [products, setProducts] = React.useState<ProductProps[]>([]);
  const [locations, setLocations] = React.useState<LocationProps[]>([]);

  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<ProductStockProps>({
    endpoint: 'product-stocks',
    limit: 10,
  });

  React.useEffect(() => {
    async function getProductData() {
      const productData = await httpRequest.get<any>(`/products`);

      if (productData && productData?.data) {
        setProducts(productData.data.payload.results);
      } else {
        message.error('Something went wrong');
      }
    }
    getProductData();
    async function getLocationData() {
      const locationData = await httpRequest.get<any>(`/locations`);
      if (locationData && locationData?.data) {
        setLocations(locationData.data.payload.results);
      } else {
        message.error('Something went wrong');
      }
    }
    getLocationData();
  }, [navigate, isLoading]);

  const handleCreateProduct = () => {
    navigate('/product-stocks/add');
  };

  const { Option } = Select;

  const handleClickDetail = (e: ProductStockProps) => {
    navigate(`/product-stocks/${e.productStockId}`);
  };

  const handleClickAction = (props: ProductStockProps, key: string) => {
    if (key === 'detail') {
      navigate(`/product-stocks/${props.productStockId}`);
    } else if (key === 'edit') {
      navigate(`/product-stocks/${props.productStockId}/edit`);
    }
  };

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'productId',
      key: 'productId',
      render: (text: string, record: ProductStockProps) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            <Text>
              {record.productId
                ? products.find((item) => record.productId === item.productId)
                    ?.productName
                : record.productId}
            </Text>
          </div>
        );
      },
    },
    {
      title: 'Current Stock',
      dataIndex: 'currentStock',
      key: 'currentStock',
      render: (text: string, record: ProductStockProps) => {
        return <Text>{record.currentStock}</Text>;
      },
    },
    {
      title: 'Unit',
      dataIndex: 'baseUnitOfMeasure',
      key: 'baseUnitOfMeasure',
      render: (text: string, record: ProductStockProps) => {
        return <Text>{record.baseUnitOfMeasure}</Text>;
      },
    },
    {
      title: 'Location Name',
      key: 'locationId',
      dataIndex: 'locationId',
      render: (text: string, record: ProductStockProps) => {
        return (
          // <Text>
          //   {
          //     locations.filter(
          //       (item) => item.locationId === record.locationId
          //     )[0].locationName
          //   }
          // </Text>
          <Text>
            {record.locationId
              ? locations.find((item) => item.locationId === record.locationId)
                  ?.locationName
              : record.locationId}
          </Text>
        );
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: ProductStockProps) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: ProductStockProps) => (
    <Menu onClick={(e) => handleClickAction(record, e.key)}>
      <Menu.Item key="edit">Edit Product</Menu.Item>
      <Menu.Item key="detail">Detail Product</Menu.Item>
    </Menu>
  );

  React.useEffect(() => {
    async function getProductData() {
      const productData = await httpRequest.get<any>(`/products`);
      setProducts(productData.data.payload.results);
    }
    getProductData();
    async function getLocationData() {
      const locationData = await httpRequest.get<any>(`/locations`);
      setLocations(locationData.data.payload.results);
    }
    getLocationData();
  }, [navigate]);

  return (
    <React.Fragment>
      <HeaderSection
        icon={<AppstoreAddOutlined />}
        title="Product Stocks"
        subtitle="Manage your Product Stocks"
        rightAction={
          <Space>
            <Button type="primary" onClick={handleCreateProduct}>
              Add Stock
            </Button>
          </Space>
        }
      />

      <ContainerFilter>
        <Input
          // size="large"
          placeholder="Search by Unit"
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
        />
        {/* <Select
          // size="large"
          allowClear
          style={{ width: 160 }}
          onChange={handleChangeStatus}
          placeholder="Status"
        >
          <Option value="all">All</Option>
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select> */}
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />

      {/* <Modal
        title="Update status confirmation"
        visible={isModalVisible}
        onOk={handleStatusChange}
        onCancel={() => {
          setIsModalVisible(false);
          setTmpData(initialProductStock);
        }}
        okText="Yes"
        confirmLoading={isLoadingUpdateStatus}
        okButtonProps={{ type: "primary" }}
      >
        <p>
          Are you sure want to change <b>"{tmpData.productName}"</b> status to{" "}
          <b>"{!tmpData.isPublished ? "Active" : "Inactive"}"</b>?.{" "}
          {!tmpData.isPublished}
        </p>
      </Modal> */}
    </React.Fragment>
  );
};

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

export default ProductStocks;
