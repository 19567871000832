import {
  Input,
  Row,
  Space,
  Col,
  Divider,
  Image,
  Button,
  Card,
  message,
} from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DetailItem from '../../../components/DetailItem';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import { formatDate } from '../../../helpers/constant';
import {
  initialProductPrice,
  ProductPriceProps,
} from '../../../types/productPrices.type';
import { initialProduct, ProductProps } from '../../../types/products.type';
import {
  initialProductVariant,
  ProductVariantProps,
} from '../../../types/productVariant.type';

interface ResponseProps extends BaseResponseProps<ProductPriceProps> {
  payload: Omit<ProductPriceProps, 'createdAt' | 'updatedAt'>;
}

interface ILocation {
  productPriceId: string;
}

const ProductPriceDetail: React.FC = () => {
  const navigate = useNavigate();
  const { productPriceId } = useParams<keyof ILocation>() as ILocation;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [productPrice, setProductPrice] =
    React.useState<ProductPriceProps>(initialProductPrice);
  const [product, setProduct] = React.useState<ProductProps>(initialProduct);
  const [variant, setVariant] = React.useState<ProductVariantProps>(
    initialProductVariant
  );

  React.useEffect(() => {
    const fetchProductPriceDetail = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<any>(
          '/product-prices/' + productPriceId
        );

        if (res && res?.data) {
          setProductPrice(res.data.payload);
        } else {
          message.error('Something went wrong');
        }

        const productRes = await httpRequest.get<any>(
          '/products/' + res.data.payload.productId
        );
        if (productRes && productRes?.data) {
          setProduct(productRes.data.payload);
        } else {
          message.error('Something went wrong');
        }

        const variantRes = await httpRequest.get<any>(
          `/products/${res.data.payload.productId}/variants/${res.data.payload.productVariantId}`
        );
        if (variantRes && variantRes?.data) {
          setVariant(variantRes.data.payload);
        } else {
          message.error('Something went wrong');
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProductPriceDetail();
  }, [productPriceId]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title="Product Price Detail"
        subtitle="Manage your product price data"
        rightAction={
          <Space>
            <Button
              type="primary"
              onClick={() =>
                navigate('/product-prices/' + productPriceId + '/edit')
              }
            >
              Edit
            </Button>
          </Space>
        }
      />

      <Card bordered={false}>
        <DetailItem label="Product Name" value={product.productName} />
        <DetailItem
          label="Unit Price"
          value={productPrice.unitPrice.toString()}
        />
        <DetailItem
          label="Base Unit Of Measure"
          value={productPrice.unitOfMeasureCode}
        />
        <DetailItem label="Min. Qty" value={productPrice.minQty.toString()} />
        <DetailItem
          label="Product Variant"
          value={variant.productVariantCode}
        />
        <DetailItem label="Start At" value={formatDate(productPrice.startAt)} />
        <DetailItem label="End At" value={formatDate(productPrice.endAt)} />
      </Card>
    </React.Fragment>
  );
};

export default ProductPriceDetail;
