import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppstoreAddOutlined,
  MoreOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Pagination,
  Space,
  Table,
  Dropdown,
  Menu,
  Input,
  Select,
  Typography,
  Button,
  message,
} from 'antd';
import styled from 'styled-components';
import { BaseResponseProps } from '../../../types/config.type';
import useFetchList from '../../../hooks/useFetchList';
import { formatDate, PAGE_SIZE_OPTIONS } from '../../../helpers/constant';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { ProductProps } from '../../../types/products.type';
import { ProductPriceProps } from '../../../types/productPrices.type';

interface ResponseProps extends BaseResponseProps<ProductPriceProps> {
  payload: Omit<ProductPriceProps, 'createdAt' | 'updatedAt'>;
}

const { Text } = Typography;

const ProductPrices = () => {
  const navigate = useNavigate();
  const [products, setProducts] = React.useState<ProductProps[]>([]);
  const [variants, setVariants] = React.useState<any[]>([]);

  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<ProductPriceProps>({
    endpoint: 'product-prices',
    limit: 10,
  });

  React.useEffect(() => {
    async function getProductData() {
      const productData = await httpRequest.get<any>(`/products`);
      if (productData && productData?.data) {
        setProducts(productData.data.payload.results);
      } else {
        message.error('Something went wrong');
      }
    }
    getProductData();
    // async function getVariantData() {
    //   const locationData = await httpRequest.get<any>(`/locations`);
    //   setLocations(locationData.data.payload.results);
    // }
    // getVariantData();
  }, [navigate, isLoading]);

  const handleCreatePrice = () => {
    navigate('/product-prices/add');
  };

  const { Option } = Select;

  const handleClickDetail = (e: ProductPriceProps) => {
    navigate(`/product-prices/${e.productPriceId}`);
  };

  const handleClickAction = (props: ProductPriceProps, key: string) => {
    if (key === 'detail') {
      navigate(`/product-prices/${props.productPriceId}`);
    } else if (key === 'edit') {
      navigate(`/product-prices/${props.productPriceId}/edit`);
    }
  };

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'productId',
      key: 'productId',
      render: (text: string, record: ProductPriceProps) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            <Text>
              {record.productId
                ? products.find((item) => item.productId === record.productId)
                    ?.productName
                : record.productId}
            </Text>
          </div>
        );
      },
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (text: number, record: ProductPriceProps) => {
        return <Text>{record.unitPrice}</Text>;
      },
    },
    {
      title: 'Unit',
      dataIndex: 'unitOfMeasureCode',
      key: 'unitOfMeasureCode',
      render: (text: string, record: ProductPriceProps) => {
        return <Text>{record.unitOfMeasureCode}</Text>;
      },
    },
    {
      title: 'Min Qty',
      key: 'minQty',
      dataIndex: 'minQty',
      render: (text: number, record: ProductPriceProps) => {
        return <Text>{record.minQty}</Text>;
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: ProductPriceProps) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: ProductPriceProps) => (
    <Menu onClick={(e) => handleClickAction(record, e.key)}>
      <Menu.Item key="edit">Edit Price</Menu.Item>
      <Menu.Item key="detail">Detail Price</Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <HeaderSection
        icon={<AppstoreAddOutlined />}
        title="Product Prices"
        subtitle="Manage your Product Prices"
        rightAction={
          <Space>
            <Button type="primary" onClick={handleCreatePrice}>
              Add Price
            </Button>
          </Space>
        }
      />

      <ContainerFilter>
        <Input
          // size="large"
          placeholder="Search by Unit"
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
      </ContainerFilter>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      />
    </React.Fragment>
  );
};

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

export default ProductPrices;
