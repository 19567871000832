import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MoreOutlined, PictureOutlined, SearchOutlined } from '@ant-design/icons';
import { Table, Switch, Menu, Image, Modal, Dropdown, Input, Select } from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { ColumnsType } from 'antd/lib/table';
import useFetchList from '../../hooks/useFetchList';
import styled from 'styled-components';
import { DEFAULT_IMG } from '../../const/config';
import { PaymentMethodProps } from '../../types/paymentMethods.type';
import moment from 'moment';

const { Option } = Select;

const PaymentMethod = () => {
	const navigate = useNavigate();

	const [isLoadingAction, setIsLoadingAction] = useState(false);
	const [tmpData, setTmpData] = useState<PaymentMethodProps>();

	const { isLoading, data, setData, setQuery, setSearch } = useFetchList<PaymentMethodProps>({
		apiRequest: httpRequest,
		endpoint: 'payment-methods',
	});

	const handleClickDetail = (e: PaymentMethodProps) => {
		navigate(`/payment-methods/${e.code}`);
	};

	const handleClickEdit = (e: PaymentMethodProps) => {
		navigate(`/payment-methods/${e.code}/edit`);
	};

	const handleClickUpdateStatus = async () => {
		if (tmpData) {
			setIsLoadingAction(true);
			try {
				const res = await httpRequest.patch('/payment-methods/' + tmpData.code, {
					isPublished: !tmpData.isPublished,
				});
				if (res.data && res.data.payload) {
					const newData = data.map((item) =>
						item.code === res.data.payload.code ? res.data.payload : item
					);
					setData(newData);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setTmpData(undefined);
				setIsLoadingAction(false);
			}
		}
	};

	const handleChangeStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		}
	};

	const columns: ColumnsType<PaymentMethodProps> = [
		{
			title: 'IMAGE',
			dataIndex: 'logo',
			key: 'logo',
			width: 60,
			render: (logo: string) => {
				return (
					<Image
						width={60}
						height={40}
						preview={false}
						style={{objectFit: 'contain'}}
						src={logo || DEFAULT_IMG}
						fallback={DEFAULT_IMG}
					/>
				);
			},
		},
		{
			title: 'CODE',
			key: 'code',
			dataIndex: 'code',
			render: (code: string, record: PaymentMethodProps) => {
				return (
					<div
						className='table-link'
						onClick={() => handleClickDetail(record)}>
						{code}
					</div>
				);
			},
		},
		{
			title: 'BC CODE',
			key: 'bcCode',
			dataIndex: 'bcCode',
			render: (bcCode: string) => {
				return <div>{bcCode}</div>;
			},
		},
		{
			title: 'DESCRIPTION',
			key: 'description',
			dataIndex: 'description',
			render: (description: string) => {
				return <div>{description}</div>;
			},
		},
		{
			title: 'EXPIRED TIME',
			key: 'expiryTime',
			dataIndex: 'expiryTime',
			render: (expiryTime: string) => {
				return <div>{expiryTime}</div>;
			},
		},
		{
			title: 'STATUS',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isActive: any, record: PaymentMethodProps) => (
				<>
					<Switch
						loading={isLoadingAction}
						checked={isActive}
						onChange={() => {
							setTmpData(record);
						}}
					/>
				</>
			),
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt) => <div>{moment(createdAt).format('DD MMM YYYY, HH:mm')}</div>,
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: PaymentMethodProps) => (
				<Dropdown
					overlay={() => menu(record)}
					placement='bottomRight'>
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: PaymentMethodProps) => (
		<Menu
			onClick={({ key }) => {
				if (key === 'edit') {
					handleClickEdit(record);
				} else if (key === 'detail') {
					handleClickDetail(record);
				}
			}}>
			<Menu.Item key='edit'>Edit</Menu.Item>
			<Menu.Item key='detail'>Detail</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<PictureOutlined />}
				title='Payment Method'
			/>

			<ContainerFilter>
				<Input
					placeholder='Search by Code or Payment Type...'
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					allowClear
					style={{ width: 160 }}
					onChange={handleChangeStatus}
					placeholder='Status'>
					<Option value='all'>All</Option>
					<Option value='active'>Published</Option>
					<Option value='inactive'>Unpublished</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			<Modal
				title='Confirmation'
				open={!!tmpData}
				onOk={handleClickUpdateStatus}
				onCancel={() => {
					setTmpData(undefined);
				}}
				okText='Yes'
				confirmLoading={isLoadingAction}
				okButtonProps={{ type: 'primary' }}>
				<p>
					Are you sure want to change promo deals status to{' '}
					<b>{tmpData?.isPublished ? 'Unpublished' : 'Published'}</b>?
				</p>
			</Modal>
		</div>
	);
};

export default PaymentMethod;

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;
