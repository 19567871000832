import {
  Form,
  Input,
  message,
  Select,
  Space,
  Button,
  Radio,
  Row,
  Col,
  Typography,
  Divider,
} from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import { generateFormRules } from '../../helpers/formRules';
import 'react-quill/dist/quill.snow.css';
import {
  DeliveryPointProps,
  initialDeliveryPoint,
} from '../../types/deliveryPoint.type';
import {
  initialLocation,
  initialShipmentSetting,
  LocationProps,
  shipmentSettingProps,
} from '../../types/location.type';
import AppLayout from '../layout/AppLayout';
import { getErrorMessage } from '../../helpers/errorHandler';
import SectionContent from '../../components/SectionContent';
import { CustomRadio } from '../promotion/edit';
import styled from 'styled-components';
import { IconArrowDown } from '../../assets/icons';
import MapPickLocation from '../../components/AppMapsLocation';

interface ILocation {
  deliveryOutletId: string;
}

interface ResponseProps extends BaseResponseProps<DeliveryPointProps> {
  payload: Omit<DeliveryPointProps, 'createdAt' | 'updatedAt'>;
}

interface LocationResponseProps extends BaseResponseProps<any> {
  payload: {
    results: LocationProps[];
  };
}

interface IJNEOrigin {
  originCode: string;
  originName: string;
}

interface IJNEBranch {
  branchCode: string;
  branchName: string;
}
const DeliveryPointEdit: React.FC = () => {
  const navigate = useNavigate();
  const { deliveryOutletId } = useParams<keyof ILocation>() as ILocation;
  const [form] = Form.useForm();
  const [searchOrigin, setSearchOrigin] = React.useState<string>('');
  const [searchBranch, setSearchBranch] = React.useState<string>('');

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [deliveryPoint, setDeliveryPoint] =
    React.useState<DeliveryPointProps>(initialDeliveryPoint);
  const [locations, setLocations] = React.useState<LocationProps[]>([
    initialLocation,
  ]);
  const [geolocation, setGeolocation] = React.useState({
    lat: 0,
    lng: 0,
  });

  const [tmpEditShipmentSetting, setTmpEditShipmentSetting] =
    React.useState<shipmentSettingProps>(initialShipmentSetting);

  const createDeliveryPoint = async (
    props: Omit<
      DeliveryPointProps,
      'createdAt' | 'updatedAt' | 'deliveryPointId' | 'statusLoading'
    >
  ) => {
    if (!deliveryPoint.name) {
      return message.error('Delivery Outlet Name is required');
    } else if (!deliveryPoint.locationId) {
      return message.error('Store is required');
    } else if (!tmpEditShipmentSetting.jne.originCode) {
      return message.error('Origin Code is required');
    } else if (!tmpEditShipmentSetting.jne.originCode) {
      return message.error('Branch Code is required');
    }

    try {
      setIsLoadingAction(true);

      const res = await httpRequest.post('/delivery_points', {
        ...props,
        // locationId: deliveryPoint.locationId,
        // isPublished: deliveryPoint.isPublished === true ? true : false,
      });

      if (res.data && res.data.payload) {
        await httpRequest.patch<ResponseProps>(
          '/locations/' + deliveryPoint.locationId,
          {
            shipmentSetting: tmpEditShipmentSetting,
            geolocation,
          }
        );
        message.success('Success create ' + deliveryPoint.name);
        navigate(-1);
      }
    } catch (error) {
      setIsLoadingAction(false);
      message.error(getErrorMessage(error));
      // message.error('Failed to create delivery point ' + deliveryPoint.name);
    }
  };

  const updateDeliveryPoint = async (
    props: Omit<DeliveryPointProps, 'createdAt' | 'updatedAt' | 'statusLoading'>
  ) => {
    if (!deliveryPoint.name) {
      return message.error('Delivery Outlet Name is required');
    } else if (!deliveryPoint.locationId) {
      return message.error('Store is required');
    } else if (!tmpEditShipmentSetting.jne.originCode) {
      return message.error('Origin Code is required');
    } else if (!tmpEditShipmentSetting.jne.originCode) {
      return message.error('Branch Code is required');
    }

    try {
      setIsLoadingAction(true);

      const dataToBeSent = {
        ...props,
      };
      if (deliveryOutletId) {
        const res = await httpRequest.patch(
          '/delivery_points/' + deliveryOutletId,
          dataToBeSent
        );

        if (res.data && res.data.payload) {
          await httpRequest.patch<ResponseProps>(
            '/locations/' + deliveryPoint.locationId,
            {
              shipmentSetting: tmpEditShipmentSetting,
              geolocation,
            }
          );
          message.success('Success update ' + props.name + ' data');
          navigate(`/delivery-outlets/${deliveryOutletId}`);
        }
      }
      // message.success('Successfully update ' + props.name + ' data');
      // navigate('/delivery-outlets');
    } catch (error) {
      setIsLoadingAction(false);
      message.error(getErrorMessage(error));
      // message.success('Failed to update ' + props.name + ' data');
    }
  };

  const handleSubmit = async (
    values: Omit<
      DeliveryPointProps,
      'createdAt' | 'updatedAt' | 'statusLoading'
    >
  ) => {
    if (deliveryOutletId) {
      updateDeliveryPoint(values);
    } else {
      createDeliveryPoint(values);
    }
  };

  React.useEffect(() => {
    const fetchDeliveryPointDetail = async () => {
      try {
        setIsLoading(true);

        if (deliveryOutletId) {
          const res = await httpRequest.get<ResponseProps>(
            '/delivery_points/' + deliveryOutletId
          );

          if (res && res?.data) {
            setDeliveryPoint(res.data.payload);
            setTmpEditShipmentSetting(
              res.data.payload.location?.shipmentSetting!
            );
            setGeolocation(
              res?.data?.payload?.location?.geolocation!
                ? res?.data?.payload?.location?.geolocation!
                : {
                    lat: -6.2,
                    lng: 106.816666,
                  }
            );
            form.setFieldsValue(res.data.payload);
          } else {
            message.error('Something went wrong');
          }

          const bcDetails = [
            {
              field: 'deliveryOutletId',
              value: deliveryOutletId,
              label: res.data.payload.name,
            },
          ];
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchDeliveryPointDetail();
  }, [deliveryOutletId]);

  const [jneListOriginCode, setJneListOriginCode] = React.useState<
    IJNEOrigin[]
  >([]);
  const [jneListBranchCode, setJneListBranchCode] = React.useState<
    IJNEBranch[]
  >([]);

  React.useEffect(() => {
    const fetchlistJneOriginAndBranch = async () => {
      const resListOrigin = await httpRequest.get<
        BaseResponseProps<IJNEOrigin[]>
      >('/jne/list-origin');
      const resListBranch = await httpRequest.get<
        BaseResponseProps<IJNEBranch[]>
      >('/jne/list-branch');
      if (resListOrigin && resListOrigin?.data && resListOrigin.data.payload) {
        setJneListOriginCode(resListOrigin.data.payload);
      } else {
        message.error('Something went wrong');
      }
      if (resListBranch && resListBranch?.data && resListBranch.data.payload) {
        setJneListBranchCode(resListBranch.data.payload);
      } else {
        message.error('Something went wrong');
      }
    };

    fetchlistJneOriginAndBranch();
  }, []);

  React.useEffect(() => {
    async function fetchLocations() {
      const getLocations = await httpRequest.get<LocationResponseProps>(
        '/locations/'
      );
      if (getLocations && getLocations?.data) {
        setLocations(getLocations.data.payload.results);
      } else {
        message.error('Something went wrong');
      }
    }
    fetchLocations();
  }, []);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={(deliveryOutletId ? 'Edit' : 'Add') + ' Delivery Point'}
        subtitle="Manage your delivery Point data"
        rightAction={
          <Space>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button
              loading={isLoadingAction}
              type="primary"
              onClick={() => handleSubmit(deliveryPoint)}
            >
              Save
            </Button>
          </Space>
        }
      />

      <div style={{ padding: 24 }}>
        <Form name="form" autoComplete="off" layout="vertical" form={form}>
          <SectionContent
            groupTitle="Delivery Outlet"
            subTitle="These are delivery details, you can change anything"
          >
            <Row justify="space-between">
              <Col span={21}>
                <Form.Item name="outletName" className="mb-3">
                  <Typography.Text className="text-3 text-gray block mb-1">
                    Delivery Outlet Name
                  </Typography.Text>
                  <Input
                    placeholder="Enter delivery outlet name"
                    value={deliveryPoint?.name}
                    onChange={(e) =>
                      setDeliveryPoint({
                        ...deliveryPoint,
                        name: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={21}>
                <Form.Item name="locationId" className="mb-3">
                  <Typography.Text className="text-3 text-gray block mb-1">
                    Store
                  </Typography.Text>
                  <Select
                    placeholder="Search store by name or code"
                    className="w-full"
                    showSearch
                    allowClear
                    filterOption={(input, option) => {
                      return (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    value={
                      deliveryPoint?.locationId === ''
                        ? null
                        : deliveryPoint?.locationId
                    }
                    onChange={(e) => {
                      setDeliveryPoint({
                        ...deliveryPoint,
                        locationId: e,
                      });
                    }}
                  >
                    {locations.map((location) => (
                      <Select.Option
                        value={location?.locationId}
                        key={location?.locationId}
                      >
                        {`${location?.locationName} - ${location?.locationCode}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={21}>
                <Form.Item name="isPublished" className="mb-3">
                  <Typography.Text className="text-3 text-gray block mb-1">
                    Status
                  </Typography.Text>

                  <Radio.Group
                    onChange={(e) => {
                      setDeliveryPoint({
                        ...deliveryPoint,
                        isPublished: e.target.value,
                      });
                    }}
                    value={
                      !deliveryOutletId && deliveryPoint?.isPublished === false
                        ? null
                        : deliveryPoint?.isPublished
                    }
                  >
                    <CustomRadio value={true}>Active</CustomRadio>
                    <CustomRadio value={false}>Inactive</CustomRadio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </SectionContent>
          <Divider style={{ marginTop: '1%' }} />
          {process.env.REACT_APP_MAP_API_KEY && (
            <>
              <SectionContent
                groupTitle="Set the location pinpoint"
                subTitle="These are location details, you can change anything"
              >
                <div
                  style={{
                    border: `1px solid #D5DCE1`,
                    padding: 12,
                    borderRadius: 8,
                  }}
                >
                  <MapPickLocation
                    onChange={(location) => {
                      console.log(location);

                      setGeolocation({
                        lat: +location?.coords?.lat,
                        lng: +location?.coords?.lng,
                      });
                    }}
                    geolocation={deliveryOutletId ? geolocation : undefined}
                  />
                </div>
              </SectionContent>
              <Col span={24}>
                <Row className="w-full" gutter={[12, 12]}>
                  <Col span={12}>
                    <Typography.Text className="text-3 text-gray block mb-1">
                      ZIP Code
                    </Typography.Text>
                    <Typography.Text className="text-3.5 text-primary-black">
                      -
                    </Typography.Text>
                  </Col>
                  <Col span={12}>
                    {process.env.REACT_APP_MAP_API_KEY && (
                      <Col span={7}>
                        <Typography.Text className="text-3 text-gray block mb-1">
                          Coordinate
                        </Typography.Text>
                        <Typography.Text className="text-3.5 text-primary-black">
                          {`${geolocation?.lat}, ${geolocation?.lng}`}
                        </Typography.Text>
                      </Col>
                    )}
                  </Col>
                </Row>
              </Col>
              <Divider style={{ marginTop: '1%' }} />
            </>
          )}

          <SectionContent
            groupTitle="Shipping Setting"
            subTitle="This is shipping setting, you can change here"
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row className="w-full" gutter={[12, 12]}>
                  <Col span={7}>
                    <Typography.Text className="text-3 text-gray block mb-1">
                      Courier
                    </Typography.Text>
                    <Typography.Text className="text-3.5 text-primary-black">
                      JNE
                    </Typography.Text>
                  </Col>
                  <Col span={7}>
                    <CustomFormItem name="originCode">
                      <Typography.Text className="text-3 text-gray block mb-1">
                        Origin Code
                      </Typography.Text>
                      <Select
                        showSearch
                        allowClear
                        autoClearSearchValue
                        optionFilterProp="children"
                        searchValue={searchOrigin}
                        onSearch={(value) => setSearchOrigin(value)}
                        placeholder="Origin Code"
                        className="w-full"
                        onChange={(e) =>
                          setTmpEditShipmentSetting(
                            (prev: shipmentSettingProps) => ({
                              ...prev,
                              jne: { ...prev.jne, originCode: e },
                            })
                          )
                        }
                        value={
                          tmpEditShipmentSetting?.jne.originCode
                            ? jneListOriginCode?.find(
                                (item: any) =>
                                  item.originCode ===
                                  tmpEditShipmentSetting?.jne.originCode
                              )?.originName
                            : undefined
                        }
                      >
                        {jneListOriginCode.map((origin) => (
                          <Select.Option value={origin?.originCode}>
                            {origin?.originName}
                          </Select.Option>
                        ))}
                      </Select>
                    </CustomFormItem>
                  </Col>
                  <Col span={7}>
                    <CustomFormItem name="branchCode">
                      <Typography.Text className="text-3 text-gray block mb-1">
                        Branch Code
                      </Typography.Text>
                      <Select
                        showSearch
                        allowClear
                        autoClearSearchValue
                        optionFilterProp="children"
                        searchValue={searchBranch}
                        onSearch={(value) => setSearchBranch(value)}
                        placeholder="Branch Code"
                        className="w-full"
                        onChange={(e) =>
                          setTmpEditShipmentSetting(
                            (prev: shipmentSettingProps) => ({
                              ...prev,
                              jne: { ...prev.jne, branchCode: e },
                            })
                          )
                        }
                        value={
                          tmpEditShipmentSetting?.jne?.branchCode
                            ? jneListBranchCode?.find(
                                (item: any) =>
                                  item.branchCode ===
                                  tmpEditShipmentSetting?.jne.branchCode
                              )?.branchName
                            : undefined
                        }
                      >
                        {jneListBranchCode.map((branch) => (
                          <Select.Option value={branch?.branchCode}>
                            {branch?.branchName}
                          </Select.Option>
                        ))}
                      </Select>
                    </CustomFormItem>
                  </Col>
                </Row>
              </Col>
              {/* <Col span={24}>
								<Typography.Text className='text-3 text-gray block mb-1'>Courier</Typography.Text>
								<Typography.Text className='text-3.5 text-primary-black'>RPX</Typography.Text>
							</Col> */}
              {/* <Col span={24}>
										<Typography.Text className='text-3 text-gray block mb-1'>
											Courier
										</Typography.Text>
										<Typography.Text className='text-3.5 text-primary-black'>Gojek</Typography.Text>
							</Col> */}
              {/* <Col span={24}>
										<Typography.Text className='text-3 text-gray block mb-1'>
											Courier
										</Typography.Text>
										<Typography.Text className='text-3.5 text-primary-black'>Grab</Typography.Text>
							</Col> */}
            </Row>
          </SectionContent>
        </Form>
      </div>
    </React.Fragment>
  );
};

const CustomFormItem = styled(Form.Item)`
  margin: 0;
`;
export default DeliveryPointEdit;
