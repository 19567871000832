import {
	Form,
	message,
	Radio,
	Space,
	Button,
	Card,
	Row,
	Col,
	Typography,
	InputNumber,
	Input,
	DatePicker,
} from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import {
	EVoucherDiscountType,
	EVoucherType,
	VoucherEventProperties,
	VoucherProperties,
	initialVoucher,
	initialVoucherEvent,
} from '../../types/voucher.type';
import { generateFormRules } from '../../helpers/formRules';
import moment from 'moment';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const VoucherEdit: React.FC = () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [dataEvent, setDataEvent] = React.useState<VoucherEventProperties>(initialVoucherEvent);
	const [dataVoucher, setDataVoucher] = React.useState<VoucherProperties>(initialVoucher);

	const createProduct = async () => {
		try {
			setIsLoadingAction(true);

			console.log('dataVoucher', dataVoucher);
			console.log('dataEvent', dataEvent);

			const resCreateVoucher = await httpRequest.post('/vouchers', {
				dataEvent,
				dataVoucher: { ...dataVoucher, voucherType: EVoucherType.event },
			});

			console.log('resCreateVoucher', resCreateVoucher);

			if (resCreateVoucher) {
				message.success('Success create voucher');
				navigate('/vouchers');
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async () => {
		await createProduct();
	};

	return (
		<React.Fragment>
			<HeaderSection
				icon='back'
				title={'Add Voucher'}
				rightAction={
					<Space>
						<Button
							style={{ padding: '0px 32px' }}
							onClick={() => navigate(-1)}>
							Cancel
						</Button>
						<Button
							style={{ padding: '0px 32px' }}
							loading={isLoadingAction}
							type='primary'
							onClick={() => form.submit()}>
							Save
						</Button>
					</Space>
				}
			/>

			<Card bordered={false}>
				<Form
					form={form}
					name='voucherForm'
					layout='vertical'
					onFinish={handleSubmit}
					autoComplete='off'>
					<Row>
						<Col span={5}>
							<Title level={5}>Voucher</Title>
						</Col>
						<Col
							offset={1}
							span={16}>
							<Row>
								<Col span={24}>
									<Form.Item
										name='title'
										label='Title'
										rules={generateFormRules('Title', ['required'])}>
										<Input
											style={{ width: '100%' }}
											value={dataEvent.title}
											onChange={(e: any) => {
												setDataEvent({
													...dataEvent,
													title: e.target.value,
												});
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<Form.Item
										name='description'
										label='Description'
										rules={generateFormRules('Description', ['required'])}>
										<Input
											style={{ width: '100%' }}
											value={dataEvent.description}
											onChange={(e: any) => {
												setDataEvent({
													...dataEvent,
													description: e.target.value,
												});
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<Form.Item
										name='voucherCode'
										label='Voucher Code'
										rules={generateFormRules('Voucher Code', ['required'])}>
										<Input
											style={{ width: '100%' }}
											value={dataVoucher.voucherCode}
											onChange={(e: any) => {
												setDataVoucher({
													...dataVoucher,
													voucherCode: e.target.value,
												});
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<Form.Item
										name='discountType'
										label='Discount Type'
										rules={generateFormRules('Discount Type', ['required'])}>
										<Radio.Group
											onChange={(e) =>
												setDataVoucher({
													...dataVoucher,
													discountType: e.target.value,
												})
											}
											defaultValue={true}
											value={dataVoucher?.discountType}>
											<CustomRadio value={EVoucherDiscountType.nominal}>Nominal</CustomRadio>
											<CustomRadio value={EVoucherDiscountType.percentage}>Percentage</CustomRadio>
										</Radio.Group>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[16, 0]}>
								<Col span={dataVoucher.discountType === EVoucherDiscountType.percentage ? 12 : 24}>
									<Form.Item
										name='discountValue'
										label='Discount Value'
										rules={generateFormRules('Discount Value', ['required'])}>
										<InputNumber
											style={{ width: '100%' }}
											value={dataVoucher.discountValue}
											controls={false}
											onChange={(e: any) => {
												setDataVoucher({
													...dataVoucher,
													discountValue: e,
												});
											}}
											addonAfter={
												dataVoucher.discountType === EVoucherDiscountType.nominal ? null : (
													<p style={{ marginBottom: 0, color: '#9FACBF' }}>%</p>
												)
											}
										/>
									</Form.Item>
								</Col>
								{dataVoucher.discountType === EVoucherDiscountType.percentage && (
									<Col span={12}>
										<Form.Item
											name='maxDiscountValue'
											label='Max Discount Value'>
											<InputNumber
												style={{ width: '100%' }}
												value={dataVoucher.maxDiscountValue}
												controls={false}
												onChange={(e: any) => {
													setDataVoucher({
														...dataVoucher,
														maxDiscountValue: e,
													});
												}}
											/>
										</Form.Item>
									</Col>
								)}
							</Row>
							<Row gutter={[16, 0]}>
								<Col span={12}>
									<Form.Item
										name='minTotalTransaction'
										label='Min Total Transaction'
										rules={generateFormRules('Min Total Transaction', ['required'])}>
										<InputNumber
											style={{ width: '100%' }}
											value={dataVoucher.requirement?.minTotalTransaction}
											controls={false}
											onChange={(e: any) => {
												setDataVoucher({
													...dataVoucher,
													requirement: {
														minTotalTransaction: e,
													},
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name='limitMaxUsePerPerson'
										label='Limit Max User Per Person'
										rules={generateFormRules('Limit Max User Per Person', ['required'])}>
										<InputNumber
											style={{ width: '100%' }}
											value={dataVoucher.limitMaxUsePerPerson}
											controls={false}
											onChange={(e: any) => {
												setDataVoucher({
													...dataVoucher,
													limitMaxUsePerPerson: e,
												});
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<Form.Item
										name='rangeDate'
										label='Valid Start and End Date'
										rules={generateFormRules('Valid Start and End Date', ['required'])}>
										<RangePicker
											style={{ width: '100%' }}
											value={[moment(dataVoucher.validStartAt), moment(dataVoucher.validEndAt)]}
											onChange={(dates: any, dateStrings: any) =>
												setDataVoucher({
													...dataVoucher,
													validStartAt: new Date(dateStrings?.[0]),
													validEndAt: new Date(dateStrings?.[1]),
												})
											}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Card>
		</React.Fragment>
	);
};

export default VoucherEdit;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: #1e1e1e;
		border-width: 2px;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: #1e1e1e;
	}
`;
