import React from 'react';
import useAuth from '../hooks/useAuth';
import {
	Navigate,
	Outlet
} from 'react-router-dom';

const PublicRoute: React.FC = ()=>{
	const { isLoggedIn } = useAuth();

	if(isLoggedIn){
		return <Navigate to="/" replace />
	}

	return <Outlet/>
}
export default PublicRoute;