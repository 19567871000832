import AppLayout from '../layout/AppLayout';
import { AntDesignOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Pagination,
  Table,
  Input,
  Select,
  Row,
  Col,
  Tag,
  DatePicker,
  Typography,
  Tabs,
  Badge,
  Image,
  message,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { formatDate, PAGE_SIZE_OPTIONS } from '../../helpers/constant';
import {
  BaseResponsePaginationProps,
  BaseResponseProps,
} from '../../types/config.type';
import useFetchList from '../../hooks/useFetchList';
import moment from 'moment';
// import { ReactComponent as OrderLogo } from "../../assets/icons/order-icon.svg";
import { httpRequest } from '../../helpers/api';
import useAuth from '../../hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { ContainerFilter } from '../product';
import {
  EPaymentStatus,
  ETransactionStatus,
  TransactionProps,
} from '../../types/transaction';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePrevious } from '../loyalty/Detail';
import CustomPagination from '../../components/CustomPagination';
import { IconArrowDown } from '../../assets/icons';
import formatPrice from '../../helpers/formatPrice';
import { DEFAULT_IMG } from '../../const/config';

interface ResponseProps extends BaseResponseProps<TransactionProps> {
  payload: Omit<TransactionProps, 'createdAt' | 'updatedAt'>;
}

const labelStyle = {
  width: '150px',
  color: 'grey',
};

const { RangePicker } = DatePicker;

const tagStyle = [
  {
    color: '#31d63a',
  },
  {
    color: '#D81F64',
  },
  {
    color: '#748494',
  },
];

const listOfStatusFailed = [ETransactionStatus.expired];

const listOfStatusSuccess = [
  ETransactionStatus.paid,
  ETransactionStatus.shipped,
  ETransactionStatus.done,
];

export const colorTransactionStatus = [
  {
    status: ETransactionStatus.created,
    color: '#ffbc00',
    bgColor: '#fff8e4',
  },
  {
    status: ETransactionStatus.shipped,
    color: '#38AC3D',
    bgColor: '#D7F0D8',
  },
  {
    status: ETransactionStatus.paid,
    color: '#2391D9',
    bgColor: ' #D9EDFC',
  },
  {
    status: ETransactionStatus.expired,
    color: '#D81F64',
    bgColor: '#f097b8',
  },
  {
    status: ETransactionStatus.done,
    color: '#38AC3D',
    bgColor: '#D7F0D8',
  },
  {
    status: ETransactionStatus.scheduled_for_shipment,
    color: '#6F7887',
    bgColor: '#DADEE4',
  },
];

const Transaction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const buyerId: string = location.state
    ? location.state.buyerId
      ? location.state.buyerId
      : ''
    : '';

  const [transactions, setTransactions] = useState<TransactionProps[]>([]);
  const {
    isLoading,
    data,
    pagination,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
    setIsLoading,
    setPagination,
  } = useFetchList<TransactionProps>({
    endpoint: 'transactions',
    initialQuery: {
      // buyerId: location ? location.state.buyerId : '',
      buyerId,
    },
    limit: +PAGE_SIZE_OPTIONS[0],
  });

  console.log(data);
  const [keyTab, setKeyTab] = useState('all');

  const onChangeTab = (key: string) => {
    handleTransactionStatusFilterChange(key);
  };

  const handleClickDetail = (e: TransactionProps) => {
    navigate(`/transactions/${e.transactionId}`);
  };
  const { Option } = Select;

  useEffect(() => {
    let url = buyerId ? `/transactions?buyerId=${buyerId}` : '/transactions';
    (async () => {
      try {
        const res = await httpRequest.get<
          BaseResponsePaginationProps<TransactionProps>
        >(url);
        if (res && res.data) {
          setTransactions(res.data.payload.results);
        } else {
          message.error('Something went wrong');
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [buyerId]);

  const handleTransactionStatusFilterChange = (
    transactionStatus: ETransactionStatus | string
  ) => {
    let arrTransStatus = transactionStatus.split('/');
    console.log(arrTransStatus);
    setKeyTab(
      arrTransStatus.length > 0 ? arrTransStatus.join('/') : arrTransStatus[0]
    );
    if (arrTransStatus[0] === 'all') {
      setQuery((oldVal) => ({
        ...oldVal,
        transactionStatus: '',
        buyerId: buyerId ? buyerId : '',
        shipmentType: '',
      }));
    } else if (arrTransStatus[0]) {
      setQuery((oldVal) => ({
        ...oldVal,
        transactionStatus: arrTransStatus[0],
        buyerId: buyerId ? buyerId : '',
        shipmentType: arrTransStatus[1],
      }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, transactionStatus: null }));
    }
  };

  const handleTransactionDateFilterChange = (e: any) => {
    console.log(e);
    if (e) {
      setQuery((oldVal) => ({
        ...oldVal,
        startAt: moment.utc(e[0]).toISOString(),
        endAt: moment.utc(e[1]).toISOString(),
      }));
    } else {
      setQuery((oldVal) => ({
        ...oldVal,
        startAt: '',
        endAt: '',
      }));
    }
  };

  const columns = [
    {
      title: 'ORDER ID',
      key: 'transactionCode',
      dataIndex: 'transactionCode',
      render: (text: string, record: TransactionProps) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            {text?.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: 'TRANSACTION DATE',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (price: number, record: TransactionProps) => {
        return (
          <div>{moment(record.createdAt).format('DD MMM YYYY, HH:mm')}</div>
        );
      },
    },
    {
      title: 'CUSTOMERS',
      key: 'CustomerName',
      dataIndex: 'buyerDetail.fullName',
      render: (text: string, record: TransactionProps) => {
        return (
          <div className="flex flex-col">
            <Typography.Text className="text-primary-black text-3.5">
              {record.buyerDetail?.name}
            </Typography.Text>
            <Typography.Text className="text-gray text-2.7">
              {record.buyerDetail?.email}
            </Typography.Text>{' '}
            <Typography.Text className="text-gray text-2.7">
              {record.buyerDetail?.phone}
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: 'ORDERS',
      key: '',
      dataIndex: '',
      width: 350,
      render: (text: string, record: TransactionProps) => {
        const [first, ...other] = record?.transactionItems!;
        const isPromo = first?.metaProduct?.promoId;
        const isPoint = first?.metaProduct?.redeemProductId;
        const hargaCoret =
          isPromo &&
          record?.transactionItems?.[0]?.metaProduct?.items?.length === 1;

        return (
          <div className="flex gap-x-2">
            <Image
              width={42}
              height={42}
              src={
                (hargaCoret
                  ? first?.metaProduct?.items[0]?.product?.images[0]?.imageUrl
                  : isPromo
                  ? first?.metaProduct?.image
                  : isPoint
                  ? first?.metaProduct?.product?.images[0]?.imageUrl
                  : first?.metaProduct?.images
                  ? first?.metaProduct?.images[0]?.imageUrl
                  : DEFAULT_IMG) || DEFAULT_IMG
              }
              className="p-1 border-default"
              style={{ width: 42, height: 42 }}
              fallback={DEFAULT_IMG}
            />
            <div className="flex flex-col">
              <Typography.Text className="text-primary-black text-3.5">
                {hargaCoret
                  ? first?.metaProduct?.items[0]?.product?.productName
                  : isPromo
                  ? first?.metaProduct.description
                  : isPoint
                  ? first?.metaProduct?.product?.productName
                  : first?.metaProduct?.productName}
              </Typography.Text>
              {other.length > 0 && (
                <Typography.Text className="text-gray text-2.7">
                  +{other.length} product{other.length > 1 ? 's' : ''}
                </Typography.Text>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: 'SHIPMENT',
      key: '',
      dataIndex: '',
      render: (text: string, record: TransactionProps) => {
        return (
          <div className="flex flex-col">
            <Typography.Text className="text-primary-black text-2.7">
              <span className="text-gray">Receiver</span>{' '}
              {record.transactionShipment?.shipToAdress.receiverName ??
                'Not Set'}
            </Typography.Text>
            <Typography.Text className="text-gray text-2.7">
              <span className="text-gray">Courier</span>{' '}
              {record.transactionShipment?.courierVendor ?? 'Not Set'}
            </Typography.Text>{' '}
            <Typography.Text className="text-gray text-2.7">
              <span className="text-gray">Shipment ID</span>{' '}
              {record.transactionShipment?.waybill ?? 'Not Set'}
            </Typography.Text>
          </div>
        );
      },
    },

    {
      title: 'TOTAL',
      key: 'finalPrice',
      dataIndex: 'finalPrice',
      render: (price: number, record: TransactionProps) => {
        const isPoint = record?.details?.items.find(
          (item) => item.type === 'point'
        );
        const shippingFee = record?.details?.items.find(
          (item) => item.key === 'shipping-fee'
        )?.value;

        return (
          <div className="text-3 text-primary-black">
            {/* {record.totalFinalPrice
              ? `IDR ${record.totalFinalPrice?.toLocaleString('id-ID')}`
              : '-'} */}
            {record && isPoint
              ? `IDR ${formatPrice(shippingFee || 0)}`
              : `IDR ${record.totalFinalPrice?.toLocaleString('id-ID')}`}
          </div>
        );
      },
    },
    // keyTab === 'done'
    //   ? {
    //       title: 'DELIVERY DATE',
    //       key: 'deliverAt',
    //       dataIndex: 'deliverAt',
    //       render: (text: string, record: TransactionProps) => {
    //         return <div>-</div>;
    //       },
    //     }
    //   : {
    //       title: 'STATUS',
    //       key: 'transactionStatus',
    //       dataIndex: 'transactionStatus',
    //       render: (text: string, record: TransactionProps) => {
    //         const res = colorTransactionStatus.find(
    //           (item) => item.status === text
    //         );
    //         return (
    //           <div
    //             className="px-3 py-1 w-max rounded-full flex justify-center"
    //             style={{
    //               backgroundColor: res?.bgColor,
    //               color: res?.color,
    //               width: 'max-content',
    //             }}
    //           >
    //             <span className="font-semibold text-center">
    //               {text === ETransactionStatus.created
    //                 ? 'Waiting Payment'
    //                 : text}
    //             </span>
    //           </div>
    //         );
    //       },
    //     },

    {
      title: 'STATUS',
      key: 'transactionStatus',
      dataIndex: 'transactionStatus',
      render: (text: string, record: TransactionProps) => {
        const res = colorTransactionStatus.find((item) => item.status === text);
        return (
          <div
            className="px-3 py-1 w-max rounded-full flex justify-center"
            style={{
              backgroundColor: res?.bgColor,
              color: res?.color,
              width: 'max-content',
            }}
          >
            <span className="font-semibold text-center">
              {text === ETransactionStatus.created
                ? 'Waiting Payment'
                : text === ETransactionStatus.scheduled_for_shipment
                ? record.transactionShipment?.shipmentType
                : text}
            </span>
          </div>
        );
      },
    },
  ];

  const waitingTransLength = transactions?.filter(
    (item) => item.transactionStatus === ETransactionStatus.created
  ).length;
  const paidLength = transactions?.filter(
    (item) => item.transactionStatus === ETransactionStatus.paid
  ).length;
  const shippedLength = transactions?.filter(
    (item) => item.transactionStatus === ETransactionStatus.shipped
  ).length;
  const doneLength = transactions?.filter(
    (item) => item.transactionStatus === ETransactionStatus.done
  ).length;
  const expiredLength = transactions?.filter(
    (item) => item.transactionStatus === ETransactionStatus.expired
  ).length;
  const pickupLength = transactions?.filter(
    (item) =>
      item.transactionStatus === ETransactionStatus.scheduled_for_shipment &&
      item.transactionShipment?.shipmentType === 'PICKUP'
  ).length;
  const dropLength = transactions?.filter(
    (item) =>
      item.transactionStatus === ETransactionStatus.scheduled_for_shipment &&
      item.transactionShipment?.shipmentType === 'DROP'
  ).length;

  const items = [
    {
      label: (
        <div className="flex items-center gap-2">
          Waiting Payment{' '}
          <Badge
            count={waitingTransLength}
            style={{ backgroundColor: '#20A1F5' }}
            overflowCount={9999}
          />
        </div>
      ),
      key: 'created',
    },
    {
      label: (
        <div className="flex items-center gap-2">
          Paid{' '}
          <Badge
            count={paidLength}
            style={{ backgroundColor: '#20A1F5' }}
            overflowCount={9999}
          />
        </div>
      ),
      key: 'paid',
    },
    {
      label: (
        <div className="flex items-center gap-2">
          Pickup
          <Badge
            count={pickupLength}
            style={{ backgroundColor: '#20A1F5' }}
            overflowCount={9999}
          />
        </div>
      ),
      key: `${ETransactionStatus.scheduled_for_shipment}/PICKUP`,
    },
    {
      label: (
        <div className="flex items-center gap-2">
          Must Drop
          <Badge
            count={dropLength}
            style={{ backgroundColor: '#20A1F5' }}
            overflowCount={9999}
          />
        </div>
      ),
      key: `${ETransactionStatus.scheduled_for_shipment}/DROP`,
    },
    {
      label: (
        <div className="flex items-center gap-2">
          Shipped
          <Badge
            count={shippedLength}
            style={{ backgroundColor: '#20A1F5' }}
            overflowCount={9999}
          />
        </div>
      ),
      key: 'shipped',
    },

    {
      label: (
        <div className="flex items-center gap-2">
          Done{' '}
          <Badge
            count={doneLength}
            style={{ backgroundColor: '#20A1F5' }}
            overflowCount={9999}
          />
        </div>
      ),
      key: 'done',
    },
    {
      label: (
        <div className="flex items-center gap-2">
          Expired{' '}
          <Badge count={expiredLength} style={{ backgroundColor: '#20A1F5' }} />
        </div>
      ),
      key: 'expired',
    },
    {
      label: (
        <div className="flex items-center gap-2">
          All Transactions{' '}
          <Badge
            count={transactions.length}
            style={{ backgroundColor: '#20A1F5' }}
            overflowCount={9999}
          />
        </div>
      ),
      key: 'all',
    },
  ];

  return (
    <React.Fragment>
      <HeaderSection
        icon={buyerId ? 'back' : <AntDesignOutlined />}
        title={
          buyerId
            ? `Transactions of ${
                data[0]?.buyerDetail?.name
                  ? data[0]?.buyerDetail?.name
                  : 'Not Set'
              }`
            : 'Transactions'
        }
      />
      <Row gutter={{ xs: 8, sm: 15 }} className="mb-7.5">
        <Col span={12}>
          <Typography.Text className="text-3 text-gray block font-semibold mb-1">
            Search
          </Typography.Text>
          <Input
            placeholder="Search by Order ID"
            suffix={<SearchOutlined />}
            allowClear
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        {/* <Col span={6}>
          <Typography.Text className="text-3 text-gray block font-semibold mb-1">
            Status
          </Typography.Text>
          <Select
            allowClear
            onChange={handleTransactionStatusFilterChange}
            placeholder="Transaction Status"
            defaultValue="all"
            suffixIcon={<IconArrowDown />}
          >
            <Option value="all">All</Option>
            <Option value={ETransactionStatus.created}>Waiting Payment</Option>
            <Option value={ETransactionStatus.paid}>Paid</Option>
            <Option
              value={`${ETransactionStatus.scheduled_for_shipment}/PICKUP`}
            >
              Pickup
            </Option>
            <Option value={`${ETransactionStatus.scheduled_for_shipment}/DROP`}>
              Drop
            </Option>
            <Option value={ETransactionStatus.shipped}>Shipped</Option>{' '}
            <Option value={ETransactionStatus.done}>Done</Option>
            <Option value={ETransactionStatus.expired}>Expired</Option>
          </Select>
        </Col> */}
        <Col span={6}>
          <Typography.Text className="text-3 text-gray block font-semibold mb-1">
            Order Date
          </Typography.Text>
          <RangePicker
            suffixIcon={null}
            format="DD MMM YYYY"
            onChange={handleTransactionDateFilterChange}
          />
        </Col>
      </Row>
      <Tabs
        items={items}
        onChange={onChangeTab}
        defaultActiveKey="all"
        activeKey={keyTab}
      />

      <Row style={{ paddingBottom: 24 }}>
        <Col span={24}>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={data && data}
            pagination={{
              pageSize: pagination.perPage,
              current: pagination.page,
              style: { display: 'none' },
            }}
          />
        </Col>
        <CustomPagination
          data={data && data}
          pagination={pagination}
          changeLimit={changeLimit}
          changePage={changePage}
        />
      </Row>
    </React.Fragment>
  );
};

export default Transaction;
