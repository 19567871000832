import { BaseResponsePaginationProps } from './config.type';
import { CategoryProps } from './category.type';
import { ProductPriceProps, initialProductPrice } from './productPrices.type';

// export interface ProductVariantProps {
//     productVariantId?: string;
//     productVariantCode: string;
//     productId: string;
//     variantName: string;
//     description: string;
//     isPublished: boolean;
// }

// export const initialProductVariant: ProductVariantProps = {
//     productVariantId: '',
//     productVariantCode: '',
//     productId: '',
//     variantName: '',
//     description: '',
//     isPublished: true
// }

export interface ProductProps {
  productId?: string;
  productName: string;
  productCode: string;
  currentProductPriceId: string;
  description: string;
  isPublished: boolean;
  abv: number;
  country: string;
  sizeInML: number;
  isHighlight?: boolean;

  grossWeight: number;
  netWeight: number;
  volume: number;

  category: string;
  subcat: string;

  baseUnit: string;
  salesUnit: string;
  purchaseUnit: string;

  images?: Array<{
    fileId: string;
    imageUrl: string | null;
  }>;

  // variants: ProductVariantProps[];
  categories: CategoryProps[];
  prices: ProductPriceProps[];
  currentProductPrice: ProductPriceProps;

  updatedAt?: Date | string;
  createdAt?: Date | string;
  statusLoading?: boolean;
}

export interface FetchAllProductResponse
  extends BaseResponsePaginationProps<ProductProps> {
  code: string;
  message: string;
  payload: {
    count: number;
    prev: string;
    next: string;
    results: ProductProps[];
  };
}

export const initialProduct: ProductProps = {
  productId: '',
  productName: '',
  productCode: '',
  currentProductPriceId: '',
  description: '',
  category: '',
  subcat: '',
  isPublished: true,
  abv: 0,
  country: '',

  grossWeight: 0,
  netWeight: 0,
  volume: 0,

  sizeInML: 0,

  baseUnit: '',
  salesUnit: '',
  purchaseUnit: '',
  images: [],

  // variants: [],
  categories: [],
  prices: [],
  currentProductPrice: initialProductPrice,

  updatedAt: '',
  createdAt: '',
  statusLoading: false,
};
