import {
  Row,
  Col,
  Button,
  Space,
  Form,
  Typography,
  Divider,
  Spin,
  message,
} from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import { formatYearToTime } from '../../helpers/constant';
import {
  DeliveryPointProps,
  initialDeliveryPoint,
} from '../../types/deliveryPoint.type';
import SectionContent from '../../components/SectionContent';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import MapPickLocation from '../../components/AppMapsLocation';

interface ResponseProps extends BaseResponseProps<DeliveryPointProps> {
  payload: Omit<DeliveryPointProps, 'createdAt' | 'updatedAt'>;
}

interface ILocation {
  deliveryPointId: string;
}

const DeliveryPointsDetail: React.FC = () => {
  const navigate = useNavigate();
  const { deliveryPointId } = useParams<keyof ILocation>() as ILocation;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [deliveryPoint, setDeliveryPoint] =
    React.useState<DeliveryPointProps>(initialDeliveryPoint);
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  console.log(deliveryPoint);
  React.useEffect(() => {
    const fetchDeliveryPointDetail = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          '/delivery_points/' + deliveryPointId
        );
        if (res && res.data && res.data.payload) {
          setDeliveryPoint(res.data.payload);
        } else {
          message.error('Something went wrong');
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    const bcDetails = [
      {
        field: 'deliveryPointId',
        value: deliveryPointId,
        label: 'Detail',
      },
    ];
    setBreadcrumbDetails(bcDetails);

    fetchDeliveryPointDetail();
  }, [deliveryPointId]);

  console.log(deliveryPoint);
  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title="Delivery Outlet Detail"
        subtitle={deliveryPoint?.deliveryPointId}
        rightAction={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                navigate(
                  `/delivery-outlets/${deliveryPoint?.deliveryPointId}/edit`
                );
              }}
            >
              Edit Delivery Point
            </Button>
          </Space>
        }
      />
      {isLoading ? (
        <Spin spinning />
      ) : (
        <div style={{ padding: 24 }}>
          <SectionContent
            groupTitle="Delivery Outlet"
            subTitle="These are delivery details, you can't change here"
          >
            <Row justify="space-between">
              <Col span={21}>
                <Form.Item name="outletName" className="mb-3">
                  <Typography.Text className="text-3 text-gray block mb-1">
                    Delivery Outlet Name
                  </Typography.Text>
                  <Typography.Text className="text-3.5 text-primary-black">
                    {deliveryPoint?.name}
                  </Typography.Text>
                </Form.Item>
              </Col>
              <Col span={21}>
                <Form.Item name="locationId" className="mb-3">
                  <Typography.Text className="text-3 text-gray block mb-1">
                    Store
                  </Typography.Text>
                  <Typography.Text className="text-3.5 text-primary-black">
                    {deliveryPoint?.location?.locationName}
                  </Typography.Text>
                </Form.Item>
              </Col>
              <Col span={21}>
                <Form.Item name="isPublished" className="mb-3">
                  <Typography.Text className="text-3 text-gray block mb-1">
                    Status
                  </Typography.Text>
                  <Typography.Text className="text-3.5 text-primary-black">
                    {deliveryPoint?.isPublished ? 'Active' : 'Inactive'}
                  </Typography.Text>
                </Form.Item>
              </Col>
              <Col span={21}>
                <Row>
                  <Col span={7}>
                    <Typography.Text className="text-3 text-gray block mb-1">
                      Created At
                    </Typography.Text>
                    <Typography.Text className="text-3.5 text-primary-black">
                      {formatYearToTime(deliveryPoint?.createdAt)}
                    </Typography.Text>
                  </Col>
                  <Col span={7}>
                    <Typography.Text className="text-3 text-gray block mb-1">
                      Updated At
                    </Typography.Text>
                    <Typography.Text className="text-3.5 text-primary-black">
                      {formatYearToTime(deliveryPoint?.updatedAt)}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </SectionContent>
          <Divider style={{ marginTop: '1%' }} />
          {process.env.REACT_APP_MAP_API_KEY && (
            <>
              <SectionContent
                groupTitle="Set the location pinpoint"
                subTitle="These are loyalty details, you can change anything"
              >
                <div
                  style={{
                    border: `1px solid #D5DCE1`,
                    padding: 12,
                    borderRadius: 8,
                  }}
                >
                  <MapPickLocation
                    onChange={(location) => {
                      console.log(location);
                    }}
                    center={{
                      lat: +deliveryPoint?.location?.geolocation?.lat!,
                      lng: +deliveryPoint?.location?.geolocation?.lng!,
                    }}
                    isDetail
                  />
                </div>
              </SectionContent>
              <Divider style={{ marginTop: '1%' }} />
            </>
          )}

          <SectionContent
            groupTitle="Shipping Setting"
            subTitle="This is shipping setting, you can't change here"
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row className="w-full" gutter={[12, 12]}>
                  <Col span={7}>
                    <Typography.Text className="text-3 text-gray block mb-1">
                      Courier
                    </Typography.Text>
                    <Typography.Text className="text-3.5 text-primary-black">
                      JNE
                    </Typography.Text>
                  </Col>
                  <Col span={7}>
                    <Typography.Text className="text-3 text-gray block mb-1">
                      Origin Code
                    </Typography.Text>
                    <Typography.Text className="text-3.5 text-primary-black">
                      {deliveryPoint?.location?.shipmentSetting?.jne
                        ?.originCode || 'Not Set'}
                    </Typography.Text>
                  </Col>
                  <Col span={7}>
                    <Typography.Text className="text-3 text-gray block mb-1">
                      Branch Code
                    </Typography.Text>
                    <Typography.Text className="text-3.5 text-primary-black">
                      {deliveryPoint?.location?.shipmentSetting?.jne
                        ?.branchCode || 'Not Set'}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
              {/* <Col span={24}>
								<Row
									className='w-full'
									gutter={[12, 12]}>
									<Col span={7}>
										<Typography.Text className='text-3 text-gray block mb-1'>
											Courier
										</Typography.Text>
										<Typography.Text className='text-3.5 text-primary-black'>RPX</Typography.Text>
									</Col>
									<Col span={7}>
										<Typography.Text className='text-3 text-gray block mb-1'>
											ZIP Code
										</Typography.Text>
										<Typography.Text className='text-3.5 text-primary-black'>-</Typography.Text>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row
									className='w-full'
									gutter={[12, 12]}>
									<Col span={7}>
										<Typography.Text className='text-3 text-gray block mb-1'>
											Courier
										</Typography.Text>
										<Typography.Text className='text-3.5 text-primary-black'>Gojek</Typography.Text>
									</Col>
									{process.env.REACT_APP_MAP_API_KEY && (
										<Col span={7}>
											<Typography.Text className='text-3 text-gray block mb-1'>
												Coordinate
											</Typography.Text>
											<Typography.Text className='text-3.5 text-primary-black'>
												{`${deliveryPoint?.location?.geolocation?.lat}, ${deliveryPoint?.location?.geolocation?.lng}`}
											</Typography.Text>
										</Col>
									)}
								</Row>
							</Col>
							<Col span={24}>
								<Row
									className='w-full'
									gutter={[12, 12]}>
									<Col span={7}>
										<Typography.Text className='text-3 text-gray block mb-1'>
											Courier
										</Typography.Text>
										<Typography.Text className='text-3.5 text-primary-black'>Grab</Typography.Text>
									</Col>
									{process.env.REACT_APP_MAP_API_KEY && (
										<Col span={7}>
											<Typography.Text className='text-3 text-gray block mb-1'>
												Coordinate
											</Typography.Text>
											<Typography.Text className='text-3.5 text-primary-black'>
												{`${deliveryPoint?.location?.geolocation?.lat}, ${deliveryPoint?.location?.geolocation?.lng}`}
											</Typography.Text>
										</Col>
									)}
								</Row>
							</Col> */}
            </Row>
          </SectionContent>
        </div>
      )}
    </React.Fragment>
  );
};

export default DeliveryPointsDetail;
