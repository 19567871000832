import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MoreOutlined,
  SearchOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import {
  Pagination,
  Space,
  Table,
  Switch,
  Dropdown,
  Menu,
  Modal,
  message,
  Input,
  Select,
  Typography,
  Button,
  Row,
  Col,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import styled from 'styled-components';
import useFetchList from '../../hooks/useFetchList';
import { PAGE_SIZE_OPTIONS } from '../../helpers/constant';
import {
  DeliveryPointProps,
  initialDeliveryPoint,
} from '../../types/deliveryPoint.type';
import CustomPagination from '../../components/CustomPagination';
import moment from 'moment';
import { IconArrowDown } from '../../assets/icons';

interface ResponseProps extends BaseResponseProps<DeliveryPointProps> {
  payload: Omit<DeliveryPointProps, 'createdAt' | 'updatedAt'>;
}

interface DeleteResponseProps
  extends BaseResponseProps<{ isSuccess: boolean }> {
  payload: {
    isSuccess: boolean;
  };
}

const DeliveryPoints = () => {
  const navigate = useNavigate();

  const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
    React.useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [tmpData, setTmpData] =
    React.useState<DeliveryPointProps>(initialDeliveryPoint);
  const [isLoadingDelete, setIsLoadingDelete] = React.useState<boolean>(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] =
    React.useState<boolean>(false);

  const {
    isLoading,
    data,
    pagination,
    setData,
    fetchList,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<DeliveryPointProps>({
    endpoint: 'delivery_points',
    limit: +PAGE_SIZE_OPTIONS[0],
  });

  const handleStatusChange = async () => {
    try {
      setIsLoadingUpdateStatus(true);

      const res = await httpRequest.patch<ResponseProps>(
        '/delivery_points/' + tmpData.deliveryPointId,
        {
          isPublished: !tmpData.isPublished,
        }
      );

      if (res.data.payload) {
        message.success('Success change ' + tmpData.name + ' status.');
        fetchList();
      }

      setIsLoadingUpdateStatus(false);
      setIsModalVisible(false);
      setTmpData(initialDeliveryPoint);
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalVisible(false);
      setTmpData(initialDeliveryPoint);
      setIsLoadingUpdateStatus(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoadingDelete(true);
      let newData = [];
      newData = data.map((item) => {
        if (item.deliveryPointId === tmpData.deliveryPointId) {
          return {
            ...item,
            statusLoading: true,
          };
        }
        return item;
      });
      setData(newData);

      const res = await httpRequest.delete<DeleteResponseProps>(
        '/delivery_points/' + tmpData.deliveryPointId
      );

      if (res.data.payload.isSuccess) {
        newData = data.filter(
          (item) => item.deliveryPointId !== tmpData.deliveryPointId
        );
        setData(newData);

        message.success('Successfully delete ' + tmpData.name + '.');
      }

      setIsLoadingDelete(false);
      setIsModalDeleteVisible(false);
      setTmpData(initialDeliveryPoint);
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalDeleteVisible(false);
      setTmpData(initialDeliveryPoint);
      setIsLoadingDelete(false);
    }
  };

  const { Option } = Select;

  const handleClickDetail = (e: DeliveryPointProps) => {
    navigate(`/delivery-outlets/${e.deliveryPointId}`);
  };

  const handleClickAction = (props: DeliveryPointProps, key: string) => {
    if (key === 'detail') {
      navigate(`/delivery-outlets/${props.deliveryPointId}`);
    } else if (key === 'edit') {
      navigate(`/delivery-outlets/${props.deliveryPointId}/edit`);
    } else if (key === 'delete') {
      setTmpData(props);
      setIsModalDeleteVisible(true);
    }
  };

  const handleCreateDeliveryPoint = () => {
    navigate('/delivery-outlets/add');
  };

  const handleFilterStatus = (status: string) => {
    console.log(typeof status);
    if (status !== 'all') {
      setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isPublished: '' }));
    }
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: DeliveryPointProps) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            {record?.name}
          </div>
        );
      },
    },
    {
      title: 'STORE',
      dataIndex: 'location',
      key: 'location',
      render: (text: string, record: DeliveryPointProps) => {
        return <div>{record?.location?.locationName}</div>;
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'isPublished',
      key: 'isPublished',
      render: (text: string, record: DeliveryPointProps) => {
        return (
          <Switch
            loading={record?.statusLoading}
            checked={record?.isPublished}
            onChange={() => {
              setIsModalVisible(true);
              setTmpData(record);
            }}
          />
        );
      },
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: any) => (
        <div>{moment(updatedAt).format('DD MMM YYYY, HH:mm')}</div>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any) => (
        <div>{moment(createdAt).format('DD MMM YYYY, HH:mm')}</div>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: DeliveryPointProps) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: DeliveryPointProps) => (
    <Menu
      onClick={(e) => handleClickAction(record, e.key)}
      items={[
        { label: 'Detail', key: 'detail' },
        { label: 'Edit', key: 'edit' },
        { label: 'Delete', key: 'delete' },
      ]}
    />
  );

  return (
    <React.Fragment>
      <HeaderSection
        icon={<EnvironmentOutlined />}
        title="Delivery Outlet"
        subtitle="Manage your delivery point data"
        rightAction={
          <Space>
            <Button type="primary" onClick={handleCreateDeliveryPoint}>
              Add Outlet
            </Button>
          </Space>
        }
      />

      <Row gutter={{ xs: 8, sm: 15 }} className="mb-7.5">
        <Col span={18}>
          <Typography.Text className="text-3 text-gray block font-semibold mb-1">
            Search
          </Typography.Text>
          <Input
            placeholder="Search by outlet name"
            suffix={<SearchOutlined />}
            allowClear
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col span={6}>
          <Typography.Text className="text-3 text-gray block font-semibold mb-1">
            Status
          </Typography.Text>
          <Select
            allowClear
            onChange={handleFilterStatus}
            placeholder="Status"
            defaultValue="all"
            suffixIcon={<IconArrowDown />}
          >
            <Option value="all">All</Option>
            <Option value={'true'}>Active</Option>
            <Option value={'false'}>Inactive</Option>
          </Select>
        </Col>
      </Row>

      {/* <Table
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        current={pagination.page}
        total={pagination.totalData}
        defaultPageSize={pagination.perPage}
        pageSize={pagination.perPage}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onShowSizeChange={(_current, size) => {
          changeLimit(size);
        }}
        showSizeChanger={true}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        onChange={changePage}
      /> */}

      <Row style={{ paddingBottom: 24 }}>
        <Col span={24}>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={data && data}
            pagination={{
              pageSize: pagination.perPage,
              current: pagination.page,
              style: { display: 'none' },
            }}
          />
        </Col>
        <CustomPagination
          data={data && data}
          pagination={pagination}
          changeLimit={changeLimit}
          changePage={changePage}
        />
      </Row>

      <Modal
        title="Update status confirmation"
        open={isModalVisible}
        onOk={handleStatusChange}
        onCancel={() => {
          setIsModalVisible(false);
          setTmpData(initialDeliveryPoint);
        }}
        okText="Yes"
        confirmLoading={isLoadingUpdateStatus}
        okButtonProps={{ type: 'primary' }}
      >
        <p>
          Are you sure want to change the status from{' '}
          <b>
            "{tmpData.isPublished === true ? 'Published' : 'Not Published'}"
          </b>{' '}
          status to{' '}
          <b>
            "{tmpData.isPublished === true ? 'Not Published' : 'Published'}"
          </b>
          ?. {!tmpData.isPublished}
        </p>
      </Modal>
      <Modal
        title="Delete article confirmation"
        visible={isModalDeleteVisible}
        onOk={handleDelete}
        onCancel={() => {
          setIsModalDeleteVisible(false);
          setTmpData(initialDeliveryPoint);
        }}
        okText="Yes"
        confirmLoading={isLoadingDelete}
        okButtonProps={{ type: 'primary' }}
      >
        <p>
          Are you sure want to delete delivery point <b>{tmpData.name}</b>?
        </p>
      </Modal>
    </React.Fragment>
  );
};

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

export default DeliveryPoints;
