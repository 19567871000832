import { Row, Select, Col, Typography } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../../components/HeaderSection';
import { CategoryProps } from '../../types/category.type';
import { capitalizeFirstLetter } from '../../helpers/text';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { VoucherProperties, initialVoucher } from '../../types/voucher.type';
import DetailItem from '../../components/DetailItem';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import moment from 'moment';

interface ILocation {
	voucherCode: string;
}

const { Text, Title } = Typography;
const { Option } = Select;

const ProductDetail: React.FC = () => {
	const navigate = useNavigate();

	const { voucherCode } = useParams<keyof ILocation>() as ILocation;
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();

	const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false);
	const [voucher, setVoucher] = React.useState<VoucherProperties>(initialVoucher);

	React.useEffect(() => {
		const fetchloyaltyDetail = async () => {
			try {
				setIsLoadingData(true);

				const res = await httpRequest.get<BaseResponseProps<VoucherProperties>>(
					'/vouchers/' + voucherCode
				);

				if (res.data && res.data.payload) {
					setVoucher(res.data.payload);
				}

				const bcDetails = [
					{
						field: 'voucherCode',
						value: voucherCode,
						label: 'Detail Voucher',
					},
				];
				setBreadcrumbDetails(bcDetails);

				setIsLoadingData(false);
			} catch (error) {
				console.log(error);
			} finally {
				setIsLoadingData(false);
			}
		};

		fetchloyaltyDetail();
	}, [voucherCode]);

	return (
		<React.Fragment>
			<HeaderSection
				icon='back'
				title={voucherCode}
			/>
			<div>
				<Row>
					<Col span={5}>
						<Title level={5}>Voucher Information</Title>
					</Col>
					<Col
						offset={1}
						span={16}>
						<Row>
							<Col span={10}>
								<DetailItem
									label={'Voucher Code'}
									value={voucher.voucherCode}
								/>
							</Col>
							<Col span={10}>
								<DetailItem
									label={'Title'}
									value={voucher.event?.title}
								/>
							</Col>
						</Row>
						<Row>
							<DetailItem
								label={'Description'}
								value={voucher.event?.description}
							/>
						</Row>
						<Row>
							<Col span={10}>
								<DetailItem
									label={'Voucher Status'}
									value={capitalizeFirstLetter(voucher.voucherStatus)}
								/>
							</Col>
							<Col span={10}>
								<DetailItem
									label={'Discount Type'}
									value={capitalizeFirstLetter(voucher.discountType)}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={10}>
								<DetailItem
									label={'Discount Value'}
									value={voucher.discountValue?.toString()}
								/>
							</Col>
							<Col span={10}>
								<DetailItem
									label={'Max Discount Value'}
									value={voucher.maxDiscountValue?.toString()}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={10}>
								<DetailItem
									label={'Limit Max Use Per Person'}
									value={voucher.limitMaxUsePerPerson?.toString()}
								/>
							</Col>
							<Col span={10}>
								<DetailItem
									label={'Limit Max Use User'}
									value={voucher.limitMaxUseUser?.toString()}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={10}>
								<DetailItem
									label={'Valid Start Date'}
									value={moment(voucher.validStartAt).format('DD MMM YYYY, HH:mm')}
								/>
							</Col>
							<Col span={10}>
								<DetailItem
									label={'Valid End Date'}
									value={moment(voucher.validEndAt).format('DD MMM YYYY, HH:mm')}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	);
};

export default ProductDetail;

function sortCategories(categories: CategoryProps[]) {
	categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
	return categories;
}
