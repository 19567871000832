import React, { useMemo } from 'react';
import { Menu } from 'antd';
import {
	HomeOutlined,
	TagOutlined,
	UsergroupDeleteOutlined,
	FileTextOutlined,
	PictureOutlined,
	MenuOutlined,
	TableOutlined,
	LaptopOutlined,
	PayCircleOutlined,
	LineChartOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons';

import type { MenuProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const Sidebar: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { role } = useAuth();

	// const isHavePermission = (finds: EUserRole[]) => {
	// 	return finds.includes(role);
	// };

	const isAdmin = useMemo(() => role.includes('admin'), [role]);

	const filterPermission = (items: any[]) => {
		return items
			.map((item) => {
				if (item?.permission === undefined || item.permission === true) {
					let newObject = item;
					delete item.permission;
					if (newObject.children) {
						newObject.children = filterPermission(newObject.children);
					}
					return newObject;
				} else {
					return null;
				}
			})
			.filter(Boolean);
	};

	const MENUS = [
		{
			key: '/dashboard',
			label: 'Dashboard',
			icon: <HomeOutlined />,
		},
		{
			permission: isAdmin,
			type: 'group',
			label: 'TRANSACTION',
			children: [
				{
					key: '/transactions',
					label: 'Transaction',
					icon: <MenuOutlined />,
				},
				// {
				// 	key: '/reports',
				// 	label: 'Report',
				// 	icon: <LineChartOutlined />,
				// },
				{
					key: '/redeem-transactions',
					label: 'Redeem Transaction',
					icon: <LineChartOutlined />,
				},
			],
		},
		{
			permission: isAdmin,
			type: 'group',
			label: 'MASTER DATA',
			children: [
				{
					key: '/product-categories',
					label: 'Category',
					icon: <TagOutlined />,
				},
				{
					key: '/products',
					label: 'Product',
					icon: <TableOutlined />,
				},
				{
					key: '/promotions',
					label: 'Promotion',
					icon: <TagOutlined />,
				},
				{
					key: '/news',
					label: 'News',
					icon: <FileTextOutlined />,
				},
				{
					key: '/loyalty',
					label: 'Loyalty',
					icon: <FileTextOutlined />,
				},
				{
					key: '/stores',
					label: 'Store',
					icon: <FileTextOutlined />,
				},
				{
					key: '/delivery-outlets',
					label: 'Delivery Outlet',
					icon: <FileTextOutlined />,
				},
				{
					key: '/basic-configurations',
					label: 'Basic Configuration',
					icon: <LaptopOutlined />,
				},
				{
					key: '/redeem-products',
					label: 'Redeem Product',
					icon: <TableOutlined />,
				},
				// {
				// 	key: '/vouchers',
				// 	label: 'Voucher',
				// 	icon: <TagsOutlined />,
				// },
				// {
				// 	key: '/redeem',
				// 	label: 'Redeem',
				// 	icon: <TableOutlined />,
				// },
				// {
				//   key: "/product-stocks",
				//   label: "Product Stocks",
				//   icon: <UserOutlined />,
				// },
				// {
				//   key: "/product-prices",
				//   label: "Product Prices",
				//   icon: <UserOutlined />,
				// },
			],
		},
		// {
		//   permission: isAdmin,
		//   type: "group",
		//   label: "Location",
		//   children: [
		//     {
		//       key: "/locations",
		//       label: "Location",
		//       icon: <EnvironmentOutlined />,
		//     },
		//     {
		//       key: "/delivery-points",
		//       label: "Delivery Point",
		//       icon: <EnvironmentOutlined />,
		//     },
		//   ],
		// },
		{
			permission: isAdmin,
			type: 'group',
			label: 'WEB CONFIG',
			children: [
				{
					key: '/banner',
					label: 'Banner',
					icon: <PictureOutlined />,
				},
				{
					key: '/tnc-privacy',
					label: 'Tnc & Privacy Policy',
					icon: <InfoCircleOutlined />,
				},
				{
					key: '/faqs',
					label: 'FAQs',
					icon: <InfoCircleOutlined />,
				},
				// {
				//   key: "/page-element",
				//   label: "Page Element",
				//   icon: <LayoutOutlined />,
				// },
				// {
				//   key: "/tnc",
				//   label: "TnC & Privacy Policy",
				//   icon: <ExclamationCircleOutlined />,
				// },
				// {
				//   key: "/faq",
				//   label: "FAQs",
				//   icon: <ExclamationCircleOutlined />,
				// },
			],
		},
		{
			permission: isAdmin,
			type: 'group',
			label: 'USERS',
			children: [
				{
					key: '/admin',
					label: 'Admin',
					icon: <UsergroupDeleteOutlined />,
				},
				{
					key: '/customer',
					label: 'Customer',
					icon: <UsergroupDeleteOutlined />,
				},
			],
		},
		{
			permission: isAdmin,
			type: 'group',
			label: 'INTEGRATION',
			children: [
				{
					key: '/payment-methods',
					label: 'Payment Methods',
					icon: <PayCircleOutlined />,
				},
				{
					key: '/bc-integration-logs',
					label: 'BC Integration Logs',
					icon: <InfoCircleOutlined />,
				},
			],
		},
	];

	const items = filterPermission(MENUS) as MenuProps['items'];

	const convertPathName = () => {
		const pathname = location.pathname.split('/');
		const res = '/' + pathname[1];
		return res;
	};

	const getSelectedParent = () => {
		const currentMenu = convertPathName();
		var parent: string[] = [];
		if (items) {
			for (const group of items) {
				if (group && 'children' in group && group.children) {
					for (const submenu of group.children) {
						var current = String(submenu?.key ? submenu.key : '');
						if (submenu && 'children' in submenu && submenu.children) {
							for (const item of submenu.children) {
								if (
									item &&
									'path' in item &&
									(item as any).path === currentMenu &&
									current !== undefined
								) {
									parent.push(current);
								}
							}
						}
					}
				}
			}
		}
		return parent;
	};

	return (
		<Menu
			mode='inline'
			style={{ paddingBottom: 40 }}
			defaultSelectedKeys={[convertPathName()]}
			defaultOpenKeys={getSelectedParent()}
			items={items}
			onClick={({ key }) => {
				navigate(key);
			}}
		/>
	);
};
export default Sidebar;
