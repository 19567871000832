import { createGlobalState } from 'react-hooks-global-state';

const { useGlobalState } = createGlobalState({
  lastPagination: {},
});

export default function UseGlobalState() {
  const [lastPagination, setLastPagination] =
    useGlobalState<any>('lastPagination');

  return {
    lastPagination,
    setLastPagination,
  };
}
