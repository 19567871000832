import React from 'react';
import { Button, Layout, Tooltip, Typography } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import UserAvatar from '../../components/UserAvatar';
import { appVersion, now, thisYear } from '../../helpers/constant';
import useConfigApp from '../../hooks/useConfigApp';
import AppLogo from '../../components/AppLogo';
import Breadcrumbs from '../../components/Breadcrumbs';
import Sidebar from '../../components/Sidebar';
import { useLocation } from 'react-router-dom';

const { Header, Sider, Content } = Layout;

type Props = {
  children: React.ReactNode;
};

const AppLayout: React.FC<Props> = ({ children }) => {
  const { sidebarCollapsed, toggleSidebar } = useConfigApp();
  const location = useLocation();

  return (
    <Layout style={{ height: '100vh', flexDirection: 'row' }}>
      <AppSider
        onCollapse={toggleSidebar}
        trigger={null}
        collapsible
        collapsed={sidebarCollapsed}
        collapsedWidth={70}
        width={250}
        style={{ height: '100vh' }}
      >
        <AppLogo collapsed={sidebarCollapsed} />
        <Sidebar />
      </AppSider>
      {sidebarCollapsed ? (
        <Tooltip placement="right" title={'Expand Sidebar'}>
          <Button
            style={{
              position: 'absolute',
              bottom: 0,
              background: '#9E2A2B',
              height: 40,
              width: 70,
            }}
            onClick={toggleSidebar}
            type="text"
            icon={
              <RightCircleOutlined color="#FFFFFF" className="icon-collapsed" />
            }
          />
        </Tooltip>
      ) : (
        <MinimizeButton
          onClick={toggleSidebar}
          style={{
            position: 'absolute',
            bottom: 0,
            background: '#9E2A2B',
            height: 40,
            width: 240,
          }}
        >
          <div>
            v{appVersion} · ©{thisYear}
          </div>
          <LeftCircleOutlined color="#FFFFFF" />
        </MinimizeButton>
      )}

      <Layout
        id="scroll-content"
        className="site-layout"
        style={{ minHeight: '100vh', overflowY: 'scroll' }}
      >
        <AppHeader>
          {location.pathname === '/dashboard' ? (
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
              VINES - Content Management System
            </Typography.Title>
          ) : (
            <Breadcrumbs />
          )}

          <AppHeaderAccount>
            <DateNow>{}</DateNow>
            <DividerTitle />
            <UserAvatar />
          </AppHeaderAccount>
        </AppHeader>
        <AppContent>{children}</AppContent>
      </Layout>
    </Layout>
  );
};

const AppContent = styled(Content)`
  height: 100%;
  padding: 0px 20px 20px 20px;
`;

const AppSider = styled(Sider)`
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: auto;
  overflow-x: hidden;
  padding-left: 10px;
`;

const AppHeader = styled(Header)`
  position: relative;
  padding: 0 20px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateNow = styled.div`
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
`;

const AppHeaderAccount = styled.div`
  flex: 1;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  justify-content: flex-end;
`;

const DividerTitle = styled.div`
  width: 2.5px;
  height: 100%;
  background-color: #eef0f6;
  opacity: 0.4;
  margin: 0 10px;
`;

const MinimizeButton = styled.div`
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  cursor: pointer;

  div {
    color: ${({ theme }) => theme.colors.white};
    font-size: 10px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default AppLayout;
