import {
  Form,
  Row,
  Space,
  Select,
  Col,
  Modal,
  Image,
  Button,
  Table,
  Typography,
  Card,
  message,
  Divider,
  Radio,
  Upload,
  UploadFile,
  Input,
} from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import HTMLPreview from '../../components/HTMLPreview';
import {
  BaseResponseProps,
  BaseResponsePaginationProps,
} from '../../types/config.type';
import DetailItem from '../../components/DetailItem';
import { initialProduct, ProductProps } from '../../types/products.type';
import { CategoryProps } from '../../types/category.type';
import {
  PromotionProps,
  initialPromotion,
  PromotionItems,
} from '../../types/promotion.type';
import { formatYearToTime } from '../../helpers/constant';
import SectionContent from '../../components/SectionContent';
import formatPrice from '../../helpers/formatPrice';
import styled from 'styled-components';
import { IconTrash } from '../../assets/icons';
import { RcFile, UploadProps } from 'antd/lib/upload';
import { PlusOutlined } from '@ant-design/icons';
import { DEFAULT_IMG } from '../../const/config';

interface ResponseProps extends BaseResponseProps<PromotionProps> {}

interface ResponsePropsProduct
  extends BaseResponsePaginationProps<ProductProps> {}

interface ILocation {
  promoId: string;
}

const { Text, Title } = Typography;

const PromotionEdit: React.FC = () => {
  const { promoId } = useParams<keyof ILocation>() as ILocation;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [promotion, setPromotion] =
    React.useState<PromotionProps>(initialPromotion);
  const [promotionItem, setPromotionItem] = React.useState<any[]>([]);
  // const [product, setProduct] = React.useState<ProductProps[] | any>([
  //   initialProduct,
  // ]);
  const [image, setImage] = React.useState<File | undefined>();
  const [imageUrl, setImageUrl] = React.useState<string | undefined>();

  const [tmpData, setTmpData] = React.useState({
    isArchive: promotion.isArchive,
    descriptionPromo: promotion.descriptionPromo,
  });
  const [previewImg, setPreviewImg] = React.useState<any>(null);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState('');
  const [previewTitle, setPreviewTitle] = React.useState('');
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);

  const btnUploadRef = React.useRef<any>(null);

  React.useEffect(() => {
    const fetchPromotionDetail = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          '/promotions/' + promoId
        );

        if (res && res.data) {
          setPromotion(res?.data?.payload);
          setImageUrl(res?.data?.payload?.image);
          setPromotionItem(res?.data?.payload?.items);
          setTmpData({
            isArchive: res?.data?.payload?.isArchive,
            descriptionPromo: res?.data?.payload?.descriptionPromo,
          });
          if (res.data.payload?.image) {
            const dataImages = {
              imageId: res?.data?.payload?.promoId,
              name: res?.data?.payload?.description,
              url: res?.data?.payload?.image,
              uid: res?.data?.payload?.promoId,
            };

            setFileList([dataImages]);
          }
        } else {
          message.error('Something went wrong');
        }

        // const resProduct = await httpRequest.get<ResponsePropsProduct>(
        //   '/products/'
        // );
        // setProduct(resProduct.data.payload.results);

        setIsLoading(false);
        setIsLoadingAction(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchPromotionDetail();
  }, [promoId, isLoadingAction]);

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      await httpRequest.patch<ResponseProps>('/promotions/' + promoId, {
        isArchive: tmpData.isArchive,
        descriptionPromo: tmpData.descriptionPromo,
      });

      // fetchList();

      if (fileList && fileList.length > 0 && fileList?.[0].originFileObj) {
        let formData = new FormData();
        formData.append('image', fileList[0].originFileObj as RcFile);

        await httpRequest.put(
          '/promotions/' + promoId + '/upload-image',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      } else if (fileList.length === 0) {
        await httpRequest.delete('/promotions/' + promoId + '/image/delete');
      }
      setIsLoadingAction(!isLoadingAction);

      message.success('successfully update promotion.');
      navigate(`/promotions/${promoId}`);
    } catch (error) {
      message.error('failed');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: 'IMAGE',
      dataIndex: 'image',
      key: 'image',
      render: (_: string, record: PromotionItems) => {
        return (
          <Image
            width={42}
            height={42}
            fallback={DEFAULT_IMG}
            src={
              (record?.product?.images
                ? record?.product?.images?.length > 0
                  ? record?.product?.images[0]?.imageUrl
                  : DEFAULT_IMG
                : DEFAULT_IMG) || DEFAULT_IMG
            }
            className="p-1 border-default"
            style={{ width: 'max-content' }}
            placeholder={
              <Image preview={false} src={DEFAULT_IMG} width={42} height={42} />
            }
          />
        );
      },
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'productId',
      key: 'productId',
      render: (text: string, record: PromotionItems) => {
        return (
          <Text>
            {/* {product
              .filter(
                (value: ProductProps) => value.productId === record.productId
              )
              .map((value: ProductProps) => value.productName)} */}
            {record?.product ? record?.product?.productName : 'Not Set'}
          </Text>
        );
      },
    },
    {
      title: 'UNIT',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text: string, record: PromotionItems) => {
        return <Text>{record?.quantity ?? 'Not Set'}</Text>;
      },
    },
    {
      title: 'DISC PERCENTAGE',
      dataIndex: 'discPercentage',
      key: 'discPercentage',
      render: (text: string, record: PromotionItems) => {
        return (
          <Text>
            {record?.discPercentage
              ? `${Math.floor(record?.discPercentage)}%`
              : 'Not Set'}
          </Text>
        );
      },
    },
    {
      title: 'PRICE',
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      render: (text: string, record: PromotionItems) => {
        return (
          <div>
            {promotion?.items?.length === 1 && (
              <p
                style={{
                  textDecoration: 'line-through',
                  marginBottom: '0',
                  color: '#D8D7D7',
                }}
              >
                IDR {formatPrice(record?.totalAmount) ?? 'Not Set'}
              </p>
            )}
            IDR{' '}
            {promotion?.items?.length === 1
              ? formatPrice(
                  promotion?.finalPrice! * promotion?.items?.[0]?.quantity || 0
                )
              : formatPrice(record?.totalAmount - record?.discAmount) ||
                'Not Set'}
          </div>
        );
      },
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any) => <>{formatYearToTime(createdAt)}</>,
    },
  ];

  // const getBase64 = (file: any) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result as string);
  //     reader.onerror = (error) => reject(error);
  //   });

  // const handlePreview = async (e: any) => {
  //   const img = await getBase64(e.target.files[0]);
  //   if (
  //     !(
  //       e.target.files[0]?.type === 'image/png' ||
  //       e.target.files[0]?.type === 'image/jpg' ||
  //       e.target.files[0]?.type === 'image/jpeg'
  //     )
  //   ) {
  //     const err = `Unaccepted file format. Type: ${e.target.files[0]?.type}`;

  //     message.error(err);
  //     return;
  //   }

  //   const MAX_FILE_SIZE = 2048;

  //   const fileSizeKiloBytes = e.target.files[0]?.size! / 1024;
  //   if (fileSizeKiloBytes > MAX_FILE_SIZE) {
  //     message.error('File size is greater than 2mb');

  //     return;
  //   }
  //   setPreviewImg(img);
  //   setImage(e.target.files[0]);
  //   e.target.value = '';
  // };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    );
  };

  const handleChange: UploadProps['onChange'] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList.length > 0) {
      if (
        !(
          newFileList[0]?.type === 'image/png' ||
          newFileList[0]?.type === 'image/jpg' ||
          newFileList[0]?.type === 'image/jpeg'
        )
      ) {
        const err = `Unaccepted file format. Type: ${newFileList[0]?.type}`;

        message.error(err);
        return;
      }

      const MAX_FILE_SIZE = 2048;

      if (!newFileList || newFileList.length === 0) return;
      const fileSizeKiloBytes = newFileList[0]?.size! / 1024;
      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        message.error('File size is greater than 2mb');

        return;
      }
    }

    setFileList(newFileList);
  };

  const uploadButton = (
    <div ref={btnUploadRef}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title="Edit Promotion"
        rightAction={
          <Space>
            <Button
              style={{ padding: '0px 32px' }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              style={{ padding: '0px 32px' }}
              loading={isLoading}
              type="primary"
              onClick={() => handleUpdate()}
            >
              Save
            </Button>
          </Space>
        }
      />
      <div style={{ paddingBottom: 120 }}>
        <SectionContent
          groupTitle="Promo Information"
          subTitle="These are edit promotion details, you can change anything"
        >
          <Row justify="space-between" gutter={[15, 15]}>
            <Col span={24}>
              <Row className="items-center" gutter={20}>
                <Col>
                  <Title
                    level={5}
                    style={{ color: '#768499', marginBottom: 2, fontSize: 14 }}
                  >
                    Image
                  </Title>
                  {/* {previewImg ? (
                    <div className="relative">
                      <Image
                        preview={true}
                        width={144}
                        height={104}
                        style={{ objectFit: 'cover' }}
                        src={previewImg}
                      />
                      <div
                        className="absolute top-0 right-0 cursor-pointer"
                        onClick={() => setPreviewImg(null)}
                      >
                        <IconTrash />
                      </div>
                    </div>
                  ) : (
                    <Image
                      preview={true}
                      width={144}
                      height={104}
                      fallback={DEFAULT_IMG}
                      style={{ objectFit: 'cover' }}
                      src={
                        promotion.image
                          ? promotion.image
                          : DEFAULT_IMG
                      }
                      placeholder={
                        <Image
                          preview={false}
                          src={DEFAULT_IMG}
                          width={144}
                          height={104}
                          style={{ objectFit: 'cover' }}
                        />
                      }
                    />
                  )} */}
                  <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => setPreviewOpen(false)}
                  >
                    <img
                      alt="example"
                      style={{ width: '100%' }}
                      src={previewImage}
                    />
                  </Modal>
                  {fileList?.length === 0 && (
                    <Image
                      width={144}
                      height={104}
                      fallback={DEFAULT_IMG}
                      style={{ objectFit: 'cover' }}
                      src={DEFAULT_IMG}
                      placeholder={
                        <Image
                          preview={false}
                          src={DEFAULT_IMG}
                          width={144}
                          height={104}
                          style={{ objectFit: 'cover' }}
                        />
                      }
                    />
                  )}
                  <CustomUpload
                    accept="image/png, image/jpeg, image/jpg"
                    name="avatar"
                    fileList={fileList}
                    onPreview={handlePreview}
                    listType="picture-card"
                    className="avatar-uploader"
                    // showUploadList={false}
                    beforeUpload={() => false}
                    onChange={handleChange}
                    maxCount={1}
                  >
                    {/* {image.length > 0 ? null : uploadButton} */}
                    {uploadButton}
                  </CustomUpload>
                </Col>
                {/* <Col>
                  <input
                    type="file"
                    // accept="image/*"
                    accept="image/png, image/jpeg, image/jpg"
                    hidden
                    ref={btnUploadRef}
                    onChange={handlePreview}
                  />
                  <Button
                    type="primary"
                    className="block mb-2.5"
                    onClick={() => {
                      if (
                        btnUploadRef.current != undefined &&
                        btnUploadRef.current.click != undefined
                      )
                        btnUploadRef.current.click();
                    }}
                  >
                    Upload Image
                  </Button>
                  <Typography.Text className="text-2.5 text-gray block">
                    Recommended file resolution 1200x800 pixel, Extension
                    allowed are .jpg and .png and Max file size is 2 Mb.
                  </Typography.Text>
                </Col> */}
                <Col span={16} className="mt-4">
                  <Button
                    // disabled={image.length > 0}
                    size="small"
                    type="primary"
                    className="block"
                    onClick={() => {
                      if (
                        btnUploadRef.current != undefined &&
                        btnUploadRef.current.click != undefined
                      )
                        btnUploadRef.current.click();
                    }}
                  >
                    Upload Image
                  </Button>
                  <Typography.Text className="text-2.5 text-gray bloc">
                    Recommended file resolution 1700x800 pixel, Extension
                    allowed are .jpg and .png and Max file size is 2 Mb.
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography.Text className="text-3 text-gray block mb-1">
                Name
              </Typography.Text>
              <Typography.Text className="text-3.5 text-primary-black">
                {promotion?.description ? (
                  <HTMLPreview html={promotion?.description} />
                ) : (
                  'Not Set'
                )}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text className="text-3 text-gray block mb-1">
                Description
              </Typography.Text>
              <Input
                value={tmpData?.descriptionPromo}
                onChange={(e) =>
                  setTmpData({
                    ...tmpData,
                    descriptionPromo: e.target.value,
                  })
                }
              />
            </Col>
            <Col span={24}>
              <Row>
                <Col span={8}>
                  <Typography.Text className="text-3 text-gray block">
                    Promo Code
                  </Typography.Text>
                  <Typography.Text className="text-3.5 text-primary-black">
                    {promotion?.promoCode ?? 'Not Set'}
                  </Typography.Text>
                </Col>
                <Col span={8}>
                  <Typography.Text className="text-3 text-gray block">
                    Amount
                  </Typography.Text>
                  <Typography.Text className="text-3.5 text-primary-black">
                    {/* {promotion?.amount
                      ? formatPrice(promotion?.amount)
                      : 'Not Set'} */}
                    {promotion?.items?.length === 1
                      ? formatPrice(
                          promotion?.finalPrice! *
                            promotion?.items?.[0]?.quantity
                        ) ?? 0
                      : formatPrice(promotion?.amount) || 'Not Set'}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography.Text className="text-3 text-gray block mb-1">
                Promo Time
              </Typography.Text>
              <Typography.Text className="text-3.5 text-primary-black">
                {formatYearToTime(promotion?.startAt)} -{' '}
                {formatYearToTime(promotion?.endAt)}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text className="text-3 text-gray block mb-1">
                Status
              </Typography.Text>

              <Radio.Group
                onChange={(e: any) => {
                  setTmpData({
                    ...tmpData,
                    isArchive: !e.target.value,
                  });
                }}
                value={!tmpData.isArchive}
              >
                <CustomRadio value={true}>Active</CustomRadio>
                <CustomRadio value={false}>Inactive</CustomRadio>
              </Radio.Group>
            </Col>
          </Row>
        </SectionContent>{' '}
        <Divider style={{ marginTop: '1%' }} />
        <div className="px-6 py-3 bg-graylight2 mb-2">
          <Typography.Text className="text-primary-black font-semibold text-5">
            Promo Items
          </Typography.Text>
        </div>
        {promotionItem?.length > 0 ? (
          <Table
            rowKey="promotionItemsId"
            loading={isLoading}
            columns={columns}
            dataSource={promotionItem && promotionItem}
            pagination={false}
          />
        ) : (
          <></>
        )}
      </div>
    </React.Fragment>
  );
};

export default PromotionEdit;

export const CustomRadio = styled(Radio)`
  margin-right: 5rem;
  .ant-radio-checked .ant-radio-inner {
    border-color: #1e1e1e;
    border-width: 2px;
    box-shadow: none;
  }
  .ant-radio:hover .ant-radio-inner {
    background-color: white;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #1e1e1e;
  }
`;

const CustomUpload = styled(Upload)`
  .ant-upload {
    text-align: left;
    display: none;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0;
    border: none;
  }

  .ant-upload-list-picture-card-container {
    width: 144px !important;
    height: 104px;
    margin-right: 0;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: cover !important;
  }
`;
