import {
  Form,
  Input,
  message,
  Select,
  Space,
  Button,
  Card,
  Spin,
  DatePicker,
  Row,
  Col,
} from 'antd';
import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { initialProduct, ProductProps } from '../../../types/products.type';
import { BaseResponseProps } from '../../../types/config.type';
import { getErrorMessage } from '../../../helpers/errorHandler';
import { httpRequest } from '../../../helpers/api';
import HeaderSection from '../../../components/HeaderSection';
import { generateFormRules } from '../../../helpers/formRules';
import {
  initialProductPrice,
  ProductPriceProps,
} from '../../../types/productPrices.type';
import {
  initialProductVariant,
  ProductVariantProps,
} from '../../../types/productVariant.type';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';

interface ILocation {
  productPriceId: string;
}
interface ResponseProps extends BaseResponseProps<ProductPriceProps> {}

const { Option } = Select;

const ProductPriceEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { productPriceId } = useParams<keyof ILocation>() as ILocation;
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [productPrice, setProductPrice] = React.useState<ProductPriceProps>({
    ...initialProductPrice,
  });
  const [products, setProducts] = React.useState<ProductProps[]>([
    initialProduct,
  ]);
  const [variants, setVariants] = React.useState<ProductVariantProps[]>([
    initialProductVariant,
  ]);
  const [productId, setProductId] = React.useState<string>('');

  const createPrice = async (value: ProductPriceProps) => {
    try {
      setIsLoadingAction(true);
      let productVariantCode: ProductVariantProps | undefined;

      const productCode = products.find((item) => {
        if (item.productId === value.productId) {
          return item.productCode;
        }
      });

      if (productPrice.productVariantId!.length > 0) {
        productVariantCode = variants.find((item) => {
          if (item.productVariantId === value.productVariantId) {
            return item.productVariantCode;
          }
        });
      }

      const formData = {
        productCode: productCode?.productCode,
        // productVariantCode: productVariantCode?.productVariantCode,
        startAt: productPrice.startAt,
        endAt: productPrice.endAt,
        unitPrice: value.unitPrice,
        minQty: value.minQty,
        unitOfMeasureCode: value.unitOfMeasureCode.toLowerCase(),
      };

      await httpRequest.put('/product-prices/by-code', formData);

      message.success('Success add price.');
      navigate('/product-prices');
    } catch (error) {
      message.error(getErrorMessage(error));
      setIsLoadingAction(false);
    }
  };

  const updatePrice = async (value: ProductPriceProps) => {
    try {
      setIsLoadingAction(true);

      let productVariantCode: ProductVariantProps | undefined;

      const productCode = products.find((item) => {
        if (item.productId === value.productId) {
          return item.productCode;
        }
      });

      // if (productPrice.productVariantId!.length > 0) {
      //   productVariantCode = variants.find((item) => {
      //     if (item.productVariantId === value.productVariantId) {
      //       return item.productVariantCode;
      //     }
      //   });
      // }

      const formData = {
        productCode: productCode?.productCode,
        // productVariantCode: productVariantCode?.productVariantCode,
        startAt: productPrice.startAt,
        endAt: productPrice.endAt,
        unitPrice: value.unitPrice,
        minQty: value.minQty,
        unitOfMeasureCode: value.unitOfMeasureCode.toLowerCase(),
      };

      await httpRequest.put('/product-prices/by-code', formData);

      message.success('Success update price.');
      navigate('/product-prices');
    } catch (error) {
      message.error(getErrorMessage(error));
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (values: ProductPriceProps) => {
    if (productPriceId) {
      updatePrice(values);
    } else {
      createPrice(values);
    }
  };

  const onProductChange = async (value: string) => {
    // setProductId(value);
    const variantRes = await httpRequest.get<any>(
      `/products/${value}/variants`
    );
    setVariants(variantRes.data.payload.results);
    setProductPrice({
      ...productPrice,
      productId: value,
    });
  };

  const onProductVariantChange = (value: string) => {
    setProductPrice({
      ...productPrice,
      productVariantId: value,
    });
  };

  const onStartDateChange = (value: DatePickerProps['value']) => {
    setProductPrice({
      ...productPrice,
      startAt: moment(value).toDate(),
    });
    // console.log("onOk: ", moment(value).toDate());
  };

  const onEndDateChange = (value: DatePickerProps['value']) => {
    setProductPrice({
      ...productPrice,
      endAt: moment(value).toDate(),
    });
    // console.log("onEndOk: ", moment(value).toDate());
  };

  React.useEffect(() => {
    if (productPriceId) {
      const fetchPriceData = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            '/product-prices/' + productPriceId
          );
          if (res && res?.data) {
            setProductPrice(res.data.payload);
            form.setFieldsValue(res.data.payload);
          } else {
            message.error('Something went wrong');
          }

          const variantRes = await httpRequest.get<any>(
            `/products/${res.data.payload.productVariantId}/variants`
          );
          if (variantRes && variantRes?.data) {
            setVariants(variantRes.data.payload.results);
          } else {
            message.error('Something went wrong');
          }

          setProductId(res?.data?.payload?.productId);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchPriceData();
    }
    const fetchData = async () => {
      try {
        setIsLoading(true);

        if (productId.length > 0) {
          const variantRes = await httpRequest.get<any>(
            `/products/${productPrice.productId}/variants`
          );
          if (variantRes && variantRes?.data) {
            setVariants(variantRes.data.payload.results);
          } else {
            message.error('Something went wrong');
          }
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [productPriceId, location, productId]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const productRes = await httpRequest.get<any>('/products');
        if (productRes && productRes.data) {
          setProducts(productRes.data.payload.results);
        } else {
          message.error('Something went wrong');
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={(productPriceId ? 'Edit' : 'Add') + ' Price'}
        subtitle={
          productPriceId ? 'Manage your product price data' : 'Create new price'
        }
        rightAction={
          <Space>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button
              loading={isLoadingAction}
              type="primary"
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </Space>
        }
      />
      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <Form
            form={form}
            name="priceForm"
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Product Name"
              name="productId"
              key="productId"
              rules={generateFormRules('product Name', ['required'])}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select Product"
                optionFilterProp="children"
                searchValue=""
                onChange={onProductChange}
              >
                {products.map((item, index) => {
                  return (
                    <Option key={index} value={item.productId}>
                      {item.productName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Unit Price"
              name="unitPrice"
              key="unitPrice"
              rules={generateFormRules('unitPrice', ['required', 'numeric'])}
            >
              <Input
                onChange={(event) =>
                  setProductPrice({
                    ...productPrice,
                    unitPrice: parseInt(event.target.value),
                  })
                }
                placeholder="25000"
              />
            </Form.Item>

            <Form.Item
              label="Base Unit Of Measure"
              name="unitOfMeasureCode"
              key="unitOfMeasureCode"
              rules={generateFormRules('Base Unit Of Measure', ['required'])}
            >
              <Input
                onChange={(event) =>
                  setProductPrice({
                    ...productPrice,
                    unitOfMeasureCode: event.target.value,
                  })
                }
                placeholder="pcs"
              />
            </Form.Item>

            <Form.Item
              label="Min. Qty"
              name="minQty"
              key="minQty"
              rules={generateFormRules('minQty', ['required', 'numeric'])}
            >
              <Input
                type="number"
                onChange={(event) =>
                  setProductPrice({
                    ...productPrice,
                    minQty: parseInt(event.target.value),
                  })
                }
                placeholder="10"
              />
            </Form.Item>

            <Form.Item
              label="Product Variant"
              name="productVariantId"
              key="productVariantId"
              rules={generateFormRules('productVariant', [])}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select Product Variant"
                optionFilterProp="children"
                searchValue=""
                disabled={productPrice.productId ? false : true}
                onChange={onProductVariantChange}
                // onSearch={onSearch}
              >
                {variants.map((item, index) => {
                  return (
                    <Option key={index} value={item.productVariantId}>
                      {item.productVariantCode}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            {/* <Form.Item
              label="Start At"
              name="startAt"
              key="startAt"
              rules={generateFormRules("startAt")}
            >
              <DatePicker
                showTime
                onOk={onStartDateChange}
                defaultValue={moment(productPrice.startAt)}
                showToday={true}
                style={{
                  maxWidth: "50%",
                  minWidth: "30%",
                }}
              />
            </Form.Item> */}
            {/* <Form.Item
              label="End At"
              name="endAt"
              key="endAt"
              rules={generateFormRules("endAt")}
            >
              <DatePicker
                showTime
                onOk={onEndDateChange}
                defaultValue={moment(productPrice.endAt)}
                showToday={true}
                style={{
                  maxWidth: "50%",
                  minWidth: "30%",
                }}
              />
            </Form.Item> */}
            <Row gutter={[24, 0]}>
              <Col span={12}>
                <h4>Start At</h4>
                <DatePicker
                  showTime
                  onOk={onStartDateChange}
                  defaultValue={moment(productPrice.startAt)}
                  showToday={true}
                  style={{
                    maxWidth: '50%',
                    minWidth: '30%',
                  }}
                />
              </Col>
              <Col span={12}>
                <h4>End At</h4>
                <DatePicker
                  showTime
                  onOk={onEndDateChange}
                  defaultValue={moment(productPrice.endAt)}
                  showToday={true}
                  style={{
                    maxWidth: '50%',
                    minWidth: '30%',
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </React.Fragment>
  );
};

export default ProductPriceEdit;
