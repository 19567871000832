import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Table,
  Select,
  Button,
  Typography,
  Input,
  DatePicker,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import useFetchList from '../../hooks/useFetchList';
import { PAGE_SIZE_OPTIONS } from '../../helpers/constant';
import styled from 'styled-components';
import CustomPagination from '../../components/CustomPagination';
import {
  EVoucherDiscountType,
  EVoucherStatus,
  EVoucherType,
  VoucherEventProperties,
  VoucherProperties,
} from '../../types/voucher.type';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { IconArrowDown } from '../../assets/icons';
import { capitalizeFirstLetter } from '../../helpers/text';

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Voucher = () => {
  const navigate = useNavigate();

  const {
    isLoading,
    data,
    pagination,
    changePage,
    changeLimit,
    setQuery,
    setSearch,
  } = useFetchList<VoucherProperties>({
    endpoint: '/vouchers',
    initialQuery: {
      voucherType: EVoucherType.event,
    },
    limit: +PAGE_SIZE_OPTIONS[0],
  });

  const handleCreateUser = () => {
    navigate({ pathname: '/vouchers/add' });
  };

  const handleClickDetail = (voucherCode: string) => {
    navigate({ pathname: '/vouchers/' + voucherCode });
  };

  const handleChangeVoucherStatus = (status: string) => {
    if (status !== 'all') {
      setQuery((oldVal) => ({ ...oldVal, voucherStatus: status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, voucherStatus: '' }));
    }
  };

  const handleChangeDiscountType = (type: string) => {
    if (type !== 'all') {
      setQuery((oldVal) => ({ ...oldVal, discountType: type }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, discountType: '' }));
    }
  };

  const handleValidDateFilterChange = (e: any) => {
    if (e) {
      setQuery((oldVal) => ({
        ...oldVal,
        startAt: moment.utc(e[0]).toISOString(),
        endAt: moment.utc(e[1]).toISOString(),
      }));
    } else {
      setQuery((oldVal) => ({
        ...oldVal,
        startAt: '',
        endAt: '',
      }));
    }
  };

  const columns = [
    {
      title: 'VOUCHER CODE',
      dataIndex: 'voucherCode',
      key: 'voucherCode',
      render: (voucherCode: string) => {
        return (
          <div
            className="table-link"
            onClick={() => handleClickDetail(voucherCode)}
          >
            {voucherCode}
          </div>
        );
      },
    },
    {
      title: 'TITLE',
      dataIndex: 'event',
      key: 'event',
      render: (event: VoucherEventProperties) => {
        return <div>{event.title}</div>;
      },
    },
    {
      title: 'VOUCHER STATUS',
      dataIndex: 'voucherStatus',
      key: 'voucherStatus',
      render: (voucherStatus: string) => {
        return <div>{capitalizeFirstLetter(voucherStatus)}</div>;
      },
    },
    {
      title: 'DISCOUNT VALUE',
      dataIndex: 'discountValue',
      key: 'discountValue',
      render: (discountValue: string, record: VoucherProperties) => {
        return (
          <div>
            {record.discountType === EVoucherDiscountType.nominal
              ? 'IDR ' + Number(discountValue)?.toLocaleString('id-ID')
              : discountValue + '%'}
          </div>
        );
      },
    },
    {
      title: 'VALID DATE',
      render: (record: VoucherProperties) => {
        return (
          <div>{`${moment(record.validStartAt).format(
            'DD MMM YYYY'
          )} - ${moment(record.validEndAt).format('DD MMM YYYY')}`}</div>
        );
      },
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => {
        return <div>{moment(createdAt).format('DD MMM YYYY, HH:mm')}</div>;
      },
    },
  ];

  return (
    <React.Fragment>
      <HeaderSection
        title={'Voucher'}
        rightAction={[
          <Button type="primary" onClick={handleCreateUser}>
            Add Voucher
          </Button>,
        ]}
      />
      <Row gutter={{ xs: 8, sm: 15 }} className="mb-7.5">
        <Col span={10}>
          <Text className="text-3 text-gray block font-semibold mb-1">
            Search
          </Text>
          <Input
            placeholder="Search by Voucher Code"
            suffix={<SearchOutlined />}
            allowClear
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col span={4}>
          <Text className="text-3 text-gray block font-semibold mb-1">
            Status
          </Text>
          <Select
            allowClear
            onChange={handleChangeVoucherStatus}
            placeholder="Voucher Status"
            defaultValue="all"
            suffixIcon={<IconArrowDown />}
          >
            <Option value="all">All</Option>
            <Option value={EVoucherStatus.available}>Available</Option>
            <Option value={EVoucherStatus.expired}>Expired</Option>
            <Option value={EVoucherStatus.pending}>Pending</Option>
            <Option value={EVoucherStatus.used}>Used</Option>
          </Select>
        </Col>
        <Col span={4}>
          <Text className="text-3 text-gray block font-semibold mb-1">
            Status
          </Text>
          <Select
            allowClear
            onChange={handleChangeDiscountType}
            placeholder="Discount Type"
            defaultValue="all"
            suffixIcon={<IconArrowDown />}
          >
            <Option value="all">All</Option>
            <Option value={EVoucherDiscountType.nominal}>Nominal</Option>
            <Option value={EVoucherDiscountType.percentage}>Percentage</Option>
          </Select>
        </Col>
        <Col span={6}>
          <Text className="text-3 text-gray block font-semibold mb-1">
            Order Date
          </Text>
          <RangePicker
            suffixIcon={null}
            format="DD MMM YYYY"
            onChange={handleValidDateFilterChange}
          />
        </Col>
      </Row>

      <Row style={{ paddingBottom: 24 }}>
        <Col span={24}>
          <Table
            rowKey="userId"
            loading={isLoading}
            columns={columns}
            dataSource={data && data}
            pagination={{
              pageSize: pagination.perPage,
              current: pagination.page,
              style: { display: 'none' },
            }}
          />
        </Col>
        <CustomPagination
          data={data && data}
          pagination={pagination}
          changeLimit={changeLimit}
          changePage={changePage}
        />
      </Row>
    </React.Fragment>
  );
};
export default Voucher;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;
