import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Typography,
  message,
  Modal,
  Radio,
  Space,
  Upload,
  Image,
  Button,
  Card,
  Spin,
  Row,
  Col,
  InputNumber,
} from 'antd';
import React, { useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import styled from 'styled-components';
import { RcFile, UploadProps, UploadFile } from 'antd/lib/upload/interface';
import 'react-quill/dist/quill.snow.css';
import { getErrorMessage } from '../../helpers/errorHandler';
import { DEFAULT_IMG } from '../../const/config';
import { generateFormRules } from '../../helpers/formRules';
import {
  PaymentMethodProps,
  initialPaymentMethod,
} from '../../types/paymentMethods.type';
import ReactQuill from 'react-quill';

interface ILocation {
  code: string;
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
];

const { Title, Text } = Typography;

const PaymentMethodEdit: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { code } = useParams<keyof ILocation>() as ILocation;
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);

  const [image, setImage] = React.useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState('');
  const [previewTitle, setPreviewTitle] = React.useState('');

  const [paymentMethod, setPaymentMethod] =
    React.useState<PaymentMethodProps>(initialPaymentMethod);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    );
    setPreviewOpen(true);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handleRemove = () => {
    setImage([]);
    form.setFieldValue('logo', undefined);
    form.validateFields();
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      if (
        !(
          newFileList[0]?.type === 'image/png' ||
          newFileList[0]?.type === 'image/jpg' ||
          newFileList[0]?.type === 'image/jpeg'
        )
      ) {
        const err = `Unaccepted file format. Type: ${newFileList[0]?.type}`;

        message.error(err);
        return;
      }

      const MAX_FILE_SIZE = 2048;

      const fileSizeKiloBytes = newFileList[0]?.size! / 1024;
      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        message.error('File size is greater than 2mb');

        return;
      }
    }
    setImage(newFileList);
  };

  const btnUploadRef = useRef<any>(null);
  const uploadButton = (
    <div ref={btnUploadRef}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const updatePaymentMethod = async (
    props: Omit<PaymentMethodProps, 'createdAt' | 'updatedAt'>
  ) => {
    try {
      setIsLoadingAction(true);

      await httpRequest.patch('/payment-methods/' + code, props);

      const formData = new FormData();

      if (image.length > 0 && !image[0].hasOwnProperty('imageId')) {
        formData.append('image', image[0].originFileObj as RcFile);
      }

      await httpRequest.put('/payment-methods/' + code + '/photo', formData);

      message.success('Success update ' + props.code + ' data');
      navigate(`/payment-methods/${code}`);
    } catch (error) {
      setIsLoadingAction(false);
      message.error(getErrorMessage(error));
    }
  };

  const handleSubmit = async (
    values: Omit<
      PaymentMethodProps,
      'createdAt' | 'updatedAt' | 'statusLoading'
    >
  ) => {
    if (code) {
      updatePaymentMethod(values);
    } else {
      // createBanner(values);
    }
  };

  React.useEffect(() => {
    if (code) {
      const fetchPaymentMethodDetail = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<any>('/payment-methods/' + code);

          if (res && res.data && res.data.payload) {
            setPaymentMethod(res.data.payload);

            const images: any = res?.data?.payload?.logo;
            if (images) {
              const dataImages = {
                imageId: res.data.payload.description,
                name: res.data.payload.description,
                url: images,
                uid: res.data.payload.description,
              };

              setImage([dataImages]);
            }
            form.setFieldsValue(res.data.payload);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchPaymentMethodDetail();
    }
  }, [code, location]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={'Edit Payment Method'}
        rightAction={
          <Space>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button
              loading={isLoadingAction}
              type="primary"
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </Space>
        }
      />

      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <Form
            form={form}
            name="productForm"
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Row>
              <Col span={5}>
                <Title level={5}>Payment Method Information</Title>
                <Text style={{ color: '#768499' }}>
                  These are edit payment method, you can change some
                </Text>
              </Col>
              <Col offset={1} span={16}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Logo"
                      name="logo"
                      rules={generateFormRules('Logo', ['required'])}
                    >
                      <Row className="items-center" gutter={16}>
                        <Col>
                          <Modal
                            open={previewOpen}
                            title={previewTitle}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <Image width={'100%'} src={previewImage} />
                          </Modal>
                          {image?.length === 0 ? (
                            <Image
                              width={180}
                              height={120}
                              style={{ objectFit: 'contain' }}
                              preview={false}
                              src={DEFAULT_IMG}
                            />
                          ) : null}
                          <CustomUpload
                            name="avatar"
                            fileList={image}
                            onRemove={handleRemove}
                            onPreview={handlePreview}
                            listType="picture-card"
                            className="avatar-uploader"
                            beforeUpload={() => false}
                            onChange={handleChange}
                            maxCount={1}
                          >
                            {uploadButton}
                          </CustomUpload>
                        </Col>
                        <Col span={16}>
                          <Button
                            size="small"
                            type="primary"
                            className="block"
                            onClick={() => {
                              if (
                                btnUploadRef.current &&
                                btnUploadRef.current.click
                              )
                                btnUploadRef.current.click();
                            }}
                          >
                            Upload Image
                          </Button>
                          <Typography.Text className="text-2.5 text-gray bloc">
                            Recommended file resolution 1600x1100 pixel,
                            Extension allowed are .jpg and .png and Max file
                            size is 2 Mb.
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="Code"
                      name="code"
                      rules={generateFormRules('Code', ['required'])}
                    >
                      <Input
                        style={{ width: '100%' }}
                        value={paymentMethod?.code}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="BC Code"
                      name="bcCode"
                      rules={generateFormRules('BC Code', ['required'])}
                    >
                      <Input
                        style={{ width: '100%' }}
                        value={paymentMethod?.bcCode}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={generateFormRules('Description', ['required'])}
                      extra={'as label payment method in web'}
                    >
                      <Input
                        style={{ width: '100%' }}
                        value={paymentMethod?.description}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Expired Time"
                      name="expiryTime"
                      rules={generateFormRules('Expired Time', ['required'])}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        value={paymentMethod?.expiryTime}
                        addonAfter={'seconds'}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Status"
                      name="isPublished"
                      rules={generateFormRules('Status', ['required'])}
                    >
                      <Radio.Group value={paymentMethod?.isPublished}>
                        <CustomRadio value={true}>Active</CustomRadio>
                        <CustomRadio value={false}>Inactive</CustomRadio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Instruction Type" name="instructionType">
                      <ReactQuill
                        theme="snow"
                        value={paymentMethod.instructionType}
                        modules={quillModules}
                        formats={quillFormats}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </React.Fragment>
  );
};

export default PaymentMethodEdit;

const CustomRadio = styled(Radio)`
  margin-right: 5rem;
  .ant-radio-checked .ant-radio-inner {
    border-color: #1e1e1e;
    border-width: 2px;
    box-shadow: none;
  }
  .ant-radio:hover .ant-radio-inner {
    background-color: white;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #1e1e1e;
  }
`;

const CustomUpload = styled(Upload)`
  .ant-upload {
    text-align: left;
    display: none;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0;
    border: none;
  }

  .ant-upload-list-picture-card-container {
    width: 180px !important;
    height: 120px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: contain !important;
  }
`;
