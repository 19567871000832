import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import useAuth from '../hooks/useAuth';
import { Spin } from 'antd';
import ProductEdit from '../screens/product/Edit';
import ProductDetail from '../screens/product/Detail';
import Products from '../screens/product';
import UploadImagesEdit from '../screens/product/uploadImages/Edit';
import ProductStocks from '../screens/product/productStock';
import ProductStockDetail from '../screens/product/productStock/Detail';
import ProductStockEdit from '../screens/product/productStock/Edit';
import ProductPrices from '../screens/product/productPrice';
import ProductPriceDetail from '../screens/product/productPrice/Detail';
import ProductPriceEdit from '../screens/product/productPrice/Edit';
import Transaction from '../screens/transaction';
import TransactionDetail from '../screens/transaction/detail';
import DeliveryPoints from '../screens/deliveryPoint';
import DeliveryPointsDetail from '../screens/deliveryPoint/detail';
import DeliveryPointEdit from '../screens/deliveryPoint/edit';
import RedeemProducts from '../screens/redeemProducts';
import RedeemProductsEdit from '../screens/redeemProducts/edit';
import PromoEdit from '../screens/promotion/edit';
import Voucher from '../screens/voucher';
import VoucherDetail from '../screens/voucher/Detail';
import VoucherEdit from '../screens/voucher/Edit';
import IntegrationBC from '../screens/integrationBC';
import PaymentMethod from '../screens/paymentMethod';
import PaymentMethodDetail from '../screens/paymentMethod/Detail';
import PaymentMethodEdit from '../screens/paymentMethod/Edit';
const NotFound = React.lazy(() => import('../NotFound'));
const TncPrivacy = React.lazy(() => import('../screens/tncPrivacy'));

const Login = React.lazy(() => import('../screens/auth/Login'));
const ForgotPassword = React.lazy(() => import('../screens/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../screens/auth/ResetPassword'));

const Dashboard = React.lazy(() => import('../screens/dashboard'));
const Profile = React.lazy(() => import('../screens/profile'));
const EditProfile = React.lazy(() => import('../screens/profile/Edit'));
const ChangeMyPassword = React.lazy(() => import('../screens/profile/ChangeMyPassword'));
const ChangePasswordUser = React.lazy(() => import('../screens/user/ChangePassword'));
const User = React.lazy(() => import('../screens/user'));
const UserEdit = React.lazy(() => import('../screens/user/Edit'));
const UserDetail = React.lazy(() => import('../screens/user/Detail'));

const Category = React.lazy(() => import('../screens/category'));
const CategoryEdit = React.lazy(() => import('../screens/category/Edit'));
const CategoryDetail = React.lazy(() => import('../screens/category/Detail'));

const News = React.lazy(() => import('../screens/news'));
const NewsEdit = React.lazy(() => import('../screens/news/Edit'));
const NewsDetail = React.lazy(() => import('../screens/news/Details'));

const Banner = React.lazy(() => import('../screens/banner'));
const BannerEdit = React.lazy(() => import('../screens/banner/Edit'));
const BannerDetail = React.lazy(() => import('../screens/banner/Detail'));

const Loyalty = React.lazy(() => import('../screens/loyalty'));
const LoyaltyEdit = React.lazy(() => import('../screens/loyalty/Edit'));
const LoyaltyDetail = React.lazy(() => import('../screens/loyalty/Detail'));

const BasicConfig = React.lazy(() => import('../screens/basicConfig'));

const Store = React.lazy(() => import('../screens/location'));
const StoreDetail = React.lazy(() => import('../screens/location/Detail'));

const Promotion = React.lazy(() => import('../screens/promotion'));
const PromotionDetail = React.lazy(() => import('../screens/promotion/Detail'));

const FAQs = React.lazy(() => import('../screens/faqs'));
const FAQsEdit = React.lazy(() => import('../screens/faqs/edit'));

const RedeemTransactions = React.lazy(() => import('../screens/redeemTransactions'));

// const PromotionDetail = React.lazy(() => import('../screens/promotion/Detail'));/

const RootNavigator: React.FC = () => {
	const { isLoggedIn } = useAuth();

	return (
		<Suspense fallback={<Spin spinning={true} />}>
			<BrowserRouter>
				<Routes>
					<Route
						path='/'
						element={<Navigate to={isLoggedIn ? '/dashboard' : '/login'} />}
					/>
					<Route element={<PublicRoute />}>
						<Route
							path='/login'
							element={<Login />}
						/>
						<Route
							path='/forgot-password'
							element={<ForgotPassword />}
						/>
						<Route
							path='/reset-password/:sessionId'
							element={<ResetPassword />}
						/>
					</Route>
					<Route element={<PrivateRoute />}>
						<Route
							path='*'
							element={<NotFound />}
						/>
						<Route
							path='/dashboard'
							element={<Dashboard />}
						/>
						<Route
							path='/profile/edit'
							element={<EditProfile />}
						/>
						<Route
							path='/profile'
							element={<Profile />}
						/>
						<Route
							path='/change-password'
							element={<ChangeMyPassword />}
						/>
						<Route
							path='/transactions'
							element={<Transaction />}
						/>
						<Route
							path='/transactions/:transactionId'
							element={<TransactionDetail />}
						/>
						<Route
							path='/admin'
							element={<User userType='admin' />}
						/>
						<Route
							path='/admin/add'
							element={<UserEdit userType='admin' />}
						/>
						<Route
							path='/admin/:userId/edit'
							element={<UserEdit userType='admin' />}
						/>
						<Route
							path='/admin/:userId'
							element={<UserDetail userType='admin' />}
						/>
						<Route
							path='/admin/:userId/change-password'
							element={<ChangePasswordUser />}
						/>
						<Route
							path='/customer'
							element={<User userType='customer' />}
						/>
						<Route
							path='/customer/add'
							element={<UserEdit userType='customer' />}
						/>
						<Route
							path='/customer/:userId/edit'
							element={<UserEdit userType='customer' />}
						/>
						<Route
							path='/customer/:userId'
							element={<UserDetail userType='customer' />}
						/>
						<Route
							path='/customer/:userId/change-password'
							element={<ChangePasswordUser />}
						/>
						<Route
							path='/products'
							element={<Products />}
						/>
						<Route
							path='/products/add'
							element={<ProductEdit />}
						/>
						<Route
							path='/products/:productId/edit'
							element={<ProductEdit />}
						/>
						<Route
							path='/products/:productId'
							element={<ProductDetail />}
						/>
						<Route
							path='/products/:productId/upload'
							element={<UploadImagesEdit />}
						/>
						<Route
							path='/product-stocks'
							element={<ProductStocks />}
						/>
						<Route
							path='/product-stocks/:productStockId'
							element={<ProductStockDetail />}
						/>
						<Route
							path='/product-stocks/add'
							element={<ProductStockEdit />}
						/>
						<Route
							path='/product-stocks/:productStockId/edit'
							element={<ProductStockEdit />}
						/>
						<Route
							path='/product-prices'
							element={<ProductPrices />}
						/>
						<Route
							path='/product-prices/:productPriceId'
							element={<ProductPriceDetail />}
						/>
						<Route
							path='/product-prices/:productPriceId/edit'
							element={<ProductPriceEdit />}
						/>
						<Route
							path='/product-prices/add'
							element={<ProductPriceEdit />}
						/>
						<Route
							path='/product-categories'
							element={<Category />}
						/>
						<Route
							path='/product-categories/add'
							element={<CategoryEdit />}
						/>
						<Route
							path='/product-categories/:categoryId/edit'
							element={<CategoryEdit />}
						/>
						<Route
							path='/product-categories/:categoryId'
							element={<CategoryDetail />}
						/>
						<Route
							path='/news'
							element={<News />}
						/>
						<Route
							path='/news/add'
							element={<NewsEdit />}
						/>
						<Route
							path='/news/:newsId/edit'
							element={<NewsEdit />}
						/>
						<Route
							path='/news/:newsId'
							element={<NewsDetail />}
						/>
						<Route
							path='/basic-configurations'
							element={<BasicConfig />}
						/>
						<Route
							path='/banner'
							element={<Banner />}
						/>
						<Route
							path='/banner/add'
							element={<BannerEdit />}
						/>
						<Route
							path='/banner/:bannerId/edit'
							element={<BannerEdit />}
						/>
						<Route
							path='/banner/:bannerId'
							element={<BannerDetail />}
						/>
						<Route
							path='/loyalty'
							element={<Loyalty />}
						/>
						<Route
							path='/loyalty/:loyaltyId/edit'
							element={<LoyaltyEdit />}
						/>
						<Route
							path='/loyalty/:loyaltyId'
							element={<LoyaltyDetail />}
						/>
						<Route
							path='/promotions'
							element={<Promotion />}
						/>
						<Route
							path='/promotions/:promoId'
							element={<PromotionDetail />}
						/>
						<Route
							path='/promotions/:promoId/edit'
							element={<PromoEdit />}
						/>
						<Route
							path='/stores'
							element={<Store />}
						/>
						<Route
							path='/stores/:storeId'
							element={<StoreDetail />}
						/>
						<Route
							path='/delivery-outlets'
							element={<DeliveryPoints />}
						/>
						<Route
							path='/delivery-outlets/:deliveryPointId'
							element={<DeliveryPointsDetail />}
						/>
						<Route
							path='/redeem-products'
							element={<RedeemProducts />}
						/>
						<Route
							path='/redeem-products/add'
							element={<RedeemProductsEdit />}
						/>
						<Route
							path='/redeem-products/:redeemProductId/edit'
							element={<RedeemProductsEdit />}
						/>{' '}
						<Route
							path='/delivery-outlets/add'
							element={<DeliveryPointEdit />}
						/>
						<Route
							path='/delivery-outlets/:deliveryOutletId/edit'
							element={<DeliveryPointEdit />}
						/>
						<Route
							path='/tnc-privacy'
							element={<TncPrivacy />}
						/>
						<Route
							path='/faqs'
							element={<FAQs />}
						/>
						<Route
							path='/faqs/add'
							element={<FAQsEdit />}
						/>
						<Route
							path='/faqs/:faqId/edit'
							element={<FAQsEdit />}
						/>
						<Route
							path='/vouchers'
							element={<Voucher />}
						/>
						<Route
							path='/vouchers/add'
							element={<VoucherEdit />}
						/>
						<Route
							path='/vouchers/:voucherCode'
							element={<VoucherDetail />}
						/>
						<Route
							path='/redeem-transactions'
							element={<RedeemTransactions />}
						/>
						<Route
							path='/payment-methods'
							element={<PaymentMethod />}
						/>
						<Route
							path='/payment-methods/:code'
							element={<PaymentMethodDetail />}
						/>
						<Route
							path='/payment-methods/:code/edit'
							element={<PaymentMethodEdit />}
						/>
						<Route
							path='/bc-integration-logs'
							element={<IntegrationBC />}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
		</Suspense>
	);
};
export default RootNavigator;
