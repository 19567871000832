import { Col, Row, Divider, Typography } from 'antd';
import { TransactionProps } from '../types/transaction';
import styled from 'styled-components';
import React from 'react';

export const Invoince = React.forwardRef(
  ({ transaction }: { transaction: TransactionProps }, ref: any) => {
    return (
      <div
        ref={ref}
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '8px',
          width: '100%',
        }}
      >
        <CustomInvoice>
          <Row style={{ padding: 16, justifyContent: 'space-between' }}>
            <img
              src="/images/logo-vines.png"
              alt="logo"
              style={{ width: 100, height: 30 }}
            />
            <Typography.Text
              style={{ fontWeight: 600, color: '#000', fontSize: 16 }}
            >
              INV/{transaction.transactionCode.toUpperCase()}
            </Typography.Text>
          </Row>
          <Divider style={{ borderWidth: 2, borderColor: '#000', margin: 0 }} />
          <Row style={{ gap: 50, padding: 16, alignItems: 'center' }}>
            {transaction.transactionShipment?.courierVendor === 'JNE' ? (
              <>
                <img
                  src="/images/logo-jne.png"
                  alt="logo"
                  style={{ width: 100, height: 30 }}
                />
                <div>
                  <Typography.Text
                    style={{
                      color: '#000',
                      display: 'block',
                      fontSize: 16,
                    }}
                  >
                    {transaction.transactionShipment?.courierVendor}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      fontWeight: 600,
                      color: '#000',
                      display: 'block',
                      fontSize: 16,
                    }}
                  >
                    {transaction.transactionShipment?.courirService?.service}
                  </Typography.Text>{' '}
                </div>
              </>
            ) : (
              <Typography.Text
                style={{
                  color: '#000',
                  display: 'block',
                  marginRight: 30,
                  fontSize: 16,
                }}
              >
                Jasa Pengiriman:
              </Typography.Text>
            )}

            <div>
              <Typography.Text
                style={{ color: '#000', display: 'block', fontSize: 16 }}
              >
                Berat
              </Typography.Text>
              <Typography.Text
                style={{
                  fontWeight: 600,
                  color: '#000',
                  display: 'block',
                  fontSize: 16,
                }}
              >
                {transaction.transactionShipment?.weight} Kg
              </Typography.Text>
            </div>
            {transaction.transactionShipment?.courierVendor ===
            'VINES' ? null : (
              <Typography.Text
                style={{ color: '#000', display: 'block', fontSize: 16 }}
              >
                No. Resi :{' '}
                <span style={{ fontWeight: 600, color: '#000' }}>
                  {transaction.transactionShipment?.waybill || ''}
                </span>
              </Typography.Text>
            )}
          </Row>
          <Divider style={{ borderWidth: 2, borderColor: '#000', margin: 0 }} />
          <Row style={{ padding: 16 }} gutter={24}>
            <Col span={14}>
              <Typography.Text style={{ display: 'block', fontSize: 16 }}>
                Penerima{' '}
                <span style={{ fontWeight: 600, color: '#000', fontSize: 16 }}>
                  {transaction.transactionShipment?.shipToAdress.receiverName}
                </span>{' '}
                ({transaction.transactionShipment?.shipToAdress.receiverPhone})
              </Typography.Text>
              <Typography.Text style={{ fontSize: 16 }}>
                {transaction.transactionShipment?.shipToAdress.address},{' '}
                {transaction.transactionShipment?.shipToAdress.district},{' '}
                {transaction.transactionShipment?.shipToAdress.regency},{' '}
                {transaction.transactionShipment?.shipToAdress.province},{' '}
                {transaction.transactionShipment?.shipToAdress.postalCode}
              </Typography.Text>
            </Col>
            <Col span={10}>
              <Typography.Text style={{ display: 'block', fontSize: 16 }}>
                Pengirim{' '}
                <span style={{ fontWeight: 600, color: '#000', fontSize: 16 }}>
                  {transaction.metaLocation?.deliveryPoint?.name}
                </span>{' '}
                ({transaction.metaLocation?.phone})
              </Typography.Text>
              <Typography.Text style={{ fontSize: 16 }}>
                {transaction.metaLocation?.address
                  ? transaction.metaLocation?.address
                  : 'Not Set'}
                {transaction.metaLocation?.address2
                  ? ` ${transaction.metaLocation?.address2}`
                  : ''}
              </Typography.Text>
            </Col>
          </Row>
        </CustomInvoice>
      </div>
    );
  }
);

const CustomInvoice = styled.div`
  /* height: 300px; */
  /* width: 8.5in; */
  background-color: white;
  border: 2px solid #000;
  width: 100%;
  padding: 0px 0px 20px;
`;
