import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Input,
  Row,
  Space,
  Col,
  Divider,
  Image,
  Button,
  Card,
  message,
} from 'antd';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DetailItem from '../../../components/DetailItem';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import { initialLocation, LocationProps } from '../../../types/location.type';
import { initialProduct, ProductProps } from '../../../types/products.type';
import {
  initialProductStock,
  ProductStockProps,
} from '../../../types/productStocks.type';

interface ResponseProps extends BaseResponseProps<ProductStockProps> {
  payload: Omit<ProductStockProps, 'createdAt' | 'updatedAt'>;
}

interface ILocation {
  productStockId: string;
}

const ProductStockDetail: React.FC = () => {
  const navigate = useNavigate();
  const { productStockId } = useParams<keyof ILocation>() as ILocation;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [productStock, setProductStock] =
    React.useState<ProductStockProps>(initialProductStock);
  const [product, setProduct] = React.useState<ProductProps>(initialProduct);
  const [location, setLocation] =
    React.useState<LocationProps>(initialLocation);

  React.useEffect(() => {
    const fetchProductStockDetail = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<any>(
          '/product-stocks/' + productStockId
        );
        if (res && res.data) {
          setProductStock(res.data.payload);
          console.log(res.data.payload);
        } else {
          message.error('Something went wrong');
        }

        const productRes = await httpRequest.get<any>(
          '/products/' + res.data.payload.productId
        );
        if (productRes && productRes.data) {
          setProduct(productRes.data.payload);
        } else {
          message.error('Something went wrong');
        }

        const locationRes = await httpRequest.get<any>(
          `/locations/${res.data.payload.locationId}`
        );
        if (locationRes && locationRes.data) {
          setLocation(locationRes.data.payload);
        } else {
          message.error('Something went wrong');
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProductStockDetail();
  }, [productStockId]);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title="Product Stock Detail"
        subtitle="Manage your product stock data"
        rightAction={
          <Space>
            <Button
              type="primary"
              onClick={() =>
                navigate('/product-stocks/' + productStockId + '/edit')
              }
            >
              Edit
            </Button>
          </Space>
        }
      />

      <Card bordered={false}>
        <DetailItem label="Product Name" value={product.productName} />
        <DetailItem
          label="Current Stock"
          value={productStock.currentStock.toString()}
        />
        <DetailItem
          label="Base Unit Of Measure"
          value={productStock.baseUnitOfMeasure}
        />
        <DetailItem label="Location" value={location.locationName} />
        {/* <DetailItem
          label="Location Address"
          children={
            <Row gutter={[24, 0]}>
              <Col span={12}>
                <DetailItem label="Address" value={location.address} />
              </Col>
              <Col span={12}>
                <DetailItem label="Address 2" value={location.address2} />
              </Col>
            </Row>
          }
        /> */}
        <DetailItem label="Description" value={productStock.description} />
      </Card>
    </React.Fragment>
  );
};

export default ProductStockDetail;
