export interface PaymentMethodProps {
	code: string;
	bcCode: string;
	description: string;
	paymentType: string;
	logo?: string;
	vendorFee?: number;
	expiryTime?: number;
	instructionType?: string;
	isPublished: boolean;
	updatedAt?: Date;
	createdAt?: Date;
	bcPaymentMethod?: BCPaymentMethodProps;
}

export interface BCPaymentMethodProps {
	bcCode: string;
	bcDescription: string;
	bcPaymentType: string;
	updatedAt?: Date;
	createdAt?: Date;
}

export const initialPaymentMethod: PaymentMethodProps = {
	code: '',
	bcCode: '',
	description: '',
	paymentType: '',
	isPublished: true,
};
